import { Injectable } from '@angular/core';
import Dropzone, { DropzoneFile } from 'dropzone';
import { read } from 'fs';
import { md5 } from 'hash-wasm';
import { from } from 'rxjs';
import { Md5 } from 'ts-md5';
import { ResponsiveService } from '../core/services/responsive.service';
import { FileUploadResponse } from '../models/ui/file-upload-response';
import { MaxExtensionFileDialogComponent } from './components/dialogs/max-extension-file-dialog/max-extension-file-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EventService } from '../core/services/event.service';
import { EventData } from '../core/models/event-data';

@Injectable({
  providedIn: 'root',
})
export class DropzoneCommon {
  formatsValidModel = [
    'png',
    'ply',
    'mtl',
    'obj',
    'stl',
    'las',
    'e57',
    'laz',
    'asc',
  ];
  constructor(
    private responsiveService: ResponsiveService,
    private eventService: EventService,
    private dialog: MatDialog
  ) { }

  addDzLoading(item = null, type) {
    switch (type) {
      case 'VIDEO':
        this.addDzLoadingImageVideo(item);
        break;
      case 'IMAGE':
        this.addDzLoadingImageVideo(item);
        break;
      case 'MODEL':
        this.addDzLoadingModel(item);
        break;
      case 'BLK':
        this.addDzLoadingBlkHyperspectral(item);
        break;
      case 'HYPERSPECTRAL':
        this.addDzLoadingBlkHyperspectral(item);
        break;
      default:
        break;
    }
  }

  addDzLoadOk(
    item: DropzoneFile,
    fileUploadResponse: FileUploadResponse,
    type
  ) {
    switch (type) {
      case 'VIDEO':
        this.addDzLoadOkImageVideo(item, fileUploadResponse, 'VIDEO');
        break;
      case 'IMAGE':
        this.addDzLoadOkImageVideo(item, fileUploadResponse, 'IMAGE');
        break;
      case 'MODEL':
        this.addDzLoadOkModel(item, fileUploadResponse);
        break;
      case 'BLK':
        this.addDzLoadOkBlkHyperspectral(item, fileUploadResponse);
        break;
      case 'HYPERSPECTRAL':
        this.addDzLoadOkBlkHyperspectral(item, fileUploadResponse);
        break;
      default:
        break;
    }
  }

  addDzView(parentClass: string, type) {
    switch (type) {
      case 'VIDEO':
        this.addDzViewImageVideo(parentClass, 'VIDEO');
        break;
      case 'IMAGE':
        this.addDzViewImageVideo(parentClass, 'IMAGE');
        break;
      case 'MODEL':
        break;
      case 'BLK':
        break;
      case 'HYPERSPECTRAL':
        break;
      default:
        break;
    }
  }

  addDzRemove(parentContainer, type) {
    switch (type) {
      case 'VIDEO':
        this.addDzRemoveImageVideo(parentContainer);
        break;
      case 'IMAGE':
        this.addDzRemoveImageVideo(parentContainer);
        break;
      case 'MODEL':
        break;
      case 'BLK':
        break;
      case 'HYPERSPECTRAL':
        break;
      default:
        break;
    }
  }

  onError(dropzone, file, message) {
    dropzone.removeFile(file);
    dropzone.emit('removedfile', file);
  }
  getFileIndex(dropzone, previeElement) {
    let currentIndex = 0;
    dropzone.files.map((e, index) => {
      if (
        JSON.stringify(e.size) === previeElement.currentTarget.dataset.size ||
        e.name === previeElement.currentTarget.dataset.name
      ) {
        currentIndex = index + 1;
      }
    });
    return currentIndex;
  }

  fileHash(file, hasher): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const hash = hasher(e.target.result);
        resolve(hash);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  checkForDuplicates(file: DropzoneFile, dropzone: Dropzone): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      let countFiles = 0;
      const currenFileHash = await this.fileHash(file, md5);
      dropzone.files.forEach(async (dFile, index) => {
        const currentDFileHash = await this.fileHash(dFile, md5);
        if (currentDFileHash === currenFileHash) {
          countFiles++;
        }
        if (countFiles > 1) {
          dropzone.removeFile(file);
        }
        if (index === dropzone.files.length - 1) {
          resolve();
        }
      });
    });
  }

  checkNumImages(
    dropzone: Dropzone,
    minFiles: number,
    numImages: number
  ): number {
    // if (dropzone.files.length < minFiles) {
    //   numImages = minFiles * -1;
    // }
    if (numImages < 0) {
      numImages = dropzone.files.length + numImages;
    } else {
      numImages = dropzone.files.length;
    }
    return numImages;
  }
  openPopUpForFormatUsed(format) {
    this.dialog.open(MaxExtensionFileDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      maxWidth: '100vw',
      panelClass: 'MaxExtensionFilesDialog',
      data: {
        format: format,
      },
    });
  }
  validateFormatModel(file: DropzoneFile, filesLoaded) {
    const format = this.checkFormatModel(file.name);
    switch (format) {
      case 'jpg':
        let flag = true;
        for (const entry of filesLoaded) {
          const entryFormat = this.checkFormatModel(entry.name);
          if (
            entryFormat != 'jpg' &&
            entryFormat != 'png' &&
            entryFormat != 'obj' &&
            entryFormat != 'mtl' &&
            entryFormat != 'ply'
          ) {
            return 0;
          }
          if (entryFormat == 'obj' || entryFormat == 'ply') {
            flag = false;
          }
        }

        this.eventService.emit(
          new EventData('incorrectFormatModelDropzone', flag)
        );
        return 1;
      case 'png':
        let flagPng = true;
        for (const entry of filesLoaded) {
          const entryFormat = this.checkFormatModel(entry.name);
          if (
            entryFormat != 'jpg' &&
            entryFormat != 'png' &&
            entryFormat != 'obj' &&
            entryFormat != 'mtl' &&
            entryFormat != 'ply'
          ) {
            return 0;
          }
          if (entryFormat == 'obj' || entryFormat == 'ply') {
            flagPng = false;
          }
        }

        this.eventService.emit(
          new EventData('incorrectFormatModelDropzone', flagPng)
        );
        return 1;
      case 'ply':
        for (const entry of filesLoaded) {
          const entryFormat = this.checkFormatModel(entry.name);
          if (entryFormat == 'ply') {
            this.openPopUpForFormatUsed(entryFormat);
            return 2;
          }
          if (entryFormat != 'jpg' && entryFormat != 'png') {
            return 0;
          }
        }
        this.eventService.emit(
          new EventData('incorrectFormatModelDropzone', false)
        );
        return 1;
      case 'obj':
        for (const entry of filesLoaded) {
          const entryFormat = this.checkFormatModel(entry.name);
          if (entryFormat == 'obj') {
            this.openPopUpForFormatUsed(entryFormat);
            return 2;
          }
          if (
            entryFormat != 'jpg' &&
            entryFormat != 'png' &&
            entryFormat != 'mtl'
          ) {
            return 0;
          }
        }

        this.eventService.emit(
          new EventData('incorrectFormatModelDropzone', false)
        );
        return 1;
      case 'mtl':
        let objNotFound = true;
        for (const entry of filesLoaded) {
          const entryFormat = this.checkFormatModel(entry.name);
          if (entryFormat == 'mtl') {
            this.openPopUpForFormatUsed(entryFormat);
            return 2;
          }

          if (
            entryFormat != 'jpg' &&
            entryFormat != 'png' &&
            entryFormat != 'obj'
          ) {
            return 0;
          }
          if (entryFormat == 'obj') {
            objNotFound = false;
          }
        }
        this.eventService.emit(
          new EventData('incorrectFormatModelDropzone', objNotFound)
        );
        return 1;
      case 'stl':
        if (filesLoaded.length === 0) {
          return 1;
        }
        for (const entry of filesLoaded) {
          const entryFormat = this.checkFormatModel(entry.name);
          if (entryFormat == 'stl') {
            this.openPopUpForFormatUsed(entryFormat);
            return 2;
          }
        }
        return 0;
      case 'las':
        if (filesLoaded.length === 0) {
          return 1;
        }
        for (const entry of filesLoaded) {
          const entryFormat = this.checkFormatModel(entry.name);
          if (entryFormat == 'las') {
            this.openPopUpForFormatUsed(entryFormat);
            return 2;
          }
        }
        return 0;

      case 'e57':
        if (filesLoaded.length === 0) {
          return 1;
        }
        for (const entry of filesLoaded) {
          const entryFormat = this.checkFormatModel(entry.name);
          if (entryFormat == 'e57') {
            this.openPopUpForFormatUsed(entryFormat);
            return 2;
          }
        }
        return 0;
      case 'laz':
        if (filesLoaded.length === 0) {
          return 1;
        }
        for (const entry of filesLoaded) {
          const entryFormat = this.checkFormatModel(entry.name);
          if (entryFormat == 'laz') {
            this.openPopUpForFormatUsed(entryFormat);
            return 2;
          }
        }
        return 0;
      case 'asc':
        if (filesLoaded.length === 0) {
          return 1;
        }
        for (const entry of filesLoaded) {
          const entryFormat = this.checkFormatModel(entry.name);
          if (entryFormat == 'asc') {
            this.openPopUpForFormatUsed(entryFormat);
            return 2;
          }
        }
        return 0;
      default:
        break;
    }

    return 0;
  }

  checkFormatModel(name: string) {
    const split = name.split('.');
    const extension = split[split.length - 1].toLowerCase();
    if (extension === 'jpg' || extension === 'jpeg') {
      return 'jpg';
    } else if (this.formatsValidModel.includes(extension)) {
      return extension;
    }
    return '';
  }

  validateCombinations(filesLoaded) { }
  /* FUNCIONES CON LOS ESTILOS */
  addDzLoadingImageVideo(item = null) {
    // if (item !== null) {
    //   $(item.previewElement.childNodes[0]).prepend(
    //     $(
    //       '<div class="dz-loading"><img src="/assets/images/main/new3d/dropzone/loading-icon.svg" alt=""></div>'
    //     )
    //   );
    // }
    // $('.dz-image')
    //   .find('img')
    //   .hover(() => {
    //     $('.dz-image').find('img').css({ transform: 'none', filter: 'none' });
    //   });

    $(item.previewElement)
      .find('.dz-image')
      .find('img')
      .css('filter', 'brightness(0.5)');

    const $loadingCircle = $(
      `<div class="dz-loading-circle" style="display: flex; position: absolute; z-index: 100; width: 30%; height: 30%; top: 50%; left: 50%; transform: translate(-50%, -50%) rotate(270deg);">
        <svg viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="48" fill="none" stroke="#ffd900" stroke-width="10" stroke-opacity="1" stroke-dasharray="302" stroke-dashoffset="302" style="transition: stroke-dashoffset 0.2s ease-in-out;"></circle>
        <circle cx="50" cy="50" r="48" fill="none" stroke="#ffd900" stroke-width="10" stroke-opacity="0.1"></circle>
        </svg>
      </div>`
    );

    $(item.previewElement).prepend($loadingCircle);
  }

  addDzLoadingModel(item = null) {
    $('.dz-details').remove();
    $('.dz-progress').remove();
    if (item != null) {
      $(item.previewElement.childNodes[0]) // .dz-image
        .prepend(
          $(
            '<div class="dz-loading"><img src="/assets/images/main/new3d/dropzone/loading-icon.svg" alt=""></div>'
          )
        );
    }

    const body = `
      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    `;
    let dynamicStyles = null;
    if (!dynamicStyles) {
      dynamicStyles = document.createElement('style');
      dynamicStyles.type = 'text/css';
      document.head.appendChild(dynamicStyles);
    }
    dynamicStyles.sheet.insertRule(body, dynamicStyles.length);

    $('.dz-loading').find('img').css({
      animation: 'spin 2s linear infinite',
    });

    $('.dz-details .dz-size').remove();
    $('.dz-details .dz-filename').remove();
  }

  addDzLoadingBlkHyperspectral(item = null) {
    $('.dz-details').remove();
    $('.dz-progress').remove();
    if (item != null) {
      $(item.previewElement).addClass('dropzone-images');

      $(item.previewElement.childNodes[1]) // .dz-image
        .prepend(
          $(
            '<div class="dz-loading"><img src="/assets/images/main/new3d/dropzone/loading-icon.svg" alt=""></div>'
          )
        );
    }

    $('.dz-image')
      .find('img')
      .hover(() => {
        $('.dz-image').find('img').css({ transform: 'none', filter: 'none' });
      });
    $('.dz-details .dz-size').remove();
    $('.dz-details .dz-filename').remove();
  }

  addDzLoadOkImageVideo(
    item: DropzoneFile,
    fileUploadResponse: FileUploadResponse,
    type
  ) {
    if (item && item.previewElement) {
      $(item.previewElement).find('.dz-loading-circle').remove();
      $(item.previewElement)
        .find('.dz-image')
        .find('img')
        .css('filter', 'brightness(1)');
      let fromVideo = false;
      if (type === 'VIDEO') {
        fromVideo = true;
      }
      $('.dz-image').css({
        transform: 'rotate(0)',
      });
      $(item.previewElement).find($('.dz-loading')).remove();
      $(item.previewElement).attr('data-name', fileUploadResponse.name);
      $(item.previewElement).attr('data-size', fileUploadResponse.size);

      $(item.previewElement).prepend(
        $(
          '<div class="dz-load-ok"><img src="/assets/images/main/new3d/dropzone/loaded-icon.svg"></div>'
        )
      );
      if (fromVideo) {
        $(item.previewElement)
          .children('.dz-image')
          .prepend(
            $(
              '<div class="dz-thumnail"><img src="/assets/images/main/new3d/dropzone/vid-bg-icon.svg"></div>'
            )
          );
      }
      if (fromVideo) {
        $('.dz-thumnail').find('img').css({
          width: '100%',
          height: '100%',
        });
      }
    } else {
      // $('.dz-image').prepend(
      //   $(
      //     '<div class="dz-load-ok"><img src="/assets/images/main/new3d/dropzone/loaded-icon.svg"></div>'
      //   )
      // );
    }
  }

  addDzLoadOkModel(file: DropzoneFile, fileUploadResponse: FileUploadResponse) {
    $(file.previewElement).find($('.dz-loading')).remove();
    $(file.previewElement).find($('.dz-success-mark')).remove();
    $(file.previewElement).attr('data-name', fileUploadResponse.name);
    $(file.previewElement).attr('data-size', fileUploadResponse.size);

    $(file.previewElement).prepend(
      $(
        '<div class="dz-load-ok"><img src="/assets/images/main/new3d/dropzone/loaded-icon.svg"></div>'
      )
    );
  }

  addDzLoadOkBlkHyperspectral(
    file: DropzoneFile,
    fileUploadResponse: FileUploadResponse
  ) {
    $(file.previewElement).find($('.dz-loading')).remove();
    $(file.previewElement).find($('.dz-success-mark')).remove();
    $(file.previewElement).find($('.dz-message')).remove();
    $(file.previewElement).attr('data-name', fileUploadResponse.name);
    $(file.previewElement).attr('data-size', fileUploadResponse.size);

    $(file.previewElement).prepend(
      $(
        '<div class="dz-load-ok"><img src="/assets/images/main/new3d/dropzone/loaded-icon.svg"></div>'
      )
    );
  }

  addDzViewImageVideo(parentClass: string, type) {
    let fromVideo = false;
    if (type === 'VIDEO') {
      fromVideo = true;
    }
    $('.dz-load-ok').remove();
    const image = 'search.svg';

    $(parentClass + ' .dz-image').prepend(
      $(
        '<div class="dz-view"><img src="/assets/images/new3d/' +
        image +
        '"></div>'
      )
    );
  }

  addDzRemoveImageVideo(parentContainer) {
    const parent = $(parentContainer);

    // Crear un array para almacenar los elementos seleccionados
    const removedItemsArray = [];

    // Remover cualquier elemento existente de confirmación en este contenedor específico
    parent.find('.dz-load-ok').remove();

    // Añadir el ícono de eliminación a cada elemento individual
    parent.find('.dz-image').each(function () {
      // Evitar añadir múltiples íconos al mismo elemento
      if (!$(this).find('.dz-remove').length) {
        const removeIcon = $(
          '<div class="dz-remove dz-images"><img src="/assets/images/main/new3d/dropzone/deleting-non-selected-icon.svg"></div>'
        );

        $(this).prepend(removeIcon);

        // Añadir evento de clic al ícono de eliminación
        removeIcon.on('click', function () {
          const iconImg = $(this).find('img');
          const currentSrc = iconImg.attr('src');

          // Verificar si ya está seleccionado o no
          if (currentSrc.includes('deleting-non-selected-icon.svg')) {
            // Cambiar a seleccionado
            iconImg.attr(
              'src',
              '/assets/images/main/new3d/dropzone/deleting-icon.svg'
            );

            // Añadir al array de elementos seleccionados
            if (!removedItemsArray.includes(parentContainer)) {
              removedItemsArray.push(parentContainer);
            }
          } else {
            // Cambiar de vuelta a no seleccionado
            iconImg.attr(
              'src',
              '/assets/images/main/new3d/dropzone/deleting-non-selected-icon.svg'
            );

            // Eliminar del array de elementos seleccionados
            const index = removedItemsArray.indexOf(parentContainer);
            if (index > -1) {
              removedItemsArray.splice(index, 1);
            }
          }
        });
      }
    });

    // Añadir estilo inicial solo al contenedor actual
    parent.find('.dropzone-images img').css({
      cursor: 'pointer',
    });
    parent.find('.dz-image').css({
      transform: 'rotate(10deg)',
    });
  }
}
