export const META_CONFIG_I18N_ENTRIES = {
  meta_config_Data1: {
    es: '<p>index, follow</p>',
    en: '<p>index, follow</p>',
    pt: '<p>index, follow</p>',
    fr: '<p>index, follow</p>',
    eus: '<p>index, follow</p>',
    cat: '<p>index, follow</p>',
  },

  meta_config_Data2: {
    es: '<p>noindex, follow</p>',
    en: '<p>noindex, follow</p>',
    pt: '<p>noindex, follow</p>',
    fr: '<p>noindex, follow</p>',
    eus: '<p>noindex, follow</p>',
    cat: '<p>noindex, follow</p>',
  },

  meta_config_Data3: {
    es: '<p>index, nofollow</p>',
    en: '<p>index, nofollow</p>',
    pt: '<p>index, nofollow</p>',
    fr: '<p>index, nofollow</p>',
    eus: '<p>index, nofollow</p>',
    cat: '<p>index, nofollow</p>',
  },

  meta_config_Data4: {
    es: '<p>noindex, nofollow</p>',
    en: '<p>noindex, nofollow</p>',
    pt: '<p>noindex, nofollow</p>',
    fr: '<p>noindex, nofollow</p>',
    eus: '<p>noindex, nofollow</p>',
    cat: '<p>noindex, nofollow</p>',
  },

  meta_config_Data5: {
    es: '<p>Inicio</p>',
    en: '<p>Home</p>',
    pt: '<p>Início</p>',
    fr: '<p>Accueil</p>',
    eus: '<p>Hasiera</p>',
    cat: '<p>Inici</p>',
  },

  meta_config_Data6: {
    es: '<p>Haz tu modelo 3D</p>',
    en: '<p>Make your own 3D model</p>',
    pt: '<p>Crie o seu modelo 3D</p>',
    fr: '<p>Créez votre modèle 3D</p>',
    eus: '<p>Sortu zure 3D eredua</p>',
    cat: '<p>Fes el teu model 3D</p>',
  },

  meta_config_Dat5: {
    es: '<p>Cargar</p>',
    en: '<p>Upload</p>',
    pt: '<p>Télécharger</p>',
    fr: '<p>Carregar</p>',
    eus: '<p>Kargatu</p>',
    cat: '<p>Carrega</p>',
  },

  meta_config_Data8: {
    es: '<p>Importar</p>',
    en: '<p>Import</p>',
    fr: '<p>Importer</p>',
    pt: '<p>Importação</p>',
    eus: '<p>Inportatu</p>',
    cat: '<p>Importa</p>',
  },

  meta_config_Data9: {
    es: '<p>Galería</p>',
    en: '<p>Gallery</p>',
    fr: '<p>Galerie</p>',
    pt: '<p>A minha galeria</p>',
    eus: '<p>Galeria</p>',
    cat: '<p>Galeria</p>',
  },

  meta_config_Data10: {
    es: '<p>Modelos 3D en proceso</p>',
    en: '<p>3D Models in progress</p>',
    fr: '<p>Modèles 3D en cours</p>',
    pt: '<p>Modelos 3D em processamento</p>',
    eus: '<p>Abian dauden 3D ereduak</p>',
    cat: '<p>Models 3D en curs</p>',
  },

  meta_config_Data11: {
    es: '<p>Tarifas</p>',
    en: '<p>Plans</p>',
    fr: '<p>Tarifs</p>',
    pt: '<p>Planos</p>',
    eus: '<p>Tarifak</p>',
    cat: '<p>Tarifes</p>',
  },

  meta_config_Data12: {
    es: '<p>Promociones</p>',
    en: '<p>Promotions</p>',
    fr: '<p>Mes promotions</p>',
    pt: '<p>Promoções</p>',
    eus: '<p>Promozioak</p>',
    cat: '<p>Promocions</p>',
  },

  meta_config_Data13: {
    es: '<p>Área Personal</p>',
    en: '<p>Personal area</p>',
    fr: '<p>Espace personnel</p>',
    pt: '<p>Área pessoal</p>',
    eus: '<p>Eremu pertsonala</p>',
    cat: '<p>Àrea personal</p>',
  },

  meta_config_Data14: {
    es: '<p>Mi cuenta</p>',
    en: '<p>My account</p>',
    fr: '<p>Mon compte</p>',
    pt: '<p>A Minha Conta</p>',
    eus: '<p>Nire kontua</p>',
    cat: '<p>Compte</p>',
  },

  meta_config_Data15: {
    es: '<p>Modelo 3D</p>',
    en: '<p>3D model</p>',
    fr: '<p>Modèle 3D</p>',
    pt: '<p>O meu modelo 3D</p>',
    eus: '<p>3D eredua</p>',
    cat: '<p>Model 3D</p>',
  },

  meta_config_Data16: {
    es: '<p>Visor</p>',
    en: '<p>Viewer</p>',
    fr: '<p>Visionneuse</p>',
    pt: '<p>Visor 3D</p>',
    eus: '<p>Ikustailea</p>',
    cat: '<p>Visor model 3D</p>',
  },
};
