export const PROFILE_I18N_ENTRIES = {
  profile_Data1: {
    es: '<p>pago</p>',
    en: '<p>payment</p>',
    pt: '<p>pagamento</p>',
    fr: '<p>Paiement</p>',
    eus: '<p>ordainketa</p>',
    cat: '<p>pagament</p>',
  },

  profile_Data2: {
    es: '<p>Tu compra se ha realizado con éxito.</p>',
    en: '<p>Your purchase has been made correctly.</p>',
    pt: '<p>A sua compra foi efetuada com sucesso.</p>',
    fr: '<p>Votre achat a été effectué avec succès.</p>',
    eus: '<p>Zure erosketa behar bezala burutu da.</p>',
    cat: '<p>La compra s\'ha completat correctament.</p>',
  },

  profile_Data3: {
    es: '<p>Enviaremos un recibo del pedido a tu dirección de correo electrónico.</p>',
    en: '<p>We will send an order receipt to your email address.</p>',
    pt: '<p>Enviaremos um recibo do pedido para o seu e-mail.</p>',
    fr: '<p>Nous vous enverrons la confirmation de votre commande par e-mail.</p>',
    eus: '<p>Eskariaren ordainagiria zure helbide elektronikora bidaliko dizugu.</p>',
    cat: '<p>Enviarem un rebut de la comanda a la teva adreça de correu electrònic.</p>',
  },

  profile_Data4: {
    es: '<p>¿Está seguro de que quiere cancelar tu suscripción?</p>',
    en: '<p>Are you sure you want to cancel your subscription?</p>',
    pt: '<p>Tem a certeza de que pretende cancelar a sua subscrição?</p>',
    fr: '<p>Êtes-vous sûr(e) d\'annuler votre abonnement?</p>',
    eus: '<p>Ziur zaude zure harpidetza bertan behera utzi nahi duzula?</p>',
    cat: '<p>Segur que vols cancel·lar la subscripció?</p>',
  },

  profile_Data5: {
    es: '<p>Al confirmar esta acción perderá los beneficios asociados a tu plan actual.</p>',
    en: '<p>By confirming this action, you will lose the benefits associated with your current plan.</p>',
    pt: '<p>Ao confirmar esta ação, perderá os benefícios associados ao seu plano atual.</p>',
    fr: '<p>En confirmant cette action, vous perdrez les avantages liés à votre abonnement actuel.</p>',
    eus: '<p>Ekintza hori berresten baduzu, oraingo planari lotutako onurak galduko dituzu.</p>',
    cat: '<p>En confirmar aquesta acció, perdràs els beneficis associats al pla actual.</p>',
  },

  profile_Data6: {
    es: '<p>NO, MANTENER</p>',
    en: '<p>NO, KEEP</p>',
    pt: '<p>NÃO, MANTER</p>',
    fr: '<p>NON, MAINTENIR</p>',
    eus: '<p>EZ, MANTENTZEA</p>',
    cat: '<p>NO, MANTENIR</p>',
  },

  profile_Data7: {
    es: '<p>SÍ, CANCELAR</p>',
    en: '<p>YES, CANCEL IT</p>',
    pt: '<p>SIM, CANCELAR</p>',
    fr: '<p>OUI, ANNULER</p>',
    eus: '<p>BAI, BERTAN BEHERA UTZI</p>',
    cat: '<p>SÍ, CANCEL·LA-LA</p>',
  },

  profile_Data8: {
    es: '<p>¿Estás seguro de que quieres reactivar tu suscripción?</p>',
    en: '<p>Are you sure you want to reactivate your subscription?</p>',
    pt: '<p>Tem a certeza de que pretende reativar a sua subscrição?</p>',
    fr: '<p>Êtes-vous sûr(e) de vouloir réactiver votre abonnement ?</p>',
    eus: '<p>Ziur zaude zure harpidetza berraktibatu nahi duzula?</p>',
    cat: '<p>Segur que vols reactivar la subscripció?</p>',
  },

  profile_Data9: {
    es: '<p>Al confirmar esta acción recuperarás los beneficios asociados a tu plan que anteriormente tenías contratado.</p>',
    en: '<p>By confirming this action, you will be able to enjoy the benefits associated with the plan you had previously.</p>',
    pt: '<p>Ao confirmar esta ação, recuperará os benefícios associados ao plano anteriormente contratado.</p>',
    fr: '<p>En confirmant cette action, vous récupérerez les avantages liés à votre abonnement souscrit précédemment.</p>',
    eus: '<p>Ekintza hori berresten baduzu, lehenik kontratatuta zenuen planari lotutako onurak berreskuratuko dituzu.</p>',
    cat: '<p>En confirmar aquesta acció, perdràs els beneficis associats al pla que tenies contractat abans.</p>',
  },

  profile_Data10: {
    es: '<p>NO, MANTENER</p>',
    en: '<p>NO, DON\'T REACTIVATE IT</p>',
    pt: '<p>NÃO, MANTER</p>',
    fr: '<p>NON, MAINTENIR</p>',
    eus: '<p>EZ, EZ BERRAKTIBATU</p>',
    cat: '<p>NO, MANTINGUES-LA</p>',
  },

  profile_Data11: {
    es: '<p>SÍ, REACTIVAR</p>',
    en: '<p>YES, REACTIVATE IT</p>',
    pt: '<p>SIM, REATIVAR</p>',
    fr: '<p>OUI, RÉACTIVER</p>',
    eus: '<p>BAI, BERRAKTIBATU</p>',
    cat: '<p>SÍ, REACTIVA-LA</p>',
  },

  profile_Data12: {
    es: '<p>Seguir comprando</p>',
    en: '<p>Continue shopping</p>',
    pt: '<p>Continuar a comprar</p>',
    fr: '<p>Continuer vos achats</p>',
    eus: '<p>Erosten jarraitu</p>',
    cat: '<p>Continua comprant</p>',
  },

  profile_Data13: {
    es: '<p>Ir a generación de modelo 3D</p>',
    en: '<p>Go to 3D model generation</p>',
    pt: '<p>Ir para criação de modelo 3D</p>',
    fr: '<p>Aller à la génération du modèle 3D</p>',
    eus: '<p>Joan 3D eredua sortzera</p>',
    cat: '<p>Ves a generació de model 3D</p>',
  },

  profile_Data14: {
    es: '<p>ir al método de pago</p>',
    en: '<p>go to payment method</p>',
    pt: '<p>ir para método de pagamento</p>',
    fr: '<p>aller au mode de paiement</p>',
    eus: '<p>joan ordainketa metodora</p>',
    cat: '<p>ves al mètode de pagament</p>',
  },

  profile_Data15: {
    es: '<p>total</p>',
    en: '<p>total</p>',
    pt: '<p>total</p>',
    fr: '<p>total</p>',
    eus: '<p>guztira</p>',
    cat: '<p>total</p>',
  },

  profile_Data16: {
    es: '<p>Id de pago</p>',
    en: '<p>Payment ID</p>',
    pt: '<p>ID de pagamento</p>',
    fr: '<p>Identifiant de paiement</p>',
    eus: '<p>Ordainketa-ident.</p>',
    cat: '<p>ID de pagament</p>',
  },

  profile_Data17: {
    es: '<p>Tarifa contratada:</p>',
    en: '<p>Current plan:</p>',
    pt: '<p>Tarifa contratada:</p>',
    fr: '<p>Tarif choisi :</p>',
    eus: '<p>Kontratatutako tarifa:</p>',
    cat: '<p>Tarifa contractada:</p>',
  },

  profile_Data18: {
    es: '<p>Fecha de compra:</p>',
    en: '<p>Purchase date:</p>',
    pt: '<p>Data de compra:</p>',
    fr: '<p>Date d\'achat :</p>',
    eus: '<p>Erosketa-data:</p>',
    cat: '<p>Data de compra:</p>',
  },

  profile_Data19: {
    es: '<p>Precio de compra:</p>',
    en: '<p>Purchase price:</p>',
    pt: '<p>Preço de compra:</p>',
    fr: '<p>Prix d\'achat :</p>',
    eus: '<p>Erosketa-prezioa:</p>',
    cat: '<p>Preu de compra:</p>',
  },

  profile_Data20: {
    es: '<p>>> Descargar factura</p>',
    en: '<p>>> Download invoice</p>',
    pt: '<p>>> Transferir fatura</p>',
    fr: '<p>>> Télécharger la facture</p>',
    eus: '<p>>> Deskargatu faktura</p>',
    cat: '<p>>> Baixa la factura</p>',
  },

  profile_Data21: {
    es: '<p>Historial de pago</p>',
    en: '<p>Payment record</p>',
    pt: '<p>Histórico de pagamentos</p>',
    fr: '<p>Historique de paiements</p>',
    eus: '<p>Ordainketen historia</p>',
    cat: '<p>Historial de pagament</p>',
  },

  profile_Data22: {
    es: '<p>Área Personal</p>',
    en: '<p>Personal Area</p>',
    pt: '<p>Área pessoal</p>',
    fr: '<p>Espace personnel</p>',
    eus: '<p>Eremu pertsonala</p>',
    cat: '<p>Àrea personal</p>',
  },

  profile_Data23: {
    es: '<p>Tarifas</p>',
    en: '<p>Plans</p>',
    pt: '<p>Tarifas</p>',
    fr: '<p>Tarifs</p>',
    eus: '<p>Tarifak</p>',
    cat: '<p>Tarifes</p>',
  },

  profile_Data24: {
    es: '<p>Promociones</p>',
    en: '<p>Promotions</p>',
    pt: '<p>Promoções</p>',
    fr: '<p>Promotions</p>',
    eus: '<p>Promozioak</p>',
    cat: '<p>Promocions</p>',
  },

  profile_Data25: {
    es: '<p>Datos Personales</p>',
    en: '<p>User Details</p>',
    pt: '<p>Dados pessoais</p>',
    fr: '<p>Données personnelles</p>',
    eus: '<p>Datu pertsonalak</p>',
    cat: '<p>Dades personals</p>',
  },

  profile_Data26: {
    es: '<p>Cambio de contraseña</p>',
    en: '<p>Change password</p>',
    pt: '<p>Alteração de palavra-passe</p>',
    fr: '<p>Changer le mot de passe</p>',
    eus: '<p>Aldatu pasahitza</p>',
    cat: '<p>Canvi de contrasenya</p>',
  },

  profile_Data27: {
    es: '<p>Editar</p>',
    en: '<p>Edit</p>',
    pt: '<p>Editar</p>',
    fr: '<p>Éditer</p>',
    eus: '<p>Editatu</p>',
    cat: '<p>Modifica</p>',
  },

  profile_Data28: {
    es: '<p>País</p>',
    en: '<p>Country</p>',
    pt: '<p>País</p>',
    fr: '<p>Pays</p>',
    eus: '<p>Herrialdea</p>',
    cat: '<p>País</p>',
  },

  profile_Data29: {
    es: '<p>Región:</p>',
    en: '<p>Region:</p>',
    pt: '<p>Região:</p>',
    fr: '<p>Région :</p>',
    eus: '<p>Eskualdea:</p>',
    cat: '<p>Regió:</p>',
  },

  profile_Data30: {
    es: '<p>Ciudad</p>',
    en: '<p>City</p>',
    pt: '<p>Cidade</p>',
    fr: '<p>Ville </p>',
    eus: '<p>Hiria</p>',
    cat: '<p>Ciutat</p>',
  },

  profile_Data31: {
    es: '<p>Teléfono</p>',
    en: '<p>Phone</p>',
    pt: '<p>Número de telefone</p>',
    fr: '<p>Téléphone</p>',
    eus: '<p>Telefonoa</p>',
    cat: '<p>Telèfon</p>',
  },

  profile_Data32: {
    es: '<p>Teléfono móvil:</p>',
    en: '<p>Cellphone:</p>',
    pt: '<p>Número de telemóvel:</p>',
    fr: '<p>Téléphone portable :</p>',
    eus: '<p>Telefono mugikorra:</p>',
    cat: '<p>Telèfon mòbil:</p>',
  },

  profile_Data33: {
    es: '<p>Estoy seguro/a</p>',
    en: '<p>I\'m sure</p>',
    pt: '<p>Tenho a certeza</p>',
    fr: '<p>Je suis sûr(e)</p>',
    eus: '<p>Seguru nago</p>',
    cat: '<p>N\'estic segur/a</p>',
  },

  profile_Data34: {
    es: '<p>Dar de baja el departamento</p>',
    en: '<p>Close department</p>',
    pt: '<p>Eliminar departamento</p>',
    fr: '<p>Supprimer le département</p>',
    eus: '<p>Baja eman departamentuari</p>',
    cat: '<p>Dona de baixa el departament</p>',
  },

  profile_Data35: {
    es: '<p>Dar de baja la cuenta</p>',
    en: '<p>Close account</p>',
    pt: '<p>Eliminar conta</p>',
    fr: '<p>Supprimer le compte</p>',
    eus: '<p>Baja eman kontuari</p>',
    cat: '<p>Dona de baixa el compte</p>',
  },

  profile_Data36: {
    es: '<p>¿Estás seguro/a?</p><p>Esta acción no se puede recuperar.</p>',
    en: '<p>Are you sure?</p><p>This action cannot be reversed.</p>',
    pt: '<p>Tem a certeza?</p><p>Esta ação não é reversível.</p>',
    fr: '<p>Êtes-vous sûr(e) ?</p><p>Cette action est irréversible.</p>',
    eus: '<p>Seguru zaude?</p><p>Ekintza hau ezin da desegin.</p>',
    cat: '<p>Segur?</p><p>Aquesta acció no es pot recuperar.</p>',
  },

  profile_Data37: {
    es: '<p>Si das de baja tu cuenta se eliminarán todos los datos,</p><p>modelos 3D e informacion del perfil de manera permanente.</p>',
    en: '<p>If you delete your account, all of the data,</p><p>3D models, and profile information will be permanently deleted.</p>',
    pt: '<p>Se eliminar a sua conta, todos os dados,</p><p>modelos 3D e informações de perfil serão permanentemente eliminados.</p>',
    fr: '<p>Si vous supprimez votre compte, toutes les données,</p><p>modèles 3D et informations du profil seront éliminées de façon permanente.</p>',
    eus: '<p>Zure kontuari baja ematen badiozu, betiko ezabatuko dira datu guztiak, 3D ereduak eta profilari buruzko informazioa.</p>',
    cat: '<p>Si dones de baixa el teu compte, s\'eliminaran totes les dades,</p><p>els models 3D i la informació del perfil de forma permanent.</p>',
  },

  profile_Data38: {
    es: '<p>Si le das de baja el departamento, se eliminarán todos los datos, modelos 3D e información de manera permanente.</p>',
    en: '<p>If you close the department, all of the data, 3D models, and information will be permanently deleted.</p>',
    pt: '<p>Se eliminar o departamento, também serão eliminados todos os dados, modelos 3D e informações de forma permanente.</p>',
    fr: '<p>Si vous supprimez le département, toutes les données, tous les modèles 3D et toutes les informations seront éliminées de façon permanente.</p>',
    eus: '<p>Departamentuari baja ematen badiozu, betiko ezabatuko dira datu guztiak, 3D ereduak eta informazioa.</p>',
    cat: '<p>Si dones de baixa el departament, s\'eliminaran totes les dades, els models 3D i la informació de forma permanent.</p>',
  },

  profile_Data39: {
    es: '<p>Por favor marque para confirmar</p>',
    en: '<p>Please check the box to confirm</p>',
    pt: '<p>Por favor, marque para confirmar</p>',
    fr: '<p>Veuillez confirmer</p>',
    eus: '<p>Berretsi aurrera egin nahi duzula</p>',
    cat: '<p>Marca per confirmar</p>',
  },

  profile_Data40: {
    es: '<p>Datos Empresa</p>',
    en: '<p>Company Details</p>',
    pt: '<p>Dados da empresa</p>',
    fr: '<p>Données entreprise</p>',
    eus: '<p>Enpresaren datuak</p>',
    cat: '<p>Dades empresa</p>',
  },

  profile_Data41: {
    es: '<p>Sectores</p>',
    en: '<p>Sectors</p>',
    pt: '<p>Setores</p>',
    fr: '<p>Secteurs</p>',
    eus: '<p>Sektoreak</p>',
    cat: '<p>Sectors</p>',
  },

  profile_Data42: {
    es: '<p>Recortar la foto</p>',
    en: '<p>Crop photo</p>',
    pt: '<p>Découper la photo</p>',
    fr: '<p>Rogner la photo</p>',
    eus: '<p>Ebaki argazkia</p>',
    cat: '<p>Retalla la foto</p>',
  },

  profile_Data43: {
    es: '<p>Panel de Control</p>',
    en: '<p>Control Panel</p>',
    pt: '<p>Painel de controlo</p>',
    fr: '<p>Tableau de bord</p>',
    eus: '<p>Kontrol panela</p>',
    cat: '<p>Tauler de control</p>',
  },

  profile_Data44: {
    es: '<p>(Admin Empresas)</p>',
    en: '<p>(Company Admin)</p>',
    pt: '<p>(Administração de empresas)</p>',
    fr: '<p>(Admin entreprise)</p>',
    eus: '<p>(Enpresa Admin.)</p>',
    cat: '<p>(Admin. empreses)</p>',
  },

  profile_Data45: {
    es: '<p>Usuarios</p>',
    en: '<p>Users</p>',
    pt: '<p>Utilizadores</p>',
    fr: '<p>Utilisateurs</p>',
    eus: '<p>Erabiltzaileak</p>',
    cat: '<p>Usuaris</p>',
  },

  profile_Data46: {
    es: '<p>Departamentos</p>',
    en: '<p>Departments</p>',
    pt: '<p>Departamentos</p>',
    fr: '<p>Départements</p>',
    eus: '<p>Departamentuak</p>',
    cat: '<p>Departaments</p>',
  },

  profile_Data47: {
    es: '<p>estadísticas</p>',
    en: '<p>statistics</p>',
    pt: '<p>estatísticas</p>',
    fr: '<p>statistiques</p>',
    eus: '<p>estatistikak</p>',
    cat: '<p>estadístiques</p>',
  },

  profile_Data48: {
    es: '<p>diarias</p>',
    en: '<p>daily</p>',
    pt: '<p>diárias</p>',
    fr: '<p>quotidiennes</p>',
    eus: '<p>egunekoak</p>',
    cat: '<p>diàries</p>',
  },

  profile_Data49: {
    es: '<p>semanales</p>',
    en: '<p>weekly</p>',
    pt: '<p>semanais</p>',
    fr: '<p>hebdomadaires</p>',
    eus: '<p>astekoak</p>',
    cat: '<p>setmanals</p>',
  },

  profile_Data50: {
    es: '<p>mensuales</p>',
    en: '<p>monthly</p>',
    pt: '<p>mensais</p>',
    fr: '<p>mensuelles</p>',
    eus: '<p>hilekoak</p>',
    cat: '<p>mensuals</p>',
  },

  profile_Data51: {
    es: '<p>todas</p>',
    en: '<p>all</p>',
    pt: '<p>todas</p>',
    fr: '<p>toutes</p>',
    eus: '<p>guztiak</p>',
    cat: '<p>totes</p>',
  },

  profile_Data52: {
    es: '<p>proyectos</p>',
    en: '<p>projects</p>',
    pt: '<p>projetos</p>',
    fr: '<p>projets</p>',
    eus: '<p>proiektuak</p>',
    cat: '<p>projectes</p>',
  },

  profile_Data53: {
    es: '<p>proyectos no visibles</p>',
    en: '<p>non-visible projects</p>',
    pt: '<p>projetos não visíveis</p>',
    fr: '<p>projets non visibles</p>',
    eus: '<p>ikusgai ez dauden proiektuak</p>',
    cat: '<p>projectes no visibles</p>',
  },

  profile_Data54: {
    es: '<p>proyectos visibles</p>',
    en: '<p>visible projects</p>',
    pt: '<p>projetos visíveis</p>',
    fr: '<p>projets visibles</p>',
    eus: '<p>ikusgai dauden proiektuak</p>',
    cat: '<p>projectes visibles</p>',
  },

  profile_Data55: {
    es: '<p>proyectos en venta</p>',
    en: '<p>projects for sale</p>',
    pt: '<p>projetos à venda</p>',
    fr: '<p>projets en vente</p>',
    eus: '<p>salgai dauden proiektuak</p>',
    cat: '<p>projectes en venda</p>',
  },

  profile_Data56: {
    es: '<p>Añadir nuevo usuario</p>',
    en: '<p>Add new user</p>',
    pt: '<p>Adicionar novo utilizador</p>',
    fr: '<p>Ajouter nouvel utilisateur</p>',
    eus: '<p>Gehitu erabiltzaile berria</p>',
    cat: '<p>Afegeix nou usuari</p>',
  },

  profile_Data57: {
    es: '<p>Importar CSV</p>',
    en: '<p>Import CSV</p>',
    pt: '<p>Importar CSV</p>',
    fr: '<p>Importer CSV</p>',
    eus: '<p>Inportatu CSV</p>',
    cat: '<p>Importa CSV</p>',
  },

  profile_Data58: {
    es: '<p>Inserta tu archivo con formato CSV</p>',
    en: '<p>Insert your file in CSV format</p>',
    pt: '<p>Insira o seu ficheiro em formato CSV</p>',
    fr: '<p>Insérez votre fichier au format CSV</p>',
    eus: '<p>Txertatu CSV formatua duen fitxategia</p>',
    cat: '<p>Insereix el teu fitxer amb format CSV</p>',
  },

  profile_Data59: {
    es: '<p>* nombre -> Nombre de usuario</p>',
    en: '<p>* nombre -> User\'s first name</p>',
    pt: '<p>* nombre -> Nome do utilizador</p>',
    fr: '<p>* nombre -> Prénom de l\'utilisateur</p>',
    eus: '<p>* nombre -> Erabiltzailearen izena</p>',
    cat: '<p>* nombre -> Nom de l\'usuari</p>',
  },

  profile_Data60: {
    es: '<p>* apellidos -> Apellidos de usuario</p>',
    en: '<p>* apellidos -> User\'s last name</p>',
    pt: '<p>* apellidos -> Apelidos do utilizador</p>',
    fr: '<p>* apellidos -> Nom de l\'utilisateur</p>',
    eus: '<p>* apellidos -> Erabiltzailearen abizenak</p>',
    cat: '<p>* apellidos -> Cognoms de l\'usuari</p>',
  },

  profile_Data61: {
    es: '<p>* correo -> Correo de acceso a la web</p>',
    en: '<p>* correo -> Email used to log in to the website</p>',
    pt: '<p>* correo -> E-mail de acesso ao website</p>',
    fr: '<p>* correo -> E-mail utilisé pour accéder au site web</p>',
    eus: '<p>* correo -> Webgunera sartzeko helbidea</p>',
    cat: '<p>* correo -> Correu d\'accés al web</p>',
  },

  profile_Data62: {
    es: '<p>* contraseña -> Contraseña de acceso a la web</p>',
    en: '<p>* contraseña -> Password used to log in the website</p>',
    pt: '<p>* contraseña -> Palavra-passe de acesso ao website</p>',
    fr: '<p>* contraseña -> Mot de passe utilisé pour accéder au site web</p>',
    eus: '<p>* contraseña -> Webgunera sartzeko pasahitza</p>',
    cat: '<p>* contraseña-> Contrasenya d\'accés al web</p>',
  },

  profile_Data63: {
    es: '<p>* tipo_de_usuario -> Tipo de usuario: Empleado o Administrador Dpto</p>',
    en: '<p>* tipo_de_usuario -> User type: Employee or Department Administrator</p>',
    pt: '<p>* tipo_de_usuario -> Tipo de utilizador: Funcionário ou Administrador de Departamento</p>',
    fr: '<p>* tipo_de_usuario -> Type d\'utilisateur: Employé ou Administrateur du département</p>',
    eus: '<p>* tipo_de_usuario -> Erabiltzaile mota: Langilea edo Dptu. Administratzailea</p>',
    cat: '<p>* tipo_de_usuario -> Tipus d\'usuari: Empleat o administrador de dept.</p>',
  },

  profile_Data64: {
    es: '<p>* nombre_departamento -> Nombre de departamento</p>',
    en: '<p>* nombre_departamento -> Department name</p>',
    pt: '<p>* nombre_departamento -> Nome do departamento</p>',
    fr: '<p>* nombre_departamento -> Nom du département</p>',
    eus: '<p>* nombre_departamento -> Departamentuaren izena</p>',
    cat: '<p>* nombre_departamento -> Nom del departament</p>',
  },

  profile_Data65: {
    es: '<p>(Si no existe, no se crea el nuevo usuario)</p>',
    en: '<p>(If this does not exist, the new user will not be created)</p>',
    pt: '<p>(Se não existir, o novo utilizador não será criado)</p>',
    fr: '<p>(S\'il n\'existe pas, aucun nouvel utilisateur n\'est créé)</p>',
    eus: '<p>(Ez badago horrelakorik, ez da sortuko erabiltzaile berria)</p>',
    cat: '<p>(Si no existeix, no es crea l\'usuari nou)</p>',
  },

  profile_Data66: {
    es: '<p>Seleccionar archivo:</p>',
    en: '<p>Select file:</p>',
    pt: '<p>Selecionar ficheiro:</p>',
    fr: '<p>Sélectionner fichier :</p>',
    eus: '<p>Hautatu fitxategia:</p>',
    cat: '<p>Selecciona fitxer:</p>',
  },

  profile_Data67: {
    es: '<p>Ningún archivo seleccionado.</p>',
    en: '<p>No file selected.</p>',
    pt: '<p>Nenhum ficheiro selecionado.</p>',
    fr: '<p>Aucun fichier sélectionné.</p>',
    eus: '<p>Ez da fitxategirik hautatu.</p>',
    cat: '<p>No hi ha cap fitxer seleccionat.</p>',
  },

  profile_Data68: {
    es: '<p>Añadir nuevo departamento</p>',
    en: '<p>Add new department</p>',
    pt: '<p>Adicionar novo departamento</p>',
    fr: '<p>Ajouter nouveau département</p>',
    eus: '<p>Gehitu departamentu berria</p>',
    cat: '<p>Afegeix nou departament</p>',
  },

  profile_Data69: {
    es: '<p>Añadir nuevo usuario</p>',
    en: '<p>Add new user</p>',
    pt: '<p>Adicionar novo utilizador</p>',
    fr: '<p>Ajouter nouvel utilisateur</p>',
    eus: '<p>Gehitu erabiltzaile berria</p>',
    cat: '<p>Afegeix nou usuari</p>',
  },

  profile_Data70: {
    es: '<p>Editar información de departamento</p>',
    en: '<p>Edit department information</p>',
    pt: '<p>Editar informação do departamento</p>',
    fr: '<p>Éditer les informations du département</p>',
    eus: '<p>Editatu departamentuaren informazioa</p>',
    cat: '<p>Modifica la informació de departament</p>',
  },

  profile_Data71: {
    es: '<p>Contraseña actual</p>',
    en: '<p>Current password</p>',
    pt: '<p>Palavra-passe atual</p>',
    fr: '<p>Mot de passe actuel</p>',
    eus: '<p>Uneko pasahitza</p>',
    cat: '<p>Contrasenya actual</p>',
  },

  profile_Data72: {
    es: '<p>CAMBIAR CONTRASEÑA</p>',
    en: '<p>CHANGE PASSWORD</p>',
    pt: '<p>ALTERAR PALAVRA-PASSE</p>',
    fr: '<p>CHANGER MOT DE PASSE</p>',
    eus: '<p>ALDATU PASAHITZA</p>',
    cat: '<p>CANVIA LA CONTRASENYA</p>',
  },

  profile_Data73: {
    es: '<p>Contraseña:</p>',
    en: '<p>Password:</p>',
    pt: '<p>Palavra-passe:</p>',
    fr: '<p>Mot de passe :</p>',
    eus: '<p>Pasahitza:</p>',
    cat: '<p>Contrasenya:</p>',
  },

  profile_Data74: {
    es: '<p>Nueva contraseña</p>',
    en: '<p>New password</p>',
    pt: '<p>Nova palavra-passe</p>',
    fr: '<p>Nouveau mot de passe</p>',
    eus: '<p>Pasahitz berria</p>',
    cat: '<p>Nova contrasenya</p>',
  },

  profile_Data75: {
    es: '<p>Confirmar nueva contraseña</p>',
    en: '<p>Confirm new password</p>',
    pt: '<p>Confirmar nova palavra-passe</p>',
    fr: '<p>Confirmer nouveau mot de passe</p>',
    eus: '<p>Berretsi pasahitz berria</p>',
    cat: '<p>Confirma la nova contrasenya</p>',
  },

  profile_Data76: {
    es: '<p>E-mail</p>',
    en: '<p>Email</p>',
    pt: '<p>E-mail</p>',
    fr: '<p>E-mail</p>',
    eus: '<p>Helbide elektronikoa</p>',
    cat: '<p>Correu electrònic</p>',
  },

  profile_Data77: {
    es: '<p>Repetir contraseña:</p>',
    en: '<p>Repeat password:</p>',
    pt: '<p>Repetir palavra-passe:</p>',
    fr: '<p>Ressaisir mot de passe :</p>',
    eus: '<p>Errepikatu pasahitza:</p>',
    cat: '<p>Repeteix la contrasenya:</p>',
  },

  profile_Data78: {
    es: 'Usuarios del Departamento:',
    en: 'Department users',
    pt: 'Utilizadores do departamento',
    fr: 'Utilisateurs du département',
    eus: 'Departamentuaren erabiltzaileak',
    cat: 'Usuaris del departament',
  },

  profile_Data79: {
    es: '<p>Nombre del departamento</p>',
    en: '<p>Department name</p>',
    pt: '<p>Nome do departamento</p>',
    fr: '<p>Nom du département</p>',
    eus: '<p>Departamentuaren izena</p>',
    cat: '<p>Nom del departament</p>',
  },

  profile_Data80: {
    es: '<p>ID</p>',
    en: '<p>ID</p>',
    pt: '<p>ID</p>',
    fr: '<p>ID</p>',
    eus: '<p>Ident.</p>',
    cat: '<p>ID</p>',
  },

  profile_Data81: {
    es: '<p>NOMBRE</p>',
    en: '<p>NAME</p>',
    pt: '<p>NOME</p>',
    fr: '<p>NOM</p>',
    eus: '<p>IZENA</p>',
    cat: '<p>NOM</p>',
  },

  profile_Data82: {
    es: '<p>OPCIONES</p>',
    en: '<p>OPTIONS</p>',
    pt: '<p>OPÇÕES</p>',
    fr: '<p>OPTIONS</p>',
    eus: '<p>AUKERAK</p>',
    cat: '<p>OPCIONS</p>',
  },

  profile_Data83: {
    es: '<p>FAQ</p>',
    en: '<p>FAQ</p>',
    pt: '<p>FAQ</p>',
    fr: '<p>FAQ</p>',
    eus: '<p>FAQ</p>',
    cat: '<p>PMF</p>',
  },

  profile_Data84: {
    es: '<p>Editar información acceso usuario</p>',
    en: '<p>Edit user login information</p>',
    pt: '<p>Editar informação de acesso do utilizador</p>',
    fr: '<p>Éditer les informations d\'accès de l\'utilisateur</p>',
    eus: '<p>Editatu erabiltzailearen sarbide informazioa</p>',
    cat: '<p>Modifica la informació d\'accés de l\'usuari</p>',
  },

  profile_Data85: {
    es: '<p>No se ha podido guardar la contraseña.</p>',
    en: '<p>The password could not be saved.</p>',
    pt: '<p>Não foi possível guardar a palavra-passe.</p>',
    fr: '<p>Le mot de passe n\'a pas pu être sauvegardé.</p>',
    eus: '<p>Ezin izan da pasahitza gorde.</p>',
    cat: '<p>No s\'ha pogut desar la contrasenya.</p>',
  },

  profile_Data86: {
    es: '<p>Inténtalo de nuevo.</p>',
    en: '<p>Please try again.</p>',
    pt: '<p>Tente novamente.</p>',
    fr: '<p>Veuillez réessayer.</p>',
    eus: '<p>Berriz saiatu.</p>',
    cat: '<p>Torna a provar-ho.</p>',
  },

  profile_Data87: {
    es: '<p>Cancelar suscripción</p>',
    en: '<p>Cancel subscription</p>',
    pt: '<p>Cancelar subscrição</p>',
    fr: '<p>Annuler abonnement</p>',
    eus: '<p>Utzi harpidetza</p>',
    cat: '<p>Cancel·la la subscripció</p>',
  },

  profile_Data88: {
    es: '<p>Perfil de Usuario</p>',
    en: '<p>User Profile</p>',
    pt: '<p>Perfil de utilizador</p>',
    fr: '<p>Profil de l\'utilisateur</p>',
    eus: '<p>Erabiltzaile-profila</p>',
    cat: '<p>Perfil d\'usuari</p>',
  },

  profile_Data89: {
    es: '<p>Cambiar idioma</p>',
    en: '<p>Change language</p>',
    pt: '<p>Alterar idioma</p>',
    fr: '<p>Changer langue</p>',
    eus: '<p>Aldatu hizkuntza</p>',
    cat: '<p>Canvia l\'idioma</p>',
  },

  profile_Data90: {
    es: '<p>Información</p>',
    en: '<p>Information</p>',
    pt: '<p>Informação</p>',
    fr: '<p>Information</p>',
    eus: '<p>Informazioa</p>',
    cat: '<p>Informació</p>',
  },

  profile_Data91: {
    es: '<p>Mi Cuenta</p>',
    en: '<p>My Account</p>',
    pt: '<p>A Minha Conta</p>',
    fr: '<p>Mon compte</p>',
    eus: '<p>Nire kontua</p>',
    cat: '<p>El meu compte</p>',
  },

  profile_Data92: {
    es: '<p>Volver atrás</p>',
    en: '<p>Go back</p>',
    pt: '<p>Regressar</p>',
    fr: '<p>Retour en arrière</p>',
    eus: '<p>Itzuli atzera</p>',
    cat: '<p>Torna enrere</p>',
  },

  profile_Data93: {
    es: '<p>Atrás</p>',
    en: '<p>Back</p>',
    pt: '<p>Atrás</p>',
    fr: '<p>Retour</p>',
    eus: '<p>Atzera</p>',
    cat: '<p>Enrere</p>',
  },

  profile_Data94: {
    es: '<p>Novedades</p>',
    en: '<p>News</p>',
    pt: '<p>Novidades</p>',
    fr: '<p>Nouveautés</p>',
    eus: '<p>Berrikuntzak</p>',
    cat: '<p>Novetats</p>',
  },

  profile_Data95: {
    es: '<p>TIPS captura</p>',
    en: '<p>Capturing TIPS</p>',
    pt: '<p>DICAS captação</p>',
    fr: '<p>Conseils de capture</p>',
    eus: '<p>Kapturatzeko aholkuak</p>',
    cat: '<p>Consells de captura</p>',
  },

  profile_Data96: {
    es: '<p>cerrar</p>',
    en: '<p>close</p>',
    pt: '<p>fechar</p>',
    fr: '<p>fermer</p>',
    eus: '<p>itxi</p>',
    cat: '<p>tanca</p>',
  },

  profile_Data97: {
    es: '<p>Ayuda</p>',
    en: '<p>Help</p>',
    pt: '<p>Ajuda</p>',
    fr: '<p>Assistance</p>',
    eus: '<p>Laguntza</p>',
    cat: '<p>Ajuda</p>',
  },

  profile_Data98: {
    es: '<p>Departamento:</p>',
    en: '<p>Department:</p>',
    pt: '<p>Departamento:</p>',
    fr: '<p>Département :</p>',
    eus: '<p>Departamentua:</p>',
    cat: '<p>Departament:</p>',
  },

  profile_Data99: {
    es: '<p>Continuar</p>',
    en: '<p>Continue</p>',
    pt: '<p>Continuar</p>',
    fr: '<p>Continuer</p>',
    eus: '<p>Jarraitu</p>',
    cat: '<p>Continua</p>',
  },

  profile_Data100: {
    es: '<p>NIF</p>',
    en: '<p>Tax ID</p>',
    pt: '<p>NIF</p>',
    fr: '<p>NIF</p>',
    eus: '<p>IFZ</p>',
    cat: '<p>NIF</p>',
  },

  profile_Data101: {
    es: '<p>Ha ocurrido un error en el proceso de pago</p>',
    en: '<p>An error has occurred during the payment process</p>',
    pt: '<p>Ocorreu um erro no processo de pagamento</p>',
    fr: '<p>Une erreur est survenue durant le processus de paiement</p>',
    eus: '<p>Errore bat gertatu da ordainketa prozesuan</p>',
    cat: '<p>Hi ha hagut un error en el procés de pagament</p>',
  },

  profile_Data102: {
    es: '<p>Por favor, comprueba tus datos, contacta con tu entidad bancaria o inténtalo de nuevo más tarde.</p>',
    en: '<p>Please check your information, contact your bank, or try again later.</p>',
    pt: '<p>Verifique os seus dados, contacte a sua entidade bancária ou tente novamente mais tarde.</p>',
    fr: '<p>Vérifiez les informations saisies, contactez votre banque ou réessayez plus tard.</p>',
    eus: '<p>Mesedez, egiaztatu zure datuak, jarri harremanetan zure bankuarekin edo saiatu berriro geroago.</p>',
    cat: '<p>Comprova les dades, posa\'t en contacte amb l\'entitat bancària o torna a provar-ho més tard.</p>',
  },

  profile_Data103: {
    es: '<p>Sector de la empresa</p>',
    en: '<p>Company sector</p>',
    pt: '<p>Setor da empresa</p>',
    fr: '<p>Secteur de l\'entreprise</p>',
    eus: '<p>Enpresaren sektorea</p>',
    cat: '<p>Sector de l\'empresa</p>',
  },

  profile_Data104: {
    es: '<p>Volver al modelo 3D para su descarga</p>',
    en: '<p>Return to the 3D model to download it</p>',
    pt: '<p>Voltar ao modelo 3D para transferência</p>',
    fr: '<p>Retourner au modèle 3D pour le télécharger</p>',
    eus: '<p>Itzuli 3d eredura deskarga egiteko</p>',
    cat: '<p>Torna al model 3D per baixar-lo</p>',
  },

  profile_Data105: {
    es: '<p>Ir a generación de modelo 3D</p>',
    en: '<p>Go to 3D model generation</p>',
    pt: '<p>Ir para criação de modelo 3D</p>',
    fr: '<p>Aller à la génération du modèle 3D</p>',
    eus: '<p>Joan 3D eredua sortzera</p>',
    cat: '<p>Ves a generació de model 3D</p>',
  },

  profile_Data106: {
    es: '<p>Ir a modelos 4PRO en proceso</p><p>de generación</p>',
    en: '<p>Go to 4PRO models</p><p>being generated</p>',
    pt: '<p>Ir para modelos 4PRO em processo</p><p>de criação</p>',
    fr: '<p>Aller aux modèles 4PRO en cours</p><p>de génération</p>',
    eus: '<p>Joan abian dauden</p><p>4PRO ereduetara</p>',
    cat: '<p>Ves a models 4PRO en procés</p><p>de generació</p>',
  },

  profile_Data107: {
    es: '<p>Ir a generación de modelo 3D</p>',
    en: '<p>Go to 3D model generation</p>',
    pt: '<p>Ir para criação de modelo 3D</p>',
    fr: '<p>Aller à la génération du modèle 3D</p>',
    eus: '<p>Joan 3D eredua sortzera</p>',
    cat: '<p>Ves a generació de model 3D</p>',
  },

  profile_Data108: {
    es: '<p>Seguir comprando</p>',
    en: '<p>Continue shopping</p>',
    pt: '<p>Continuar a comprar</p>',
    fr: '<p>Continuer vos achats</p>',
    eus: '<p>Erosten jarraitu</p>',
    cat: '<p>Continua comprant</p>',
  },

  profile_Data109: {
    es: '<p>Volver a tarifas</p>',
    en: '<p>Go back to plans</p>',
    pt: '<p>Voltar para tarifas</p>',
    fr: '<p>Retour aux tarifs</p>',
    eus: '<p>Itzuli tarifak atalera</p>',
    cat: '<p>Torna a Tarifes</p>',
  },

  profile_Data110: {
    es: '<p>Administrador</p>',
    en: '<p>Administrator</p>',
    pt: '<p>Administrador</p>',
    fr: '<p>Administrateur</p>',
    eus: '<p>Administratzailea</p>',
    cat: '<p>Administrador</p>',
  },

  profile_Data111: {
    es: '<p>Recomendación (descarga y adjunta plantilla):</p>',
    en: '<p>Recommendation (download and attach template):</p>',
    pt: '<p>Recomendação (transfira e anexe o modelo):</p>',
    fr: '<p>Recommandation (téléchargez et joignez le modèle) :</p>',
    eus: '<p>Gomendatzen dizugu (deskargatu eta erantsi txantiloia):</p>',
    cat: '<p>Recomanació (baixa i adjunta plantilla):</p>',
  },

  profile_Data112: {
    es: '<p>Descargar plantilla CSV</p>',
    en: '<p>Download CSV template</p>',
    pt: '<p>Transferir modelo CSV</p>',
    fr: '<p>Télécharger modèle CSV</p>',
    eus: '<p>Deskargatu CSV txantiloia</p>',
    cat: '<p>Baixa plantilla CSV</p>',
  },

  profile_Data113: {
    es: '<p>Tu archivo CSV se ha subido correctamente.</p>',
    en: '<p>Your CSV file has been uploaded correctly.</p>',
    pt: '<p>O seu ficheiro CSV foi carregado corretamente.</p>',
    fr: '<p>Votre fichier CSV a été téléchargé correctement.</p>',
    eus: '<p>Zure CSV fitxategia behar bezala kargatu da.</p>',
    cat: '<p>El fitxer CSV s\'ha pujat correctament.</p>',
  },

  profile_Data114: {
    es: '<p>Departamento añadido correctamente.</p>',
    en: '<p>Department added correctly.</p>',
    pt: '<p>Departamento adicionado corretamente.</p>',
    fr: '<p>Département ajouté correctement.</p>',
    eus: '<p>Departamentua behar bezala gehitu da.</p>',
    cat: '<p>El departament s\'ha afegit correctament.</p>',
  },

  profile_Data115: {
    es: '<p>El usuario se ha eliminado correctamente.</p>',
    en: '<p>User has been deleted correctly.</p>',
    pt: '<p>O utilizador foi eliminado corretamente.</p>',
    fr: '<p>L\'utilisateur a été supprimé correctement.</p>',
    eus: '<p>Erabiltzailea behar bezala ezabatu da.</p>',
    cat: '<p>L\'usuari s\'ha eliminat correctament.</p>',
  },

  profile_Data116: {
    es: '<p>Tus datos se han guardado correctamente.</p>',
    en: '<p>Your information has been saved correctly.</p>',
    pt: '<p>Os seus dados foram guardados corretamente.</p>',
    fr: '<p>Vos données ont été sauvegardées</p><p>correctement.</p>',
    eus: '<p>Zure datuak behar bezala gorde dira.</p>',
    cat: '<p>Les dades s\'ha desat correctament.</p>',
  },

  profile_Data117: {
    es: '<p>El departamento se ha dado de baja correctamente.</p>',
    en: '<p>The department has been deleted correctly.</p>',
    pt: '<p>O departamento registou a saída corretamente.</p>',
    fr: '<p>Le département a été supprimé correctement.</p>',
    eus: '<p>Departamentuari behar bezala eman zaio baja.</p>',
    cat: '<p>El departament s\'ha donat de baixa correctament.</p>',
  },

  profile_Data118: {
    es: '<p>Error</p>',
    en: '<p>Error</p>',
    pt: '<p>Erro</p>',
    fr: '<p>Erreur</p>',
    eus: '<p>Errorea</p>',
    cat: '<p>Error</p>',
  },

  profile_Data119: {
    es: '<p>Tus datos no se han podido guardar.</p>',
    en: '<p>Your information could not be saved.</p>',
    pt: '<p>Não foi possível guardar os seus dados.</p>',
    fr: '<p>Vos données n\'ont pas pu être sauvegardées.</p>',
    eus: '<p>Zure datuak ezin izan dira gorde.</p>',
    cat: '<p>Les dades no s\'han pogut desar.</p>',
  },

  profile_Data120: {
    es: '<p>El usuario no se ha podido eliminar correctamente.</p>',
    en: '<p>User could not be deleted correctly.</p>',
    pt: '<p>O utilizador conseguiu eliminar corretamente.</p>',
    fr: '<p>L\'utilisateur n\'a pas pu être supprimé correctement.</p>',
    eus: '<p>Erabiltzailea ezin izan da ezabatu.</p>',
    cat: '<p>L\'usuari no s\'ha pogut eliminar correctament.</p>',
  },

  profile_Data121: {
    es: '<p>El departamento no se ha podido dar de baja correctamente.</p>',
    en: '<p>The department could not be closed correctly.</p>',
    pt: '<p>O departamento não conseguiu registar a saída corretamente.</p>',
    fr: '<p>Le département n\'a pas pu être supprimé correctement.</p>',
    eus: '<p>Departamentuari ezin izan zaio baja eman.</p>',
    cat: '<p>El departament no s\'ha pogut donar de baixa correctament.</p>',
  },

  profile_Data122: {
    es: '<p>Departamento no se ha añadido correctamente.</p>',
    en: '<p>Department has not been added correctly.</p>',
    pt: '<p>O departamento não foi adicionado corretamente.</p>',
    fr: '<p>Le département n\'a pas été ajouté correctement.</p>',
    eus: '<p>Departamentua ezin izan da gehitu.</p>',
    cat: '<p>El departament no s\'ha afegit correctament.</p>',
  },

  profile_Data123: {
    es: '<p>Por favor, inténtalo de nuevo más tarde.</p>',
    en: '<p>Please try again later.</p>',
    pt: '<p>Tente novamente mais tarde.</p>',
    fr: '<p>Veuillez réessayer plus tard.</p>',
    eus: '<p>Mesedez, saiatu berriro geroago.</p>',
    cat: '<p>Torna a provar-ho més tard.</p>',
  },

  profile_Data124: {
    es: '<p>Se ha producido un error en la carga de tu archivo.</p><p>Por favor, comprueba que los campos coinciden con los especificados</p><p>en la plantilla y/o el número de usuarios incluidos en tu tarifa actual.</p><p>Si este error continúa, por favor, ponte en contacto con</p><p>nosotros en <span class="i18n-underline i18n-bold">customercare@eyescloud3d.com</span></p>',
    en: '<p>An error has occurred while uploading your file.</p><p>Please check that the fields match those specified</p><p>in the template and/or the number of users included in your current plan.</p><p>If this error continues, please contact</p><p>us at <span class="i18n-underline i18n-bold">customercare@eyescloud3d.com</span></p>',
    pt: '<p>Ocorreu um erro ao transferir o seu ficheiro.</p><p>Verifique se os campos coincidem com os especificados</p><p>no modelo e/ou o número de utilizadores incluídos na sua tarifa atual.</p><p>Se o erro continuar, entre em contacto</p><p>connosco através do e-mail <span class="i18n-underline i18n-bold">customercare@eyescloud3d.com</span></p>',
    fr: '<p>Une erreur est survenue lors du téléchargement de votre fichier.</p><p>Veuillez vérifier que les champs correspondent à ceux spécifiés</p><p>dans le modèle et/ou au nombre d\'utilisateurs inclus dans votre tarif actuel.</p><p>Si l\'erreur persiste,</p><p>veuillez nous contacter à l\'adresse suivante <span class="i18n-underline i18n-bold">customercare@eyescloud3d.com</span></p>',
    eus: '<p>Errorea gertatu da zure fitxategia kargatzean.</p><p>Mesedez, egiaztatu eremuak bat datozela txantiloian zehaztutakoekin</p><p>eta/edo zure egungo tarifan sartutako erabiltzaile kopuruarekin.</p><p>Erroreak jarraitzen badu, jar zaitez gurekin</p><p>harremanetan <span class="i18n-underline i18n-bold">customercare@eyescloud3d.com</span> helbidean</p>',
    cat: '<p>Hi ha hagut un error en carregar el teu fitxer.</p><p>Comprova que els camps coincideixen amb els que s\'indiquen</p><p>a la plantilla i/o amb el nombre d\'usuaris inclosos a la tarifa actual.</p><p>Si l\'error persisteix, posa\'t en contacte amb</p><p>nosaltres a <span class="i18n-underline i18n-bold">customercare@eyescloud3d.com</span></p>',
  },

  profile_Data125: {
    es: '<p>Reintentar</p>',
    en: '<p>Try again</p>',
    pt: '<p>Tentar novamente</p>',
    fr: '<p>Réessayer</p>',
    eus: '<p>Berriro saiatu</p>',
    cat: '<p>Reintenta</p>',
  },

  profile_Data126: {
    es: '<p>No puedes añadir más usuarios.</p>',
    en: '<p>You cannot add more users.</p>',
    pt: '<p>Não pode adicionar mais utilizadores.</p>',
    fr: '<p>Vous ne pouvez pas ajouter plus d\'utilisateurs.</p>',
    eus: '<p>Ezin duzu erabiltzaile gehiago gehitu.</p>',
    cat: '<p>No pots afegir més usuaris.</p>',
  },

  profile_Data127: {
    es: '<p>Has alcanzado el límite de usuarios incluidos en tu tarifa actual. Si deseas ampliar este límite, haz clic en el siguiente botón para adquirir usuarios extra.</p>',
    en: '<p>You have reached the maximum number of users included in your current plan. If you wish to increase the number of users, click on the following button.</p>',
    pt: '<p>Atingiu o limite de utilizadores incluídos na sua tarifa atual. Se pretende ampliar este limite, clique no seguinte botão para adquirir utilizadores extra.</p>',
    fr: '<p>Vous avez atteint la limite d\'utilisateurs inclus dans votre forfait actuel.Si vous souhaitez augmenter cette limite, cliquez sur le bouton ci-dessous pour ajouter des utilisateurs supplémentaires.</p>',
    eus: '<p>Zure egungo tarifak onartzen dituen erabiltzaileen mugara iritsi zara. Muga hori handitu nahi baduzu, egin klik honako botoian erabiltzaile gehiago eskuratzeko.</p>',
    cat: '<p>Has assolit el límit d\'usuaris inclosos en la tarifa actual.Si vols ampliar aquest límit, fes clic en el botó següent per afegir-hi usuaris addicionals.</p>',
  },

  profile_Data128: {
    es: '<p>AÑADIR USUARIOS</p>',
    en: '<p>ADD USERS</p>',
    pt: '<p>ADICIONAR UTILIZADORES</p>',
    fr: '<p>AJOUTER DES UTILISATEURS</p>',
    eus: '<p>GEHITU ERABILTZAILEAK</p>',
    cat: '<p>AFEGEIX USUARIS</p>',
  },

  profile_Data129: {
    es: '<p>Se ha añadido un segundo administrador con éxito.</p>',
    en: '<p>A second administrator has been added successfully.</p>',
    pt: '<p>Adicionou um segundo administrador com êxito.</p>',
    fr: '<p>Un deuxième administrateur a été ajouté avec succès.</p>',
    eus: '<p>Bigarren administratzailea behar bezala gehitu da.</p>',
    cat: '<p>S\'ha afegit un segon administrador correctament.</p>',
  },

  profile_Data130: {
    es: '<p>Para asegurar el correcto funcionamiento de tu cuenta Business debes nombrar un segundo administrador. Selecciona un nuevo administrador para continuar.</p>',
    en: '<p>To ensure that your Business account works properly, you must add a second administrator. Select a new administrator to continue.</p>',
    pt: '<p>Para assegurar o correto funcionamento da sua conta Business, deve nomear um segundo administrador. Selecione um novo administrador para continuar.</p>',
    fr: '<p>Pour assurer le bon fonctionnement de votre compte Business, vous devez nommer un deuxième administrateur. Sélectionnez un nouvel administrateur pour continuer.</p>',
    eus: '<p>Zure Business kontuak behar bezala funtzionatzen duela ziurtatzeko, bigarren administratzaile bat izendatu behar duzu. Jarraitzeko, hautatu beste administratzaile bat.</p>',
    cat: '<p>Per garantir un funcionament correcte del compte Business, has de designar un segon administrador. Selecciona un nou administrador per continuar.</p>',
  },

  profile_Data131: {
    es: '<p>NO, CANCELAR</p>',
    en: '<p>NO, CANCEL</p>',
    pt: '<p>NÃO, CANCELAR</p>',
    fr: '<p>NON, ANNULER</p>',
    eus: '<p>EZ, UTZI</p>',
    cat: '<p>NO, CANCEL·LA</p>',
  },

  profile_Data132: {
    es: '<p>SÍ, TRANSFERIR</p>',
    en: '<p>YES, TRANSFER</p>',
    pt: '<p>SIM, TRANSFERIR</p>',
    fr: '<p>OUI, TRANSFÉRER</p>',
    eus: '<p>BAI, TRANSFERITU</p>',
    cat: '<p>SÍ, TRANSFEREIX</p>',
  },

  profile_Data133: {
    es: '<p>SÍ, AÑADIR</p>',
    en: '<p>YES, ADD</p>',
    pt: '<p>SIM, ADICIONAR</p>',
    fr: '<p>OUI, AJOUTER</p>',
    eus: '<p>BAI, GEHITU</p>',
    cat: '<p>SÍ, AFEGEIX</p>',
  },

  profile_Data134: {
    es: '<p>SÍ, ELIMINAR</p>',
    en: '<p>YES, DELETE</p>',
    pt: '<p>SIM, ELIMINAR</p>',
    fr: '<p>OUI, SUPPRIMER</p>',
    eus: '<p>BAI, EZABATU</p>',
    cat: '<p>SÍ, ELIMINA</p>',
  },

  profile_Data135: {
    es: '<p>SÍ, CERRAR</p>',
    en: '<p>YES, CLOSE</p>',
    pt: '<p>SIM, FECHAR</p>',
    fr: '<p>OUI, FERMER</p>',
    eus: '<p>BAI, ITXI</p>',
    cat: '<p>SÍ, TANCA</p>',
  },

  profile_Data136: {
    es: '<p>NO, CONTINUAR</p>',
    en: '<p>NO, CONTINUE</p>',
    pt: '<p>NÃO, CONTINUAR</p>',
    fr: '<p>NON, CONTINUER</p>',
    eus: '<p>EZ, JARRAITU</p>',
    cat: '<p>NO, CONTINUA</p>',
  },

  profile_Data137: {
    es: '<p>Si cierras este proceso no se añadirán el/los usuario/s que has seleccionado al departamento.</p>',
    en: '<p>If you exit this process, the user(s) you have selected will not be added to the department.</p>',
    pt: '<p>Se fechar este processo, o/os utilizador/es que selecionou não serão adicionados ao departamento.</p>',
    fr: '<p>Si vous annulez ce processus, le(s) utilisateur(s) sélectionné(s) ne sera(ont) pas ajouté(s) au département.</p>',
    eus: '<p>Prozesu hori ixten baduzu, ez d(ir)a gehituko hautatu d(it)uzun erabiltzailea(k) departamentura.</p>',
    cat: '<p>Si tanques aquest procés, no s\'afegiran els usuaris que has seleccionat al departament.</p>',
  },

  profile_Data138: {
    es: '<p>Selecciona el departamento al que quieres transferir el/los usuarios</p>',
    en: '<p>Select the department to which you want to transfer the user(s)</p>',
    pt: '<p>Selecione o departamento para o qual pretende transferir o/os utilizadores</p>',
    fr: '<p>Sélectionnez le département vers lequel vous souhaitez transférer le(s) utilisateur(s).</p>',
    eus: '<p>Hautatu zein departamentura transferitu nahi d(it)uzun erabiltzailea(k)</p>',
    cat: '<p>Selecciona el departament al qual vols transferir els usuaris</p>',
  },

  profile_Data139: {
    es: '<p>CREAR USUARIO</p>',
    en: '<p>CREATE USER</p>',
    pt: '<p>CRIAR UTILIZADOR</p>',
    fr: '<p>CRÉER UTILISATEUR</p>',
    eus: '<p>SORTU ERABILTZAILEA</p>',
    cat: '<p>CREA UN USUARI</p>',
  },

  profile_Data140: {
    es: '<p>Aviso</p>',
    en: '<p>Notice</p>',
    pt: '<p>Aviso</p>',
    fr: '<p>Avertissement</p>',
    eus: '<p>Oharra</p>',
    cat: '<p>Avís</p>',
  },

  profile_Data141: {
    es: '<p>* No has seleccionado ningún usuario.</p>',
    en: '<p>* You have not selected a user.</p>',
    pt: '<p>* Não selecionou nenhum utilizador.</p>',
    fr: '<p>* Vous n\'avez pas sélectionné d\'utilisateur.</p>',
    eus: '<p>* Ez duzu erabiltzailerik hautatu.</p>',
    cat: '<p>* No has seleccionat cap usuari.</p>',
  },

  profile_Data142: {
    es: '<p>Selecciona el/los usuario/s que deseas añadir al departamento</p>',
    en: '<p>Select the user(s) you want to add to the department</p>',
    pt: '<p>Selecione o(s) utilizador(es) que pretende adicionar ao departamento</p>',
    fr: '<p>Sélectionnez le(s) utilisateur(s) que vous souhaitez ajouter au département.</p>',
    eus: '<p>Aukeratu zein erabiltzaile gehitu nahi d(it)uzun departamentura</p>',
    cat: '<p>Selecciona els usuaris que vols afegir al departament</p>',
  },

  profile_Data143: {
    es: '<p>¿Estás seguro de que deseas añadir el/los usuario/s al departamento</p>',
    en: '<p>Are you sure you want to add the user(s) to the department?</p>',
    pt: '<p>Tem a certeza de que pretende adicionar o(s) utilizador(es) ao departamento?</p>',
    fr: '<p>Êtes-vous sûr de vouloir ajouter le(s) utilisateur(s) au département ?</p>',
    eus: '<p>Ziur zaude erabiltzailea(k) departamentura gehitu nahi d(it)uzula?</p>',
    cat: '<p>Segur que vols afegir els usuaris al departament?</p>',
  },

  frequentlyQuestions_data1: {
    es: '<p>¿Qué dispositivos de captura puedo usar para generar mi modelo 3D?</p>',
    en: '<p>Which devices can I use to capture photos and videos to generate my 3D model?</p>',
    pt: '<p>Que tipo de dispositivos de captação posso usar para gerar o meu modelo 3D?</p>',
    fr: '<p>Quel périphérique de capture puis-je utiliser pour générer mon modèle 3D ?/p>',
    eus: '<p>Nire 3D eredua sortzean, zer gailu erabili dezaket irudiak kapturatzeko?</p>',
    cat: '<p>Quins dispositius de captura puc emprar per generar el meu model 3D?</p>',
  },

  frequentlyQuestions_data2: {
    es: '<p>Puedes usar cualquier dispositivo con cámara , desde una cámara reflex hasta la cámara de tu teléfono móvil.</p>',
    en: '<p>You can use any device with camera, from a reflex camera to your smartphone camera.</p>',
    pt: '<p>Pode usar qualquer dispositivo com câmara, desde uma câmara reflex até à câmara do seu telemóvel.</p>',
    fr: '<p>Tous les dispositifs possédant un appareil photo peuvent être utilisés, d’un appareil reflex à un téléphone portable.</p>',
    eus: '<p>Kamera bat daukan edozein gailu erabili dezakezu, esaterako, reflex kamera bat edo zure telefonoaren kamera.</p>',
    cat: '<p>Pots emprar qualsevol dispositiu amb càmera, des d\'una càmera rèflex fins a la càmera del telèfon mòbil.</p>',
  },

  frequentlyQuestions_data3: {
    es: '<p>¿Puedo usar una Lente de Ojo de Pez?</p>',
    en: '<p>Can I use a fisheye lens?</p>',
    pt: '<p>Posso usar uma Lente de Olho de Peixe?</p>',
    fr: '<p>Puis-je utiliser un objectif Fisheye ?</p>',
    eus: '<p>Arrain-begi lentea erabili dezaket?</p>',
    cat: '<p>Puc utilitzar un objectiu d\'ull de peix?</p>',
  },

  frequentlyQuestions_data4: {
    es: '<p>Sí, la plataforma admite imágenes capturadas con Lente Ojo de Pez. Cuando los vídeos hayan sido captados con una lente de este tipo, debes activar el botón "Lente Ojo de Pez" cuando estés subiendo los vídeos. (Lente Ojo de Pez solo admitida en vídeos).</p>',
    en: '<p>Yes, the platform supports images captured with a fisheye lens. If videos have been shot with a fisheye lens, you must enable the "Fisheye Lens" button when you upload them. (Fisheye lens only supported in videos).</p>',
    pt: '<p>Sim, a plataforma suporta imagens captadas com uma Lente de Olho de Peixe. Quando os vídeos tiverem sido captados com uma "Lente de Olho de Peixe", deve ativar o botão "Lente de Olho de Peixe" ao carregar os vídeos. (A Lente de Olho de Peixe só é suportada em vídeos).</p>',
    fr: '<p>Oui, la plateforme admet les images capturées avec des objectifs Fisheye. Quand les vidéos ont été captées avec un objectif Fisheye, vous devez activer le bouton « Fisheye » lorsque vous téléchargez les vidéos. (Objectif Fisheye admis uniquement en vidéo).</p>',
    eus: '<p>Bai, plataformak arrain-begi lentearekin kapturatutako irudiak onartzen ditu. Mota honetako lentearekin grabatutako bideoetan, “arrain-begi lentea” botoia sakatu behar duzu bideoak kargatzerakoan. (Arrain-begi lentea soilik bideoetan onartzen da).</p>',
    cat: '<p>Sí, la plataforma admet imatges capturades amb un objectiu d\'ull de peix.Quan els vídeos s\'hagin captat amb un objectiu d\'aquest tipus, has d\'activar el botó “Objectiu ull de peix” quan estiguis carregant-los. (L\'objectiu ull de peix només s\'admet en vídeos).</p>',
  },

  frequentlyQuestions_data5: {
    es: '<p>¿Cómo debo tomar las fotos?</p>',
    en: '<p>How should I take the photos?</p>',
    pt: '<p>Como devo tirar as fotografias?</p>',
    fr: '<p>Comment bien prendre les photos ?</p>',
    eus: '<p>Nola atera behar ditut argazkiak?</p>',
    cat: '<p>Com he de fer les fotos?</p>',
  },

  frequentlyQuestions_data6: {
    es: '<p>1. Tú objeto no debe estar en movimiento y la imagen debe estar nítida, con el objeto centrado y con una superposición igual o mayor al 60% entre las fotos (debes mostrar la misma parte del objeto al menos en 3 fotos). </p><p><br>2. Debe haber un cambio entre las capturas, hacer 2 fotos del mismo punto puede causar problemas al generar el modelo 3D. Para ver más información sobre cómo capturar fotos, visita TIPS de captura.</p>',
    en: '<p>1. Your object must not be moving and the image must be sharp. The object must be centered and there must be an overlap equal to or greater than 60% between photos (you must show the same part of the object in at least 3 photos).                                                                  2. Each photo must be different. If you take 2 photos from the same point, it can cause problems when generating the 3D model. For more information on how to capture photos, visit the Capturing TIPS.</p>',
    pt: '<p>1. O seu objeto não deve estar em movimento e a imagem deve ser nítida, com o objeto centrado e com uma sobreposição igual ou superior a 60% entre fotografias (deve mostrar a mesma parte do objeto em pelo menos 3 fotografias).                                       2. Deve haver uma mudança entre as captações, tirar 2 fotografias do mesmo ponto pode causar problemas ao gerar o modelo 3D. Para mais informações sobre como captar fotografias, visite Dicas de captação.</p>',
    fr: '<p>1. L\'objet doit être immobile et l\'image nette et centrée sur l\'objet.Les photos doivent se chevaucher de 60 % au moins(une même partie de l\'objet doit être présente sur au moins 3 photos).                                                    2. Deux captures ne peuvent jamais être identiques. Prendre deux photos du même point peut entraîner des problèmes lors de la génération du modèle 3D. Pour plus d\'informations sur la manière de capturer des photos, consultez nos Conseils de capture.</p>',
    eus: '<p>1. Zure objektuak geldirik egon behar du, eta irudia garbia izan behar da. Objektuak zentratuta egon behar du eta argazkien arteko gainjartzea %60 edo gehiago izan behar da (objektuaren eremu berdina gutxienez 3 argazkitan atera behar duzu).                                                           2. Argazkien artean aldaketa egon behar da; puntu berdinaren 2 argazki egiteak arazoak eman baititzake 3D eredua sortzean. Argazkiak nola kapturatzeari buruzko informazio gehiago eskuratzeko, joan “Kapturatzeko aholkuak” atalera.</p>',
    cat: '<p>1. L’objecte no ha d\'estar en moviment i la imatge ha de ser nítida, amb l\'objecte centrat i amb una superposició igual o superior al 60 % entre les fotos(has de mostrar la mateixa part de l\'objecte com a mínim en 3 fotos).                                                           2. Ha d\'haver-hi un canvi entre les captures, fer 2 fotos del mateix punt pot ocasionar problemes en generar el model 3D. Per consultar més informació sobre com capturar fotos, visita els CONSELLS de captura.</p>',
  },

  frequentlyQuestions_data7: {
    es: '<p>¿Cómo genero un modelo 3D?</p>',
    en: '<p>How do I generate a 3D model?</p>',
    pt: '<p>Como crio um modelo 3D?</p>',
    fr: '<p>Comment générer un modèle 3D ?</p>',
    eus: '<p>Nola sor dezaket 3D eredua?</p>',
    cat: '<p>Com genero un model 3D?</p>',
  },

  frequentlyQuestions_data8: {
    es: '<p>Para generar un modelo 3D sigue estos pasos:</p><p><br>1. Captura las fotos y/o vídeos. </p><p>2. Carga tus fotos y/o vídeos en nuestra plataforma.</p><p>3. Procede a la generación automática y ya podrás visualizar tu modelo 3D en el visor.</p>',
    en: '<p>Follow these steps to generate a 3D model: 1. Capture the photos and/ or videos. 2. Upload your photos and/ or videos to our platform. 3. Automatically generate your 3D model and you will be able to visualize it in the viewer.</p>',
    pt: '<p>Para criar um modelo 3D, siga estes passos: 1. Capte as fotografias e/ou vídeos. 2. Carregue as suas fotografias e/ou vídeos na nossa plataforma. 3. Proceda à criação automática e já poderá visualizar o seu modelo 3D no visor.</p>',
    fr: '<p>Pour générer un modèle 3D, suivez ces étapes : 1. Capturez les photos et/ou vidéos. 2. Téléchargez vos photos et/ou vidéos sur notre plateforme. 3. Lancez la génération automatique et vous pourrez visualiser votre modèle 3D immédiatement dans la visionneuse.</p>',
    eus: '<p>3D eredu bat sortzeko, jarraitu honako urratsak: 1. Kapturatu argazkiak eta/edo bideoak. 2. Kargatu zure argazkiak eta/edo bideoak gure plataforman. 3. Joan sortze automatikora eta zure 3D eredua ikustailean bistaratu ahal izango duzu.</p>',
    cat: '<p>Per generar un model 3D, segueix aquests passos: 1. Captura les fotos o els vídeos. 2. Carrega les fotos o els vídeos a la nostra plataforma. 3. Procedeix a la generació automàtica i ja podràs visualitzar el teu model 3D en el visor.</p>',
  },

  frequentlyQuestions_data9: {
    es: '<p>¿Puedo añadir fotos si subo vídeos?</p>',
    en: '<p>Can I add photos if I upload videos?</p>',
    pt: '<p>Posso adicionar fotografias se carregar vídeos?</p>',
    fr: '<p>Puis-je ajouter des photos si je télécharge des vidéos ?</p>',
    eus: '<p>Argazkiak gehitu ditzaket bideoak kargatu ondoren?</p>',
    cat: '<p>Puc afegir fotos si carrego vídeos?</p>',
  },

  frequentlyQuestions_data10: {
    es: '<p>Sí, si subes un vídeo podrás añadir hasta 15 fotos de detalles para generar el modelo 3D.</p>',
    en: '<p>Yes, if you upload a video you will be able to add up to 15 detailed photos to generate the 3D model.</p>',
    pt: '<p>Sim, se carregar um vídeo pode adicionar até 15 fotografias de detalhes para criar o modelo 3D.</p>',
    fr: '<p>Oui, si vous téléchargez une vidéo, vous pouvez l’accompagner de jusqu’à 15 photos de détail pour générer le modèle 3D.</p>',
    eus: '<p>Bai, bideo bat kargatzen baduzu, 15 xehetasun argazki arte gehitu ditzakezu 3D eredua sortzeko.</p>',
    cat: '<p>Sí, si carregues un vídeo podràs afegir fins a 15 fotos de detalls per generar el model 3D.</p>',
  },

  frequentlyQuestions_data11: {
    es: '<p>¿Y puedo añadir vídeos si subo fotos?</p>',
    en: '<p>And can I add video if I upload photos?</p>',
    pt: '<p>Posso adicionar vídeos se carregar fotografias?</p>',
    fr: '<p>Et puis-je ajouter des vidéos si je télécharge des photos ?</p>',
    eus: '<p>Eta bideoak gehitu ditzaket argazkiak kargatu ondoren?</p>',
    cat: '<p>I puc afegir vídeos si carrego fotos?</p>',
  },

  frequentlyQuestions_data12: {
    es: '<p>En este caso una vez que añades fotos solo podrás usar fotos para generar tu modelo 3D.</p>',
    en: '<p>In this case, once you add photos you will only be able to use photos to generate the 3D model.</p>',
    pt: '<p>Neste caso, uma vez adicionadas as fotografias, só se pode usar fotografias para criar o seu modelo 3D.</p>',
    fr: '<p>Dans ce cas, si vous téléchargez des photos, vous ne pouvez utiliser que des photos pour générer votre modèle 3D.</p>',
    eus: '<p>Kasu honetan, behin argazkiak kargatuta, soilik irudiak erabili ditzakezu 3D eredua sortzeko.</p>',
    cat: '<p>En aquest cas, un cop que afegeixes fotos, només podràs fer servir fotos per generar el teu model 3D.</p>',
  },

  frequentlyQuestions_data13: {
    es: '<p>¿Cuánto tiempo tarda en generar mi modelo 3D?</p>',
    en: '<p>How long does it takes to generate my 3D model?</p>',
    pt: '<p>Quanto tempo demora a criação do meu modelo 3D?</p>',
    fr: '<p>Combien de temps dure la génération de mon modèle 3D ?</p>',
    eus: '<p>Zenbat denbora behar da 3D eredua sortzeko?</p>',
    cat: '<p>Quant de temps tarda a generar-se el meu model 3D?</p>',
  },

  frequentlyQuestions_data14: {
    es: '<p>El tiempo de la generación del modelo 3D, varía dependiendo del modelo 3D (número de imágenes o duración de vídeos capturados, resolución, textura…), pero suele ser de entre 5 minutos a 20 minutos.</p>',
    en: '<p>The 3D model generation time varies depending on the 3D model (number of photos or duration of captured videos, resolution, texture, etc.) but it is usually between 5 minutes to 20 minutes.</p>',
    pt: '<p>O tempo de criação do modelo 3D varia em função do modelo 3D (número de fotografias ou duração dos vídeos captados, resolução, textura...), mas normalmente é entre 5 minutos a 20 minutos.</p>',
    fr: '<p>Le temps de génération du modèle 3D dépend du modèle 3D (nombre d’images ou durée des vidéos, résolution, texture...), mais il est généralement compris entre 5 et 20 minutes.</p>',
    eus: '<p>3D eredua sortzeko behar den denbora desberdina izango da 3D eredu bakoitzaren arabera (ateratako argazki kopurua edo bideoen iraupena, bereizmena, testura, etab.), baina honen iraupena 5 eta 20 minutu artekoa izan ohi da.</p>',
    cat: '<p>El temps de generació del model 3D és diferent en funció del model 3D (nombre d\'imatges o durada de vídeos capturats, resolució, textura…), però sol tardar entre 5 i 20 minuts.</p>',
  },

  frequentlyQuestions_data15: {
    es: '<p>¿Qué tipo de resultados se obtienen?</p>',
    en: '<p>What types of results are obtained?</p>',
    pt: '<p>Que tipo de resultados são obtidos?</p>',
    fr: '<p>Quel type de résultats sont obtenus ?</p>',
    eus: '<p>Zer emaitza mota eskuratzen dira?</p>',
    cat: '<p>Quin tipus de resultats s\'obtenen?</p>',
  },

  frequentlyQuestions_data16: {
    es: '<p>Se obtienen 2 tipos de resultados:</p><p>1. La nube de puntos del modelo 3D.</p><p>2. La malla texturizada del modelo 3D.</p>',
    en: '<p>Two types of results are obtained:</p><p>1. The point cloud of the 3D model.</p><p>2. The textured mesh of the 3D model.</p>',
    pt: '<p>São obtidos 2 tipos de resultados:</p><p>1. A nuvem de pontos do modelo 3D.</p><p>2. A malha texturizada do modelo 3D.</p>',
    fr: '<p>2 types de résultats sont obtenus :</p><p>1. Le nuage de points du modèle 3D.</p><p>2. La maille texturisée du modèle 3D.</p>',
    eus: '<p>Bi emaitza mota eskuratzen dira:</p><p>1. 3D ereduaren puntu hodeia.</p><p>2. 3D ereduaren sare testuraduna.</p>',
    cat: '<p>S\'obtenen 2 tipus de resultats:</p><p>1. El núvol de punts del model 3D.</p><p>2. La malla texturitzada del model 3D.</p>',
  },

  frequentlyQuestions_data17: {
    es: '<p>¿Qué puedo hacer si tengo algún problema con mi modelo 3D?</p>',
    en: '<p>What can I do if I have any problem with my 3D model?</p>',
    pt: '<p>O que posso fazer se tiver um problema com o meu modelo 3D?</p>',
    fr: '<p>Que faire si je découvre un problème dans mon modèle 3D ?</p>',
    eus: '<p>Zer egin dezaket 3D ereduarekin arazorik badut?</p>',
    cat: '<p>Què puc fer si tinc algun problema amb el meu model 3D?</p>',
  },

  frequentlyQuestions_data18: {
    es: '<p>Si encuentras problemas en tu modelo 3D, podrás notificarlos a través del apartado "Incidencias". Envíanos tus incidencias, estudiaremos los problemas y te responderemos lo antes posible. También puedes contactar con nosotros a través del "Chat", donde nuestro equipo de soporte técnico podrá resolver tus dudas.</p>',
    en: '<p>If you find any problems in your 3D model, you can report them in the "Incidents" section. Send us your incidents and we will study the problems and get back to you as soon as possible. You can also contact us through the "Chat", where our technical support team will be able to answer your questions.</p>',
    pt: '<p>Se encontrar problemas com o seu modelo 3D, pode notificá-los através da secção "Incidências". Envie-nos as suas incidências, analisaremos os problemas e responderemos o mais rapidamente possível. Também nos pode contactar através do "Chat", onde a nossa equipa de apoio técnico poderá responder às suas dúvidas.</p>',
    fr: '<p>Si vous découvrez un problème dans votre modèle 3D, vous pouvez nous le signaler dans la section « Incidents ». Envoyez-nous vos incidents, nous les étudierons et nous vous répondrons dans les meilleurs délais. Vous pouvez aussi nous contacter via le chat, où notre équipe technique pourra résoudre vos doutes.</p>',
    eus: '<p>Zure 3D ereduarekin arazorik baduzu, “Arazoak” atalean honen berri eman dezakezu. Zure arazoak bidali, geuk ikertuko ditugu, eta ahalik eta lasterren emango dizugu erantzun bat. Gurekin ere harremanetan jar zaitezke “Txata” erabiliz; bertan gure laguntza teknikorako lantaldeak zure zalantzak argituko dituzte.</p>',
    cat: '<p>Si trobes cap problema al teu model 3D, podràs notificar-ho a través de l\'apartat “Incidències”.Envia\'ns les teves incidències, estudiarem els problemes i et respondrem al més aviat possible. També pots posar-te en contacte amb nosaltres a través del "Xat", on el nostre equip de suport tècnic podrà resoldre els teus dubtes.</p>',
  },

  frequentlyQuestions_data19: {
    es: '<p>¿Se pueden compartir los modelos 3D?</p>',
    en: '<p>Can the 3D models be shared?</p>',
    pt: '<p>Os modelos 3D podem ser partilhados?</p>',
    fr: '<p>Est-il possible de partager les modèles 3D ?</p>',
    eus: '<p>3D ereduak partekatu daitezke?</p>',
    cat: '<p>Es poden compartir els models 3D?</p>',
  },

  frequentlyQuestions_data20: {
    es: '<p>Puedes compartir tus modelos 3D con su URL ya sea como un enlace único, a través de diferentes redes sociales (Facebook, Whatsapp, LinkedIn, Twitter y Pinterest) o insertando el i-frame en tu web. Puedes activar la opción "Visible para usuarios" en Tu Modelo 3D y todos los usuarios de la plataforma podrán ver tu modelo 3D.</p>',
    en: '<p>You can share your 3D models with their URL either as a unique link, through different social medias (Facebook, Whatsapp, LinkedIn, Twitter and Pinterest) or by embedding the iframe in your website. You can activate the "Visible to users" option in Your 3D Model and all users of the platform will be able to see your 3D model.</p>',
    pt: '<p>Pode partilhar os seus modelos 3D com o seu URL quer como um link único, através de diferentes redes sociais (Facebook, Whatsapp, LinkedIn, Twitter e Pinterest) ou incorporando o i-frame no seu website. Pode ativar a opção "Visível para utilizadores" no O Seu Modelo 3D e todos os utilizadores da plataforma poderão ver o seu modelo 3D.</p>',
    fr: '<p>Vous pouvez partagez vos modèles 3D avec leur URL, que ce soit en lien unique sur les réseaux sociaux (Facebook, Whatsapp, LinkedIn, Twitter et Pinterest) ou en insérant l’iframe sur votre site Internet. Vous pouvez activer l’option « Visible pour les utilisateurs » dans votre modèle 3D et tous les utilisateurs de la plateforme pourront voir votre modèle 3D.</p>',
    eus: '<p>Zure 3D ereduak beren URLa erabiliz parteka ditzakezu, bai esteka bakar gisa zure sare sozialetan argitaratuz (Facebook, Whatsapp, LinkedIn, Twitter eta Pinterest), bai i-framea zure webgunean txertatuz. “Erabiltzaileentzat ikusgai” aukera aktiba dezakezu zure 3D ereduan, eta horrela plataformaren erabiltzaile guztiek zure 3D eredua ikusteko aukera izango dute.</p>',
    cat: '<p>Pots compartir els teus models 3D mitjançant l\'URL, ja sigui com un enllaç únic a través de diferents xarxes socials(Facebook, Whatsapp, LinkedIn, Twitter i Pinterest) o inserint l\'i-frame en el teu web. Pots activar l\'opció “Visible per a usuaris” en El teu model 3D i tots els usuaris de la plataforma podran veure el teu model 3D.</p>',
  },

  frequentlyQuestions_data21: {
    es: '<p>¿En una cuenta Empresa, qué significa establecer mi modelo 3D como "Visible para departamento"?</p>',
    en: '<p>In an company account, what does it mean to establish my 3D model as "Visible to department"?</p>',
    pt: '<p>Numa conta Empresa, o que significa estabelecer o meu modelo 3D como "Visível para o departamento"?</p>',
    fr: '<p>Dans un compte Entreprise, que signifie l’option permettant de rendre le modèle 3D « Visible pour le département » ?</p>',
    eus: '<p>Enpresa kontu batean, zer esan nahi du nire 3D eredua “Departamentuarentzat ikusgai” egoteak?</p>',
    cat: '<p>Què significa establir el meu model 3D com a “Visible per a departament” en un compte d’Empresa?</p>',
  },

  frequentlyQuestions_data22: {
    es: '<p>Si estás utilizando una cuenta proporcionada por tu empresa, marcar el modelo 3D como visible hará que tanto el jefe del departamento, con el que está asociada la cuenta, como el administrador de la compañía tengan acceso a tu modelo 3D. Si tu cuenta es de usuario particular, te aparecerá en su lugar la opción "Visible para usuarios", con la que los demás usuarios de la plataforma podrán acceder a tu modelo 3D.</p>',
    en: '<p>If you are using an account provided by your company, marking the 3D model as visible will give both the head of the department associated with the account and the company administrator access to your 3D model. If your account is a private user account, the option "Visible to users" will appear instead, allowing other users of the platform to access your 3D model.</p>',
    pt: '<p>Se estiver a utilizar uma conta fornecida pela sua empresa, a marcação do modelo 3D como visível dará tanto ao chefe do departamento ao qual a conta está associada como ao administrador da empresa acesso ao seu modelo 3D. Se a sua conta for uma conta de utilizador privado, verá a opção "Visível para utilizadores", que permitirá a outros utilizadores da plataforma aceder ao seu modelo 3D.</p>',
    fr: '<p>Si vous utilisez un compte entreprise, rendre le modèle 3D visible permettra au chef de département, à qui le compte est associé, et à l’administrateur de l’entreprise d’avoir accès à votre modèle 3D. Si votre compte est personnel, vous trouverez l’option « Visible pour les utilisateurs », avec laquelle vous donnerez accès à votre modèle 3D aux autres utilisateurs de la plateforme.</p>',
    eus: '<p>Zure enpresak hornitutako kontua erabiltzen baduzu eta 3D eredua ikusgai gisa markatzen baduzu, bai zure departamentu buruak (kontua honi lotuta dagoelako) eta bai enpresaren administratzaileak zure 3D ereduan sartzeko aukera izango dute. Zure kontua erabiltzaile partikularrentzat bada, horren ordez, “Erabiltzaileentzat ikusgai” aukera ikusiko duzu. Horrekin, plataformaren beste erabiltzaileek zure 3D ereduan sartzeko aukera izango dute.</p>',
    cat: '<p>Si estàs utilitzant un compte proporcionat per la teva empresa, marcar el model 3D com a visible farà que tant el cap del departament, amb el qual està associat el compte, com l\'administrador de l\'empresa tinguin accés al teu model 3D. Si el teu compte és d\'usuari particular, en comptes d\'això t\'apareixerà l\'opció “Visible per a usuaris”, amb la qual els altres usuaris de la plataforma podran accedir al teu model 3D.</p>',
  },

  frequentlyQuestions_data23: {
    es: '<p>¿Cuántas veces puedo descargarme un modelo 3D?</p>',
    en: '<p>How many times can I download a 3D model?</p>',
    pt: '<p>Quantas vezes posso transferir um modelo 3D?</p>',
    fr: '<p>Combien de fois puis-je télécharger un modèle 3D ?</p>',
    eus: '<p>Zenbat aldiz deskargatu dezaket 3D eredu bakoitza?</p>',
    cat: '<p>Quantes vegades puc baixar un model 3D?</p>',
  },

  frequentlyQuestions_data24: {
    es: '<p>Una vez comprada la descarga, podrás descargar tu modelo 3D de manera ilimitada y en todos los formatos disponibles.</p>',
    en: '<p>Once you have purchased the download, you will be able to download your 3D model as many times as you want in all available formats.</p>',
    pt: '<p>Depois de ter adquirido a transferência, poderá transferir o seu modelo 3D por tempo ilimitado em todos os formatos disponíveis.</p>',
    fr: '<p>Une fois l’achat effectué, vous pouvez télécharger votre modèle 3D de manière illimitée et dans tous les formats disponibles.</p>',
    eus: '<p>Behin deskarga erosita, zure 3D eredua mugarik gabe eta eskuragarri dauden formatu guztietan deskargatu dezakezu.</p>',
    cat: '<p>Una vegada comprada la baixada, podràs baixar el teu model 3D de manera il·limitada i en tots els formats disponibles.</p>',
  },

  frequentlyQuestions_data25: {
    es: '<p>¿Cómo puedo escalar mi modelo 3D?</p>',
    en: '<p>How can I scale my 3D model?</p>',
    pt: '<p>Como é que posso dimensionar o meu modelo 3D?</p>',
    fr: '<p>Comment puis-je mettre mon modèle 3D à l’échelle ?</p>',
    eus: '<p>Nola eskalatu dezaket nire 3D eredua?</p>',
    cat: '<p>Com puc ajustar l\'escala del meu model 3D??</p>',
  },

  frequentlyQuestions_data26: {
    es: '<p>Para realizar la escala directamente en el proceso de generación del modelo 3D, es necesario utilizar un Target (descargar el Target aquí). Debes imprimirlo en dimensiones reales y colocarlo al lado del objeto que se va a capturar, para que aparezca en las fotos. El modelo 3D resultante se escala automáticamente. Otra opción es generar el modelo 3D y luego escalarlo con la herramienta "Escala" a través de introducir una medida entre 2 puntos que conozcoas en el modelo 3D.</p>',
    en: '<p>To scale the 3D model directly during the generation process, you must use a Target (download the Target here). You must print it with the real dimensions and place it next to the object you capture so that it appears in the photos. The resulting 3D model is automatically scaled. Another option is to generate the 3D model and then scale it with the "Scale" tool by entering a known distance between 2 points in the 3D model.</p>',
    pt: '<p>Para dimensionar diretamente no processo de geração do modelo 3D, é necessário utilizar um Target (transferir o Target aqui). Deve imprimi-lo em dimensões reais e colocá-lo ao lado do objeto a captar, para que apareça nas fotografias. O modelo 3D resultante é automaticamente dimensionado. Outra opção é gerar o modelo 3D e depois dimensioná-lo com a ferramenta "Escala", introduzindo uma medida entre 2 pontos que conhece no modelo 3D.</p>',
    fr: '<p>Pour effectuer une mise à l\'échelle directement lors de la génération du modèle 3D, il est indispensable d\'utiliser une Target (téléchargez la Target ici). Vous devez l\'imprimer avec ses dimensions réelles et la placer à côté de l\'objet que vous allez capturer, pour qu\'elle apparaisse sur les photos.Le modèle 3D généré sera automatiquement mis à l\'échelle. Une autre option est de générer le modèle 3D et ensuite, d\'utiliser l\'outil « Échelle » pour introduire une mesure connue entre 2 points du modèle 3D.</p>',
    eus: '<p>3D eredua sortzeko prozesuan bertan eskala aplikatu ahal izateko, beharrezkoa da Target bat erabiltzea (deskargatu Target-a hemen). Tamaina errealean inprimatu eta kapturatu nahi duzun objektuaren ondoan jarri behar duzu Target-a, argazkietan atera dadin. Lortuko duzun 3D eredua automatikoki eskalatuko da. Beste aukera bat da 3D eredua sortzea eta, ondoren, “Eskala” tresnarekin eskalatzea. Horretarako, ezagutzen dituzun bi puntuen arteko neurria ezarri behar duzu 3D ereduan.</p>',
    cat: '<p>Per ajustar l\'escala directament en el procés de generació del model 3D, cal utilitzar un Target(baixa el Target aquí).Has d\'imprimir-lo en dimensions reals i col·locar-lo al costat de l\'objecte que es capturarà, perquè aparegui en les fotos.L\'escala del model 3D resultant s\'ajusta automàticament.Una altra opció és generar el model 3D i després ajustar l\'escala amb l\'eina “Escala”, introduint una mesura entre 2 punts que coneguis al model 3D.</p>',
  },

  frequentlyQuestions_data27: {
    es: '<p>¿Cuál es el sistema de refencia de mi modelo 3D?</p>',
    en: '<p>Which is the reference system of my 3D model?</p>',
    pt: '<p>Qual é o sistema de referência do meu modelo 3D?</p>',
    fr: '<p>Quel est le système de référence de mon modèle 3D ?</p>',
    eus: '<p>Zein da nire 3D ereduaren erreferentzia sistema?</p>',
    cat: '<p>Quin és el sistema de referència del meu model 3D?</p>',
  },

  frequentlyQuestions_data28: {
    es: '<p>La plataforma establece un sistema de referencia relativo en uno de los puntos del modelo 3D. Este sistema no muestra los datos reales de medidas salvo que el modelo 3D sea escalado o los datos de GPS sean obtenidos a través de los metadatos de las imágenes.</p>',
    en: '<p>The platform establishes a relative reference system at one of the points of the 3D model. This system does not show the real distance data unless the 3D model is scaled or the GPS data is obtained through the metadata of the images.</p>',
    pt: '<p>A plataforma estabelece um sistema de referência relativo num dos pontos do modelo 3D. Este sistema não mostra os dados reais de medição a menos que o modelo 3D seja dimensionado ou os dados GPS sejam obtidos através dos metadados das imagens.</p>',
    fr: '<p>La plateforme établit un système de référence relatif à un des points du modèle 3D. Ce système ne montre pas les données réelles de mesure à moins que le modèle 3D soit mis à l’échelle ou que les données GPS soient obtenues par les métadonnées des images.</p>',
    eus: '<p>Plataformak erreferentzia sistema erlatiboa ezartzen du 3D ereduaren puntu batean. Sistema honek ez ditu erakusten neurrien datu errealak, 3D eredua eskalatu ez bada edo GPS datuak irudien metadatuen bidez lortu ez badira behintzat.</p>',
    cat: '<p>La plataforma estableix un sistema de referència relatiu en un dels punts del model 3D. Aquest sistema no mostra les dades reals de les mesures tret que s\'ajusti l\'escala del model 3D o que s\'obtinguin les dades de GPS a través de les metadades de les imatges.</p>',
  },

  frequentlyQuestions_data29: {
    es: '<p>Para resolver más dudas ponemos a tu disposición los manuales eyesCloud3d en nuestro Centro de ayuda.</p>',
    en: '<p>To resolve questions we provide you the eyesCloud3d manuals in our Help Center.</p>',
    pt: '<p>Para mais perguntas, fornecemos-te os manuais do eyesCloud3d no nosso Centro de Ajuda.</p>',
    fr: '<p>Pour résoudre vos doutes, nous mettons à votre dispositions les manuels eyesCloud3d dans notre Centre d’aide.</p>',
    eus: '<p>Zalantza gehiago argitzeko, eskura jartzen dizkizugu eyesCloud3d eskuliburuak gure Laguntza zentroan.</p>',
    cat: '<p>Per a resoldre més dubtes, posem a la teva disposició els manuals d’eyesCloud3d al nostre Centre d\'ajuda.</p>',
  },

  frequentlyQuestions_data30: {
    es: '<p>Ir al Centro de ayuda.</p>',
    en: '<p>Go to Help Center.</p>',
    pt: '<p>Ir para o Centro de Ajuda.</p>',
    fr: '<p>Aller au Centre d\'aide.</p>',
    eus: '<p>Joan Laguntza zentrora.</p>',
    cat: '<p>Visita el Centre d\'ajuda.</p>',
  },

  frequentlyQuestions_data31: {
    es: '<p>Preguntas Frecuentes</p>',
    en: '<p>Frequently asked questions</p>',
    pt: '<p>Perguntas Frequentes</p>',
    fr: '<p>Questions Fréquentes</p>',
    eus: '<p>Galderak</p>',
    cat: '<p>Preguntes Freqüents</p>',
  },

  profile_Data144: {
    es: '<p>Estás a un paso de ceder tu rol de [Superadmin/Admin].</p>',
    en: '<p>You are one step away from giving up your role as [Superadmin/Admin].</p>',
    pt: '<p>Está apenas a um passo de ceder o seu cargo de [Superadministrador/Administrador].</p>',
    fr: '<p>Vous allez transférer votre rôle de [Super Admin./Admin.].</p>',
    eus: '<p>[Superadmin/Admin] rola uztear zaude.</p>',
    cat: '<p>Estàs a punt de cedir el teu càrrec de [Superadmin/Admin].</p>',
  },

  profile_Data145: {
    es: '<p>Selecciona el usuario al que deseas transferir tu rol. Recuerda que, una vez realices esta acción, tu rol pasará a ser de Usuario y perderás tus funcionalidades actuales. A partir de este momento, tu cambio de rol, dependerá de un usuario Superadmin o Admin.</p>',
    en: '<p>Select the user(s) you want to transfer your role to. Remember that once you complete this action, you will have a User role, and you will lose your current features. From now on, any changes to your role must be carried out by a Superadmin or Admin user.</p>',
    pt: '<p>Selecione o utilizador para o qual pretende transferir o seu cargo. Lembre-se de que, uma vez executada esta ação, o seu cargo passará a ser de Utilizador e perderá as suas funcionalidades atuais. A partir deste momento, a sua alteração de cargo dependerá de um utilizador Superadministrador ou Administrador.</p>',
    fr: '<p>Sélectionnez le(s) utilisateur(s) auquel vous souhaitez transférer votre rôle. N\'oubliez pas qu’une fois cette action réalisée, votre rôle sera celui d\'Utilisateur et vous perdrez vos fonctionnalités actuelles. À partir de ce moment-là, votre changement de rôle dépendra d\'un Super Admin ou d\'un Admin.</p>',
    eus: '<p>Aukeratu zein erabiltzaileri transferitu nahi diozun zure rola. Gogoan izan ekintza hau egitean, Erabiltzaile rola izatera pasako duzula eta orain dauzkazun funtzionalitateak galduko dituzula. Hemendik aurrera, soilik Superadmin edo Admin erabiltzaile batek aldatu dezake zure rola.</p>',
    cat: '<p>Selecciona l’usuari a qui vols transferir el càrrec. Recorda que, un cop duguis a terme aquesta acció, passaràs a ser Usuari i perdràs totes les funcionalitats actuals. A partir d’aquest moment, el teu canvi de càrrec dependrà d’un usuari Superadmin o Admin.</p>',
  },

  profile_Data146: {
    es: '<p>Nombre</p>',
    en: '<p>Name</p>',
    pt: '<p>Nome</p>',
    fr: '<p>Nom</p>',
    eus: '<p>Izena</p>',
    cat: '<p>Nom</p>',
  },

  profile_Data147: {
    es: '<p>Tu cargo ha sido cedido correctamente.</p>',
    en: '<p>Your role has been transferred correctly.</p>',
    pt: '<p>O seu cargo foi cedido corretamente.</p>',
    fr: '<p>Votre rôle a été transféré avec succès.</p>',
    eus: '<p>Zure rola behar bezala transferitu da.</p>',
    cat: '<p>El càrrec s\'ha cedit correctament.</p>',
  },

  profile_Data148: {
    es: '<p>Tu cargo no se ha podido ceder.</p>',
    en: '<p>Your role could not be transferred.</p>',
    pt: '<p>Não foi possível ceder o seu cargo.</p>',
    fr: '<p>Votre rôle pas pu être transféré.</p>',
    eus: '<p>Zure rola ezin izan da transferitu.</p>',
    cat: '<p>El càrrec no s’ha pogut cedir.</p>',
  },
  profile_Data149: {
    es: '<p>¿Estás seguro de que deseas eliminar el usuario</p><p>de tu cuenta de businesss?</p>',
    en: '<p>Are you sure you want to delete the user</p><p>from your business account?</p>',
    pt: '<p>Tem a certeza de que pretende eliminar o utilizador</p><p>da sua conta businesss?</p>',
    fr: '<p>Êtes-vous sûr(e) de vouloir supprimer l’utilisateur </p><p> de votre compte Business ?</p>',
    eus: '<p>Ziur zaude zure business kontuko erabiltzailea</p><p>ezabatu nahi duzula?</p>',
    cat: '<p>Segur que vols eliminar l’usuari</p><p>del teu compte Business?</p>',
  },

  profile_Data150: {
    es: '<p>Sí, continuar</p>',
    en: '<p>Yes, continue</p>',
    pt: '<p>Sim, continuar</p>',
    fr: '<p>Oui, continuer</p>',
    eus: '<p>Bai, jarraitu</p>',
    cat: '<p>Sí, continua</p>',
  },

  profile_Data151: {
    es: '<p>¿Estás seguro de que deseas dar de baja el departamento %X%?</p>',
    en: '<p>Are you sure you want to close the department %X%?</p>',
    pt: '<p>Tem a certeza de que pretende eliminar o departamento %X%?</p>',
    fr: '<p>Êtes-vous sûr(e) de vouloir supprimer le département %X%?</p>',
    eus: '<p>Ziur zaude honako izena duen departamentuari baja eman nahi diozula: %X%?</p>',
    cat: '<p>Segur que vols donar de baixa el departament %X%?</p>',
  },

  profile_Data152: {
    es: '<p>La confirmación de esta acción supone la pérdida de los beneficios asociados a tu plan actual una vez finalice el ciclo de facturación en curso.</p>',
    en: '<p>If you confirm this action, you will lose the benefits associated with your current plan at the end of this billing cycle.</p>',
    pt: '<p>A confirmação desta ação resultará na perda dos benefícios associados ao seu plano atual no final do ciclo de faturação em curso.</p>',
    fr: '<p>En confirmant cette action, vous perdrez les avantages associés à votre abonnement actuel à la fin du cycle de facturation en cours.</p>',
    eus: '<p>Ekintza hau berresten baduzu, orain daukazun planari lotutako onura guztiak galduko dituzu, abian dagoen fakturazio-zikloa behin amaituta.</p>',
    cat: '<p>La confirmació d’aquesta acció suposa la pèrdua dels beneficis associats al pla actual un cop finalitzi el cicle de facturació en curs.</p>',
  },

  profile_Data153: {
    es: '<p>Tu cuenta no se ha podido dar de baja correctamente.</p>',
    en: '<p>Your account could not be closed correctly.</p>',
    pt: '<p>A sua conta não conseguiu registar a saída corretamente.</p>',
    fr: '<p>Votre compte a n’a pas pu être supprimé correctement.</p>',
    eus: '<p>Zure kontuari ezin izan zaio baja eman.</p>',
    cat: '<p>El compte no s’ha pogut donar de baixa correctament.</p>',
  },

  profile_Data154: {
    es: '<p>Departamentos:</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },

  profile_Data155: {
    es: '<p>Buscar:</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },

  profile_Data156: {
    es: '<p>¿Estás seguro de que deseas eliminar el usuario del departamento %X%?</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
};
