export const PROFILE_INCIDENCES_I18N_ENTRIES = {
  profile_incidences_Data1: {
    es: '<p>Escribir Incidencia</p>',
    en: '<p>Report an incident</p>',
    pt: '<p>Escrever incidência</p>',
    fr: '<p>Signaler incident</p>',
    eus: '<p>Eman arazo baten berri</p>',
    cat: '<p>Escriu una incidència</p>',
  },
  profile_incidences_Data2: {
    es: '<p>¿Tienes alguna incidencia</p><p>sobre este modelo 3D?</p>',
    en: '<p>Do you have any incidents related</p><p>to this 3D model?</p>',
    pt: '<p>Tem alguma incidência</p><p>relacionada com este modelo 3D?</p>',
    fr: '<p>Avez-vous un incident à signaler</p><p>par rapport à ce modèle 3D ?</p>',
    eus: '<p>Arazorik duzu 3D</p><p>eredu honekin?</p>',
    cat: '<p>Tens cap incidència</p><p>sobre aquest model 3D?</p>',
  },
  profile_incidences_Data3: {
    es: '<p>Escribir Incidencia</p>',
    en: '<p>Report an incident</p>',
    pt: '<p>Escrever incidência</p>',
    fr: '<p>Signaler incident</p>',
    eus: '<p>Eman arazo baten berri</p>',
    cat: '<p>Escriu una incidència</p>',
  },
  profile_incidences_Data4: {
    es: '<p>ESTADO</p>',
    en: '<p>STATUS</p>',
    pt: '<p>ESTADO</p>',
    fr: '<p>ÉTAT</p>',
    eus: '<p>EGOERA</p>',
    cat: '<p>ESTAT</p>',
  },
  profile_incidences_Data5: {
    es: '<p>Tienes nuevas respuestas en tus incidencias enviadas.</p>',
    en: '<p>You have new replies for the incidents you have submitted.</p>',
    pt: '<p>Tem novas respostas nas suas incidências enviadas.</p>',
    fr: '<p>Vous avez de nouvelles réponses à vos incidents envoyés.</p>',
    eus: '<p>Bidalitako arazoentzako erantzun berriren bat jaso duzu.</p>',
    cat: '<p>Hi ha noves respostes a les incidències enviades.</p>',
  },
  profile_incidences_Data6: {
    es: '<p>[VER TODAS]</p>',
    en: '<p>[VIEW ALL]</p>',
    pt: '<p>[VER TODAS]</p>',
    fr: '<p>[VOIR TOUT]</p>',
    eus: '<p>[IKUSI GUZTIAK]</p>',
    cat: '<p>[MOSTRA TOTES]</p>',
  },
  profile_incidences_Data7: {
    es: '<p>Actualmente no tienes incidencias en proceso</p>',
    en: '<p>You currently have no incidents in progress</p>',
    pt: '<p>Atualmente não tem incidências em curso</p>',
    fr: '<p>Vous n’avez actuellement aucun incident en cours</p>',
    eus: '<p>Une honetan ez daukazu arazorik abian</p>',
    cat: '<p>Actualment no hi ha incidències en curs</p>',
  },
  profile_incidences_Data8: {
    es: '<p>EN REVISIÓN</p>',
    en: '<p>UNDER REVIEW</p>',
    pt: '<p>EM REVISÃO</p>',
    fr: '<p>EN COURS DE TRAITEMENT</p>',
    eus: '<p>BERRIKUSKETAN</p>',
    cat: '<p>EN REVISIÓ</p>',
  },
  profile_incidences_Data9: {
    es: '<p>Detalla la incidencia de tu modelo 3D:</p>',
    en: '<p>Describe the incident with your 3D model:</p>',
    pt: '<p>Detalhe a incidência do seu modelo 3D:</p>',
    fr: '<p>Détaillez l’incident concernant votre modèle 3D :</p>',
    eus: '<p>Zehaztu 3D ereduarekin duzun arazoa:</p>',
    cat: '<p>Detalla la incidència del model 3D:</p>',
  },
  profile_incidences_Data10: {
    es: '<p>RECOMENDACIONES:</p>',
    en: '<p>TIPS:</p>',
    pt: '<p>RECOMENDAÇÕES:</p>',
    fr: '<p>RECOMMANDATIONS :</p>',
    eus: '<p>GOMENDIOAK:</p>',
    cat: '<p>RECOMANACIONS:</p>',
  },
  profile_incidences_Data11: {
    es: '<p>Recomendamos que adjuntes una captura de pantalla para poder ver los errores de tu modelo 3D, pulsa botón derecho sobre la pantalla y haz clic en inspeccionar, a continuación haz una captura y adjúntala.</p>',
    en: '<p>We recommend that you attach a screenshot so we can see the errors in your 3D model. Right-click on the screen and select Inspect, then take a screenshot and attach it.</p>',
    pt: '<p>Recomendamos que anexe uma captura de ecrã para poder ver os erros do seu modelo 3D, carregue no botão direito sobre o ecrã e clique em inspecionar. Em seguida, realize a captura e anexe-a.</p>',
    fr: '<p>Nous vous recommandons de joindre une capture d’écran pour voir les erreurs dans votre modèle 3D. Faites un clic droit sur l’écran et sélectionnez Inspecter, puis effectuez une capture d’écran et joignez-la.</p>',
    eus: '<p>Pantaila kaptura bat eranstea gomendatzen dizugu zure 3D ereduaren erroreak ikusi ahal izateko, sakatu eskuineko botoia pantailaren gainean eta egin klik Ikuskatu aukeran, ondoren kaptura bat egin eta erants ezazu.</p>',
    cat: '<p>Et recomanem adjuntar una captura de pantalla per poder veure els errors del model 3D, prem el botó dret sobre la pantalla i fes clic a l’opció d’inspeccionar. A continuació, fes una captura i adjunta-la.</p>',
  },
  profile_incidences_Data12: {
    es: '<p>Escribe la incidencia</p>',
    en: '<p>Report the incident</p>',
    pt: '<p>Escreva a incidência</p>',
    fr: '<p>Décrivez l’incident</p>',
    eus: '<p>Eman arazoaren berri</p>',
    cat: '<p>Escriu la incidència</p>',
  },
  profile_incidences_Data13: {
    es: '<p>Enviar Incidencia</p>',
    en: '<p>Submit Incident</p>',
    pt: '<p>Enviar incidência</p>',
    fr: '<p>Envoyer rapport d\'incident</p>',
    eus: '<p>Bidali arazoa</p>',
    cat: '<p>Envia la incidència</p>',
  },
  profile_incidences_Data14: {
    es: '<p>Nos pondremos en contacto contigo \n en la mayor brevedad posible.</p>',
    en: '<p>We will contact you \n as soon as possible.</p>',
    pt: '<p>Entraremos em contacto consigo \n o mais brevemente possível.</p>',
    fr: '<p>Nous vous contacterons dans les meilleurs délais.</p>',
    eus: '<p>Zurekin harremanetan jarriko gara \n ahalik eta lasterren.</p>',
    cat: '<p>Ens posarem en contacte amb tu \n al més aviat.</p>',
  },
  profile_incidences_Data15: {
    es: '<p>No se ha podido enviar la incidencia</p>',
    en: '<p>Failed to submit the incident</p>',
    pt: '<p>Não foi possível enviar a incidência</p>',
    fr: '<p>Le rapport d\'incident n’a pas pu être envoyé</p>',
    eus: '<p>Ezin izan da zure arazoa bidali.</p>',
    cat: '<p>No s’ha pogut enviar la incidència</p>',
  },
  profile_incidences_Data16: {
    es: '<p>RESUELTA</p>',
    en: '<p>RESOLVED</p>',
    pt: '<p>RESOLVIDA</p>',
    fr: '<p>RÉSOLU</p>',
    eus: '<p>KONPONDUTA</p>',
    cat: '<p>RESOLTA</p>',
  },
  profile_incidences_Data17: {
    es: '<p>Estado de Incidencias</p>',
    en: '<p>Incident Status</p>',
    pt: '<p>Estado de incidências</p>',
    fr: '<p>État des incidents</p>',
    eus: '<p>Arazoen egoera</p>',
    cat: '<p>Estat de les incidències</p>',
  },
  profile_incidences_Data18: {
    es: '<p>Para resolver tu incidencia de manera más efectiva, te recomendamos adjuntar una captura de pantalla del error. Para ello, haz clic con el botón derecho de tu ratón, selecciona la opción “Inspeccionar” y luego realiza la captura de pantalla.</p><br><p>Los formatos posibles que el usuario puede proporcionar son máx. 5MB en jpg, png, mp4 o pdf.</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
};
