export const DEFORMATION_ENERGY_REPORT_I18N_ENTRIES = {
  visores_PanelDerecho_Energíadeformacion_Reporte_data1: {
    es: '<p>Informe de Análisis de Deformación</p>',
    en: '<p>Deformation Analysis Report</p>',
    pt: '<p>Relatório de análise de deformação</p>',
    fr: '<p>Rapport d’analyse de déformation</p>',
    eus: '<p>Deformazio Analisiari buruzko Txostena</p>',
    cat: '<p>Informe d\'anàlisi de deformació</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data2: {
    es: '<p class="i18n-color-black">Nombre: </p>',
    en: '<p class="i18n-color-black">Name: </p>',
    pt: '<p class="i18n-color-black">Nome: </p>',
    fr: '<p class="i18n-color-black">Nom : </p>',
    eus: '<p class="i18n-color-black">Izena:</p>',
    cat: '<p class="i18n-color-black">Nom: </p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data3: {
    es: '<p>Modelo 3D para calcular la energía de deformación</p>',
    en: '<p>3D model for calculating deformation energy</p>',
    pt: '<p>Modelo 3D para calcular a energia de deformação</p>',
    fr: '<p>Modèle 3D pour calculer l’énergie de déformation</p>',
    eus: '<p>Deformazio energia kalkulatzeko 3D eredua</p>',
    cat: '<p>Model 3D per calcular l\'energia de deformació</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data4: {
    es: '<p  class="i18n-color-black">Código del Modelo: </p>',
    en: '<p class="i18n-color-black">Model Code: </p>',
    pt: '<p class="i18n-color-black">Código do modelo: </p>',
    fr: '<p class="i18n-color-black">Code du modèle : </p>',
    eus: '<p class="i18n-color-black">Ereduaren kodea: </p>',
    cat: '<p class="i18n-color-black">Codi del model: </p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data5: {
    es: '<p>Fecha: </p>',
    en: '<p>Date: </p>',
    pt: '<p>Data: </p>',
    fr: '<p>Date : </p>',
    eus: '<p>Data:</p>',
    cat: '<p>Data: </p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data6: {
    es: '<p>Tipo de Impacto: </p>',
    en: '<p>Crash Type: </p>',
    pt: '<p>Tipo de impacto: </p>',
    fr: '<p>Type d’impact : </p>',
    eus: '<p>Inpaktu mota: </p>',
    cat: '<p>Tipus d’impacte: </p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data7: {
    es: '<p>Frontal</p>',
    en: '<p>Front</p>',
    pt: '<p>Frontal</p>',
    fr: '<p>Avant</p>',
    eus: '<p>Aurrealdetik</p>',
    cat: '<p>Frontal</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data8: {
    es: '<p>Lateral</p>',
    en: '<p>Lateral</p>',
    pt: '<p>Lateral</p>',
    fr: '<p>Latéral</p>',
    eus: '<p>Albotik</p>',
    cat: '<p>Lateral</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data9: {
    es: '<p>Modelo</p>',
    en: '<p>Model</p>',
    pt: '<p>Modelo</p>',
    fr: '<p>Modèle</p>',
    eus: '<p>Modeloa</p>',
    cat: '<p>Model</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data10: {
    es: '<p>Año</p>',
    en: '<p>Year</p>',
    pt: '<p>Ano</p>',
    fr: '<p>Année</p>',
    eus: '<p>Urtea</p>',
    cat: '<p>Any</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data11: {
    es: '<p>Longitud</p>',
    en: '<p>Length</p>',
    pt: '<p>Comprimento</p>',
    fr: '<p>Longueur</p>',
    eus: '<p>Luzera</p>',
    cat: '<p>Llargària</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data12: {
    es: '<p>Ancho</p>',
    en: '<p>Width</p>',
    pt: '<p>Largura</p>',
    fr: '<p>Largeur</p>',
    eus: '<p>Zabalera</p>',
    cat: '<p>Amplària</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data13: {
    es: '<p>Distancia entre ejes</p>',
    en: '<p>Wheelbase</p>',
    pt: '<p>Distância entre eixos</p>',
    fr: '<p>Empattement</p>',
    eus: '<p>Ardatzen arteko neurria</p>',
    cat: '<p>Distancia entre eixos</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data14: {
    es: '<p>Detección de contorno de coche</p>',
    en: '<p>Car outline detection</p>',
    pt: '<p>Deteção de contorno de carro</p>',
    fr: '<p>Détection du contour du véhicule</p>',
    eus: '<p>Autoaren ingerada antzematea</p>',
    cat: '<p>Detecció de contorn del cotxe</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data15: {
    es: '<p>Medidas de deformación</p>',
    en: '<p>Deformation distances</p>',
    pt: '<p>Medidas de deformação</p>',
    fr: '<p>Mesures de déformation</p>',
    eus: '<p>Deformazio neurketak</p>',
    cat: '<p>Mesures de deformació</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data16: {
    es: '<p>Medida</p>',
    en: '<p>Distance</p>',
    pt: '<p>Medida</p>',
    fr: '<p>Mesure</p>',
    eus: '<p>Neurketa</p>',
    cat: '<p>Mida</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data17: {
    es: '<p>Parámetro</p>',
    en: '<p>Parameter</p>',
    pt: '<p>Parâmetro</p>',
    fr: '<p>Paramètre</p>',
    eus: '<p>Parametroa</p>',
    cat: '<p>Paràmetre</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data18: {
    es: '<p>Valor</p>',
    en: '<p>Value</p>',
    pt: '<p>Valor</p>',
    fr: '<p>Valeur</p>',
    eus: '<p>Balioa</p>',
    cat: '<p>Valor</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data19: {
    es: '<p>Categoría Vehículo</p>',
    en: '<p>Vehicle category</p>',
    pt: '<p>Categoria veículo</p>',
    fr: '<p>Catégorie du véhicule</p>',
    eus: '<p>Autoaren kategoria</p>',
    cat: '<p>Categoria vehicle</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data20: {
    es: '<p>Peso Vehículo + Peso Humano = Total Peso (m):</p>',
    en: '<p>Vehicle Weight + Human Weight = Total Weight (m):</p>',
    pt: '<p>Peso veículo + Peso humano = Total Peso (m):</p>',
    fr: '<p>poids véhicule + poids humain = Poids total (m):</p>',
    eus: '<p>Autoaren pisua + Pertsonen pisua = Guztizko pisua (m):</p>',
    cat: '<p>pes vehicle + pes humà = Pes total (m):</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data21: {
    es: '<p>Coeficiente A</p>',
    en: '<p>Coefficient A</p>',
    pt: '<p>Coeficiente A</p>',
    fr: '<p>Coefficient A</p>',
    eus: '<p>A koefizientea</p>',
    cat: '<p>Coeficient A</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data22: {
    es: '<p>Coeficiente B</p>',
    en: '<p>Coefficient B</p>',
    pt: '<p>Coeficiente B</p>',
    fr: '<p>Coefficient B</p>',
    eus: '<p>B koefizientea</p>',
    cat: '<p>Coeficient B</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data23: {
    es: '<p>Modelo Matemático: </p>',
    en: '<p>Mathematical Model: </p>',
    pt: '<p>Modelo matemático: </p>',
    fr: '<p>Modèle mathématique : </p>',
    eus: '<p>Eredu matematikoa: </p>',
    cat: '<p>Model matemàtic: </p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data24: {
    es: '<p>Intervalo entre medidas 1.750 (ancho segmento): 5(L) = 0.35cm</p>',
    en: '<p>Interval between distances 1.750 (segment width): 5(L) = 0.35cm</p>',
    pt: '<p>Intervalo entre medidas 1.750 (largura segmento): 5(L) = 0,35 cm</p>',
    fr: '<p>Intervalle entre mesures 1.750 (largeur segment): 5(L) = 0,35cm</p>',
    eus: '<p>Neurketen arteko tartea 1.750 (segmentuaren zabalera): 5(L) = 0,35cm</p>',
    cat: '<p>Interval entre mesures 1.750 (ample segment): 5(L) = 0,35 cm</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data25: {
    es: '<p>Ajuste Edef por ángulo de fuerza de impacto principal:</p>',
    en: '<p>Adjustment Edef by angle of main impact force:</p>',
    pt: '<p>Ajuste Edef por ângulo de força de impacto principal:</p>',
    fr: '<p>Ajustement Edef par angle de force d\'impact principal :</p>',
    eus: '<p>Edef doikuntza, inpaktuaren indar nagusiaren angeluaren arabera:</p>',
    cat: '<p>Ajustament Edef per angle de força d\'impacte principal:</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data26: {
    es: '<p>EBS (Velocidad Equivalente de Barrera):</p>',
    en: '<p>EBS (equivalent barrier speed):</p>',
    pt: '<p>EBS (Velocidade Equivalente de Barreira):</p>',
    fr: '<p>EBS (Vitesse de barrière équivalente) :</p>',
    eus: '<p>EBS (langa-abiadura baliokidea):</p>',
    cat: '<p>EBS (Velocitat equivalent de barrera):</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data27: {
    es: '<p>Edef: Energía de Deformación</p>',
    en: '<p>Edef: deformation energy</p>',
    pt: '<p>Edef: Energia de Deformação</p>',
    fr: '<p>Edef : Énergie de déformation</p>',
    eus: '<p>Edef: Deformazio energia</p>',
    cat: '<p>Edef: Energia de deformació</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data28: {
    es: '<p>m: peso del vehículo</p>',
    en: '<p>m: vehicle weight</p>',
    pt: '<p>m: peso do veículo</p>',
    fr: '<p>m : poids du véhicule</p>',
    eus: '<p>m: autoaren pisua</p>',
    cat: '<p>m: pes del vehicle</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data29: {
    es: '<p>Altura utilizada para Cálculo de Deformación</p>',
    en: '<p>Height used for deformation calculation</p>',
    pt: '<p>Altura usada para o cálculo de deformação</p>',
    fr: '<p>Hauteur utilisée pour le calcul de déformation</p>',
    eus: '<p>Deformazio kalkulua egiteko erabili den altuera</p>',
    cat: '<p>Alçària utilitzada per al càlcul de deformació</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data30: {
    es: '<p>Análisis de Deformación en Visor 3D</p>',
    en: '<p>Deformation Analysis on 3D Viewer</p>',
    pt: '<p>Análise de deformação em visor 3D</p>',
    fr: '<p>Analyse de déformation dans la visionneuse 3D</p>',
    eus: '<p>Deformazio analisia 3D ikustailean</p>',
    cat: '<p>Anàlisi de deformació en visor 3D</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data31: {
    es: '<p>Análisis de Deformación</p>',
    en: '<p>Deformation Analysis</p>',
    pt: '<p>Análise de deformação</p>',
    fr: '<p>Analyse de déformation</p>',
    eus: '<p>Deformazio analisia</p>',
    cat: '<p>Anàlisi de deformació</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data32: {
    es: '<p>Energía de Deformación</p>',
    en: '<p>Deformation Energy</p>',
    pt: '<p>Energia de deformação</p>',
    fr: '<p>Énergie de déformation</p>',
    eus: '<p>Deformazio energia</p>',
    cat: '<p>Energia de deformació</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data33: {
    es: '<p>Ángulo de Impacto</p>',
    en: '<p>Impact Angle</p>',
    pt: '<p>Ângulo de impacto</p>',
    fr: '<p>Angle d’impact</p>',
    eus: '<p>Inpaktuaren angelua</p>',
    cat: '<p>Angle de l’impacte</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data34: {
    es: '<p>Resultados no válidos para ángulos superiores a 45°</p>',
    en: '<p>Inaccurate results for angles greater than 45°</p>',
    pt: '<p>Resultados não válidos para ângulos superiores a 45°</p>',
    fr: '<p>Résultats non valables pour des angles supérieurs à 45°</p>',
    eus: '<p>Emaitzek ez dute balio 45°-tik gorako angeluetan</p>',
    cat: '<p>Resultats no vàlids per a angles superiors a 45°</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data35: {
    es: '<p>Velocidad Equivalente de Barrera</p>',
    en: '<p>Equivalent Barrier Speed</p>',
    pt: '<p>Velocidade equivalente de barreira</p>',
    fr: '<p>Vitesse de barrière équivalente</p>',
    eus: '<p>Langa-abiadura baliokidea</p>',
    cat: '<p>Velocitat equivalent de barrera</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data36: {
    es: '<p>Modelo 3D en Visor de Malla eyesCloud3D</p>',
    en: '<p>3D Model in eyesCloud3D Mesh Viewer</p>',
    pt: '<p>Modelo 3D em visor de malha eyesCloud3D</p>',
    fr: '<p>Modèle 3D dans la visionneuse de maille eyesCloud3D</p>',
    eus: '<p>3D eredua eyesCloud3D sare-ikustailean</p>',
    cat: '<p>Model 3D en Visor de Malla eyesCloud3D</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data37: {
    es: '<p>Peso del Vehículo</p>',
    en: '<p>Vehicle Weight</p>',
    pt: '<p>Peso do veículo</p>',
    fr: '<p>Poids du véhicule</p>',
    eus: '<p>Autoaren pisua</p>',
    cat: '<p>Pes del vehicle</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data38: {
    es: '<p>Mc Henry</p>',
    en: '<p>Mc Henry</p>',
    pt: '<p>Mc Henry</p>',
    fr: '<p>Mc Henry</p>',
    eus: '<p>Mc Henry</p>',
    cat: '<p>Mc Henry</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data39: {
    es: '<p>Mc Henry (Área).</p>',
    en: '<p>Mc Henry (Area).</p>',
    pt: '<p>Mc Henry (Área).</p>',
    fr: '<p>Mc Henry (Surface).</p>',
    eus: '<p>Mc Henry (Azalera).</p>',
    cat: '<p>Mc Henry (Àrea).</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data40: {
    es: '<p>Edef = (G * L + (A + B * x ) * Área)</p>',
    en: '<p>Edef = (G * L + (A + B * x ) * Area)</p>',
    pt: '<p>Edef = (G * L + (A + B * x ) * Área)</p>',
    fr: '<p>Edef = (G * L + (A + B * x ) * Surface)</p>',
    eus: '<p>Edef = (G * L + (A + B * x ) * Azalera)</p>',
    cat: '<p>Edef = (G * L + (A + B * x ) * Àrea)</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data41: {
    es: '<p>G: A²/(2 * B),  x: distancia desde el centroide a la superficie original,  Área: área deformada.</p>',
    en: '<p>G: A²/(2 * B), x: distance from centroid to original surface, Area: deformed area.</p>',
    pt: '<p>G: A²/(2 * B),  x: distância do centroide à superfície original,  Área: área deformada.</p>',
    fr: '<p>G : A²/(2 * B), x : distance du centroïde à la surface d’origine, Surface : surface déformée.</p>',
    eus: '<p>G: A²/(2 * B),  x: barizentroaren eta jatorrizko azaleraren arteko distantzia, Azalera: deformatutako azalera.</p>',
    cat: '<p>G: A²/(2 * B),  x: distància del centre de gravetat a la superfície original,  Àrea: àrea deformada.</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data42: {
    es: '<p>Edef = Edef * (1 + tan²(ángulo) )</p>',
    en: '<p>Edef = Edef * (1 + tan²(angle) )</p>',
    pt: '<p>Edef = Edef * (1 + tan²(ângulo) )</p>',
    fr: '<p>Edef = Edef * (1 + tan²(angle) )</p>',
    eus: '<p>Edef = Edef * (1 + tan²(angelua) )</p>',
    cat: '<p>Edef = Edef * (1 + tan²(angle) )</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data43: {
    es: '<p>Altura de Estudio para Cálculo de Deformación</p>',
    en: '<p>Study Height for Deformation Calculation</p>',
    pt: '<p>Altura de estudo para cálculo de deformação</p>',
    fr: '<p>Hauteur d’étude pour le calcul de la déformation</p>',
    eus: '<p>Deformazio kalkulua egiteko estudioko altuera</p>',
    cat: '<p>Alçària d’estudi per al càlcul de deformació</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data44: {
    es: '<p>Informe de Análisis de Deformación de Vehículo.pdf</p>',
    en: '<p>Vehicle Deformation Analysis Report.pdf</p>',
    pt: '<p>Relatório de análise de deformação de veículo.pdf</p>',
    fr: '<p>Rapport d’analyse de déformation de véhicule.pdf</p>',
    eus: '<p>Ibilgailuaren deformazio analisiaren txostena.pdf</p>',
    cat: '<p>Informe d’anàlisi de deformació de vehicle.pdf</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data45: {
    es: '<p>Frontal</p>',
    en: '<p>Front</p>',
    pt: '<p>Frontal</p>',
    fr: '<p>Avant</p>',
    eus: '<p>Aurrealdetik</p>',
    cat: '<p>Frontal</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data46: {
    es: '<p>Posterior</p>',
    en: '<p>Back</p>',
    pt: '<p>Posterior</p>',
    fr: '<p>Arrière</p>',
    eus: '<p>Atzealdetik</p>',
    cat: '<p>Posterior</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data47: {
    es: '<p>Izquierda</p>',
    en: '<p>Left</p>',
    pt: '<p>Esquerda</p>',
    fr: '<p>Gauche</p>',
    eus: '<p>Ezkerraldetik</p>',
    cat: '<p>Esquerra</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data48: {
    es: '<p>Derecha</p>',
    en: '<p>Right</p>',
    pt: '<p>Direita</p>',
    fr: '<p>Droite</p>',
    eus: '<p>Eskuinaldetik</p>',
    cat: '<p>Dreta</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data49: {
    es: '<p>( Resultados no válidos para ángulos superiores a 45° )</p>',
    en: '<p>( Inaccurate results for angles greater than 45° )</p>',
    pt: '<p>( Resultados não válidos para ângulos superiores a 45° )</p>',
    fr: '<p>( Résultats non valables pour des angles supérieurs à 45° )</p>',
    eus: '<p>(Emaitzek ez dute balio 45°-tik gorako angeluetan)</p>',
    cat: '<p>(Resultats no vàlids per a angles superiors a 45°)</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data50: {
    es: '<p>INFORME DE DEFORMACIÓN DE VEHÍCULO</p>',
    en: '<p>VEHICLE DEFORMATION REPORT</p>',
    pt: '<p>RELATÓRIO DE DEFORMAÇÃO DE VEÍCULO</p>',
    fr: '<p>RAPPORT DE DÉFORMATION DU VÉHICULE</p>',
    eus: '<p>IBILGAILUAREN DEFORMAZIO TXOSTENA</p>',
    cat: '<p>INFORME DE DEFORMACIÓ DE VEHICLE</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data51: {
    es: '<p>Código del Modelo</p>',
    en: '<p>Model Code</p>',
    pt: '<p>Código do modelo</p>',
    fr: '<p>Code du modèle</p>',
    eus: '<p>Ereduaren kodea</p>',
    cat: '<p>Codi del model</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data52: {
    es: '<p>Nombre del Modelo</p>',
    en: '<p>Model Name</p>',
    pt: '<p>Nome do modelo</p>',
    fr: '<p>Nom du modèle</p>',
    eus: '<p>Ereduaren izena</p>',
    cat: '<p>Nom del model</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data53: {
    es: '<p>Fecha del Informe</p>',
    en: '<p>Report Date</p>',
    pt: '<p>Data do relatório</p>',
    fr: '<p>Date du rapport</p>',
    eus: '<p>Txostenaren data</p>',
    cat: '<p>Data de l’informe</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data54: {
    es: '<p>Hora del Informe</p>',
    en: '<p>Report Time</p>',
    pt: '<p>Hora do relatório</p>',
    fr: '<p>Heure du rapport</p>',
    eus: '<p>Txostenaren ordua</p>',
    cat: '<p>Hora de l’informe</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data55: {
    es: '<p>Datos del Vehículo</p>',
    en: '<p>Vehicle Data</p>',
    pt: '<p>Dados do veículo</p>',
    fr: '<p>Données du véhicule</p>',
    eus: '<p>Ibilgailuaren datuak</p>',
    cat: '<p>Dades del vehicle</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data56: {
    es: '<p>Modelo</p>',
    en: '<p>Model</p>',
    pt: '<p>Modelo</p>',
    fr: '<p>Modèle</p>',
    eus: '<p>Modeloa</p>',
    cat: '<p>Model</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data57: {
    es: '<p>Tipo de Impacto</p>',
    en: '<p>Crash Type</p>',
    pt: '<p>Tipo de impacto</p>',
    fr: '<p>Type d’impact</p>',
    eus: '<p>Inpaktu mota</p>',
    cat: '<p>Tipus d’impacte</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data58: {
    es: '<p>Año</p>',
    en: '<p>Year</p>',
    pt: '<p>Ano</p>',
    fr: '<p>Année</p>',
    eus: '<p>Urtea</p>',
    cat: '<p>Any</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data59: {
    es: '<p>Longitud</p>',
    en: '<p>Length</p>',
    pt: '<p>Comprimento</p>',
    fr: '<p>Longueur</p>',
    eus: '<p>Luzera</p>',
    cat: '<p>Llargària</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data60: {
    es: '<p>Ancho</p>',
    en: '<p>Width</p>',
    pt: '<p>Largura</p>',
    fr: '<p>Largeur</p>',
    eus: '<p>Zabalera</p>',
    cat: '<p>Amplària</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data61: {
    es: '<p>Medidas de Deformación</p>',
    en: '<p>Deformation Distances</p>',
    pt: '<p>Medidas de deformação</p>',
    fr: '<p>Mesures de déformation</p>',
    eus: '<p>Deformazio neurketak</p>',
    cat: '<p>Mesures de deformació</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data62: {
    es: '<p>Medida</p>',
    en: '<p>Distance</p>',
    pt: '<p>Medida</p>',
    fr: '<p>Mesure</p>',
    eus: '<p>Neurketa</p>',
    cat: '<p>Mida</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data63: {
    es: '<p>Valor</p>',
    en: '<p>Value</p>',
    pt: '<p>Valor</p>',
    fr: '<p>Valeur</p>',
    eus: '<p>Balioa</p>',
    cat: '<p>Valor</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data64: {
    es: '<p>Análisis de Deformación</p>',
    en: '<p>Deformation Analysis</p>',
    pt: '<p>Análise de deformação</p>',
    fr: '<p>Analyse de déformation</p>',
    eus: '<p>Deformazio analisia</p>',
    cat: '<p>Anàlisi de deformació</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data65: {
    es: '<p>Informe de Deformación del Vehículo</p>',
    en: '<p>Vehicle Deformation Report</p>',
    pt: '<p>Relatório de deformação do veículo</p>',
    fr: '<p>Rapport de déformation du véhicule</p>',
    eus: '<p>Autoaren deformazio txostena</p>',
    cat: '<p>Informe de deformació del vehicle</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data66: {
    es: '<p>Intervalo entre las medidas: </p>',
    en: '<p>Interval between distances: </p>',
    pt: '<p>Intervalo entre as medidas: </p>',
    fr: '<p>Intervalle entre les mesures : </p>',
    eus: '<p>Neurketen arteko tarteak:</p>',
    cat: '<p>Interval entre les mesures: </p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data67: {
    es: '<p>m (ancho de segmento): 5(L) = </p>',
    en: '<p>m (segment width): 5(L) = </p>',
    pt: '<p>m (largura do segmento): 5(L) = </p>',
    fr: '<p>m (largeur de segment): 5(L) = </p>',
    eus: '<p>m (segmentuaren zabalera): 5(L) = </p>',
    cat: '<p>m (amplada del segment): 5(L) = </p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data68: {
    es: '<p>Trasero</p>',
    en: '<p>Rear</p>',
    pt: '<p>Traseira</p>',
    fr: '<p>Arrière</p>',
    eus: '<p>Atzealdetik</p>',
    cat: '<p>Posterior</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data69: {
    es: '<p>Informe de Análisis de Deformación de Vehículo.jpg</p>',
    en: '<p>Vehicle Deformation Analysis Report.jpg</p>',
    pt: '<p>Relatório de análise de deformação de veículo.jpg</p>',
    fr: '<p>Rapport d\'analyse de déformation de véhicule.jpg</p>',
    eus: '<p>Ibilgailuaren deformazio analisiaren txostena.jpg</p>',
    cat: '<p>Informe d\'anàlisi de deformació de vehicle.jpg</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data70: {
    es: '<p>Categoría %X%</p>',
    en: '<p>Category %X%</p>',
    pt: '<p>Categoria %X%</p>',
    fr: '<p>Catégorie %X%</p>',
    eus: '<p>%X% Kategoria</p>',
    cat: '<p>Categoria %X%</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data71: {
    es: '<p>informe_analisis_deformacion</p>',
    en: '<p>Deformation_analysis_report</p>',
    pt: '<p>informe_analisis_deformacion</p>',
    fr: '<p>Rapport_danalyse_de_déformation</p>',
    eus: '<p>Deformazio_analisiaren_txostena</p>',
    cat: '<p>informe_danàlisi_de_deformació</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data72: {
    es: '<p>Edef = A² *L/2 *A * B * L/20 * [C1 + 2 * (C2 + C3 + C4 + C5) + C6] + B * l/30 *</p>',
    en: '<p>Edef = A² *L/2 *A * B * L/20 * [C1 + 2 * (C2 + C3 + C4 + C5) + C6] + B * l/30 *</p>',
    pt: '<p>Edef = A² *L/2 *A * B * L/20 * [C1 + 2 * (C2 + C3 + C4 + C5) + C6] + B * l/30 *</p>',
    fr: '<p>Edef = A² *L/2 *A * B * L/20 * [C1 + 2 * (C2 + C3 + C4 + C5) + C6] + B * l/30 *</p>',
    eus: '<p>Edef = A² *L/2 *A * B * L/20 * [C1 + 2 * (C2 + C3 + C4 + C5) + C6] + B * l/30 *</p>',
    cat: '<p>Edef = A² *L/2 *A * B * L/20 * [C1 + 2 * (C2 + C3 + C4 + C5) + C6] + B * l/30 *</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data73: {
    es: '<p>* [C1² + 2 * (C2² + C3² + C4² + C5²) + C6²] + C1 * C2 + C2 * C3 + C3 * C4 + C4 * C5 + C5 * C6]</p>',
    en: '<p>* [C1² + 2 * (C2² + C3² + C4² + C5²) + C6²] + C1 * C2 + C2 * C3 + C3 * C4 + C4 * C5 + C5 * C6]</p>',
    pt: '<p>* [C1² + 2 * (C2² + C3² + C4² + C5²) + C6²] + C1 * C2 + C2 * C3 + C3 * C4 + C4 * C5 + C5 * C6]</p>',
    fr: '<p>* [C1² + 2 * (C2² + C3² + C4² + C5²) + C6²] + C1 * C2 + C2 * C3 + C3 * C4 + C4 * C5 + C5 * C6]</p>',
    eus: '<p>* [C1² + 2 * (C2² + C3² + C4² + C5²) + C6²] + C1 * C2 + C2 * C3 + C3 * C4 + C4 * C5 + C5 * C6]</p>',
    cat: '<p>* [C1² + 2 * (C2² + C3² + C4² + C5²) + C6²] + C1 * C2 + C2 * C3 + C3 * C4 + C4 * C5 + C5 * C6]</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data74: {
    es: '<p>Dmed = 1/6(C1+C2+C3+C4+C5+C6)</p>',
    en: '<p>Davg = 1/6(C1+C2+C3+C4+C5+C6)</p>',
    pt: '<p>Dmed = 1/6(C1+C2+C3+C4+C5+C6)</p>',
    fr: '<p>Dmoy = 1/6(C1+C2+C3+C4+C5+C6)</p>',
    eus: '<p>Dbb = 1/6(C1+C2+C3+C4+C5+C6)</p>',
    cat: '<p>Dmit= 1/6(C1+C2+C3+C4+C5+C6)</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data75: {
    es: '<p>Edef = A .( (Dmed / Dmax) .Ln ( 1 / 1 - Dmax/L)+ B)*masaTotal</p>',
    en: '<p>Edef = A .( (Davg / Dmax) .Ln ( 1 / 1 - Dmax/L)+ B)*Mass total</p>',
    pt: '<p>Edef = A .( (Dmed / Dmax) .Ln ( 1 / 1 - Dmax/L)+ B)*Massa total</p>',
    fr: '<p>Edef = A .( (Dmoy / Dmax) .Ln ( 1 / 1 - Dmax/L)+ B)*Masse totale</p>',
    eus: '<p>Edef = A .( (Dbb / Dmax) .Ln ( 1 / 1 - Dmax/L)+ B)*Masa guztira</p>',
    cat: '<p>Edef = A .( (Dmit / Dmax) .Ln ( 1 / 1 - Dmax/L)+ B)*Massa total</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data76: {
    es: '<p>Análisis de deformación (comparativa según modelo matemático)</p>',
    en: '<p>Deformation analysis (comparative according to mathematical model)</p>',
    pt: '<p>Análise de deformação (comparativa de acordo com o modelo matemático)</p>',
    fr: '<p>Analyse de déformation (comparaison selon modèle mathématique)</p>',
    eus: '<p>Deformazio analisia (eredu matematikoaren araberako konparazioa)</p>',
    cat: '<p>Anàlisi de deformació (comparativa segons model matemàtic)</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data77: {
    es: '<p>Cálculo matemático</p>',
    en: '<p>Mathematical calculation</p>',
    pt: '<p>Cálculo matemático</p>',
    fr: '<p>Calcul mathématique</p>',
    eus: '<p>Kalkulu matematikoa</p>',
    cat: '<p>Càlcul matemàtic</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data78: {
    es: '<p>Ángulo</p>',
    en: '<p>Angle</p>',
    pt: '<p>Ângulo</p>',
    fr: '<p>Angle</p>',
    eus: '<p>Angelua</p>',
    cat: '<p>Angle</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data79: {
    es: '<p>Velocidad</p>',
    en: '<p>Speed</p>',
    pt: '<p>Velocidade</p>',
    fr: '<p>Vitesse</p>',
    eus: '<p>Abiadura</p>',
    cat: '<p>Velocitat</p>',
  },

  visores_PanelDerecho_Energíadeformacion_Reporte_data80: {
    es: '<p>Modelo matemático: Mc Henry (Área)</p>',
    en: '<p>Mathematical Model: Mc Henry (Area)</p>',
    pt: '<p>Modelo matemático: Mc Henry (Área)</p>',
    fr: '<p>Modèle mathématique : Mc Henry (Aire)</p>',
    eus: '<p>Eredu matematikoa: Mc Henry (Azalera)</p>',
    cat: '<p>Model matemàtic: Mc Henry (Àrea)</p>',
  },
};
