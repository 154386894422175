import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseProfile } from '../../models/security/base-profile';
import { SecurityTokenStorage } from './security-token-storage';
import { LoginRemoteService } from './login-remote.service';
import { AcceptedLogin } from '../../models/security/accepted-login';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Credential } from '../../models/security/credential';
import { User } from '../../commons/models/user/user';
import { RestClientService } from '../../core/services/api-access/rest-client.service';
import { SocialCredential } from '../../models/security/socialcredential';
import { I18nService } from '../../core/services/i18n.service';
import { ForgotPasswordRemoteService } from './forgot-password-remote.service';
import { LoaderService } from '../../core/services/loader.service';
import { EmailVerifiedRemoteService } from './email-verified-remote.service';
import { ConfigurationRemoteService } from '../common/configuration-remote.service';
import { StorageService } from 'src/app/models/storage/storage.service';
import { UserRemoteService } from '../../core/services/remote/user/user.remote.service';
import { UserToken } from '../../models/security/user-token';
import { BimServerCredential } from '../../models/security/bim-server-credential';
import { GalleryService } from 'src/app/core/services/gallery/gallery.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TwoStepVerificationComponent } from 'src/app/auth/components/two-step-verification/two-step-verification.component';
import { CommsService } from 'src/app/core/services/comms.service';
import { environment } from 'src/environments/environment';
import { PrivateUrlRemoteService } from 'src/app/core/services/remote/private-url/private-url-remote.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private user: User = null;

  authUser$ = new BehaviorSubject<User>(null);
  private OnLogin = new Subject<AcceptedLogin<BaseProfile>>();
  public OnLogout = new Subject();

  private URL = '/two-factor';

  constructor(
    private loginRemoteService: LoginRemoteService,
    private securityTokenStorage: SecurityTokenStorage<UserToken>,
    private router: Router,
    private restClientService: RestClientService,
    private i18nService: I18nService,
    private forgotPasswordRemoteService: ForgotPasswordRemoteService,
    private loaderService: LoaderService,
    private emailVerifiedRemoteService: EmailVerifiedRemoteService,
    private configurationService: ConfigurationRemoteService,
    private storageService: StorageService,
    private commsService: CommsService,
    private privateUrlRemoteService: PrivateUrlRemoteService
  ) {
    // this.setDefaultLanguage();
    this.securityTokenStorage.onSessionExpired().subscribe(() => {
      this.redirectToLogin();
    });
  }

  static getAuthenticationUrl(): string {
    try {
      if (window.self !== window.top) {
        return '/login-bimserver';
      } else {
        return '/login';
      }
    } catch (e) {
      return '/login';
    }
  }

  get(redirectToLogin = true): Observable<User> {
    const subject = new Subject<any>();
    this.restClientService.get('auth-user').subscribe(
      (user) => {
        subject.next(user);
        this.authUser$.next(user as User);
        // if ((user as any).two_factor_pending && this.dialog.openDialogs.length === 0 && !this.router.url.includes('login')) {
        //   this.dialog.open(TwoStepVerificationComponent, {
        //     autoFocus: false,
        //     disableClose: true,
        //     hasBackdrop: true,
        //     panelClass: 'two-step-verification-component',
        //   });
        // }
      },
      () => {
        // if (redirectToLogin) {
        //   this.redirectToLogin();
        // } else {
        //   subject.error('Not authenticated user');
        // }
      }
    );
    return subject;
  }

  login<T extends BaseProfile>(
    email: string,
    password: string
  ): Observable<AcceptedLogin<T>> {
    const subject = new Subject<AcceptedLogin<T>>();
    this.loginRemoteService.login(new Credential(email, password)).subscribe(
      (acceptedLogin) => {
        if (acceptedLogin.data.token) {
          this.configure(acceptedLogin.data);
          subject.next(acceptedLogin.data);
          this.OnLogin.next(acceptedLogin.data);
        } else {
          subject.error(acceptedLogin.data.error);
        }
      },
      (error) => {
        subject.error(error.error);
      }
    );
    return subject;
  }

  logout() {
    const tokenObj = this.securityTokenStorage.getAcceptedLogin();
    if (tokenObj !== null) {
      this.loginRemoteService.logout().subscribe(() => {
        this.commsService.setCurrentUser(null);
        this.loaderService.hideLoader();
        this.redirectToLogin();
      });
    } else {
      this.redirectToLogin();
    }
  }

  deleteSecurityTokenInfo() {
    this.securityTokenStorage.deleteFromStorage();
    this.redirectToLogin();
  }

  onLogin(): Observable<any> {
    return this.OnLogin;
  }

  onLogout(): Observable<any> {
    return this.OnLogout;
  }

  socialLogin<T extends BaseProfile>(
    email: string,
    authToken: string
  ): Observable<AcceptedLogin<T>> {
    const subject = new Subject<AcceptedLogin<T>>();
    this.loginRemoteService
      .socialLogin<T>(new SocialCredential(email, authToken))
      .subscribe(
        (acceptedLogin) => {
          if (acceptedLogin.token) {
            this.configure(acceptedLogin);
            subject.next(acceptedLogin);
            this.OnLogin.next(acceptedLogin);
          } else {
            subject.error(acceptedLogin.error);
          }
        },
        (error) => {
          subject.error(error.error);
        }
      );
    return subject;
  }

  forgotPassword(email) {
    const subject = new Subject<any>();
    const emailParameter = {
      email: email.email,
      language_code: this.i18nService.getCurrentLanguage().code,
    };

    this.forgotPasswordRemoteService.forgotPassword(emailParameter).subscribe(
      (response) => {
        subject.next(response);
      },
      (error) => {
        subject.error(error);
      }
    );
    return subject;
  }

  resetPassword(parameters) {
    const subject = new Subject<any>();
    this.forgotPasswordRemoteService.resetPassword(parameters).subscribe(
      (acceptedLogin) => {
        if (acceptedLogin.token) {
          this.configure(acceptedLogin);
          subject.next(acceptedLogin);
          this.OnLogin.next(acceptedLogin);
        } else {
          subject.error(acceptedLogin.error);
        }
      },
      (error) => {
        subject.error(error);
      }
    );
    return subject;
  }

  checkOldPassword(password) {
    const body = {
      password: password,
    };
    return this.restClientService.get(`${this.URL}/check-old-password`, body);
  }
  verifiedEmail<T extends BaseProfile>(token): Observable<AcceptedLogin<T>> {
    const subject = new Subject<any>();
    this.emailVerifiedRemoteService.verify(token).subscribe(
      (acceptedLogin) => {
        if (acceptedLogin.token) {
          this.configure(acceptedLogin);
          subject.next(acceptedLogin);
          this.OnLogin.next(acceptedLogin);
        } else {
          subject.error(acceptedLogin.error);
        }
      },
      (error) => {
        subject.error(error.error);
      }
    );

    return subject;
  }

  loginWithToken<T extends BaseProfile>(token): Observable<AcceptedLogin<T>> {
    const subject = new Subject<any>();
    this.loginRemoteService.loginWithToken(token).subscribe(
      (acceptedLogin) => {
        if (acceptedLogin.token) {
          this.configure(acceptedLogin);
          subject.next(acceptedLogin);
          this.OnLogin.next(acceptedLogin);
        } else {
          subject.error(acceptedLogin.error);
        }
      },
      (error) => {
        subject.error(error.error);
      }
    );

    return subject;
  }

  bimServerLogin(email: string, password: string): void {
    const bimserverLogin = {
      service: 'login',
      email,
      password,
    };
    window.parent.postMessage(bimserverLogin, '*');
  }

  executeBimServerLogin<T extends BaseProfile>(
    email: string,
    password: string
  ): Observable<AcceptedLogin<T>> {
    const subject = new Subject<AcceptedLogin<T>>();

    // Para la autenticacion con BIMServer se va a usar una llave que se guardara en config
    this.configurationService.bimserverKey().subscribe(
      (configuration) => {
        const bimServerPassword = window.btoa(
          email + '---' + configuration.value
        );
        // Se loguea al usuario. Este servicio crea el usuario si no existe en la base de datos
        this.loginRemoteService
          .bimServerLogin<T>(
            new BimServerCredential(email, password, bimServerPassword)
          )
          .subscribe(
            (acceptedLogin) => {
              if (acceptedLogin && acceptedLogin.token) {
                this.configure(acceptedLogin);
                subject.next(acceptedLogin);
                this.OnLogin.next(acceptedLogin);
              } else {
                subject.error(acceptedLogin.error);
              }
            },
            (error) => {
              subject.error(error.error);
            }
          );
      },
      () => {
        subject.error('Error in bimserver login');
      }
    );

    return subject;
  }

  private setDefaultLanguage(): void {
    const userInfo = this.securityTokenStorage.getObjectValue();
    const defaultLanguage = userInfo
      ? userInfo.locale
      : I18nService.getSupportedLanguages()[1].code;

    if (this.storageService.get('language')) {
      this.i18nService.setCurrentLanguage(this.storageService.get('language'));
    } else if (userInfo) {
      this.i18nService.setCurrentLanguage(userInfo.locale);
    } else {
      const browserLang = navigator.language;
      const browserFormatted = browserLang.substr(0, 2);
      if (browserFormatted === 'en') {
        this.i18nService.setCurrentLanguage('en');
      } else if (browserFormatted === 'es') {
        this.i18nService.setCurrentLanguage('es');
      } else {
        this.i18nService.setCurrentLanguage(defaultLanguage);
      }
    }
  }

  private redirectToLogin(): void {
    this.securityTokenStorage.deleteFromStorage();
    this.loaderService.hideLoader();
    this.OnLogout.next();
    if (
      this.privateUrlRemoteService.checked &&
      this.privateUrlRemoteService.privateUrl
    ) {
      this.router.navigateByUrl('/login');
    } else {
      const matches = document.cookie.match(
        new RegExp(
          '(?:^|; )' +
          'android-login'.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
          '=([^;]*)'
        )
      );
      if (matches) {
        if (parseInt(decodeURIComponent(matches[1])) == 1) {
          const cookieValue = `android-login=0; Domain=.eyescloud3d.com;Path=/; SameSite=Lax`;
          document.cookie = cookieValue;
          window.location.href = `${environment.landingUrl}/android-login`;
        } else {
          window.location.href = `${environment.landingUrl}/iniciar-sesion`;
        }
      } else {
        window.location.href = `${environment.landingUrl}/iniciar-sesion`;
      }
    }
    // this.router
    //   .navigateByUrl(AuthenticationService.getAuthenticationUrl())
    //   .then(() => {
    //     this.OnLogout.next();
    //   });
  }

  private configure<T extends BaseProfile>(
    acceptedLogin: AcceptedLogin<T>
  ): void {
    const tokenObj = {
      token: acceptedLogin.token,
      tokenExpirationDate: acceptedLogin.tokenExpirationDate,
      locale: acceptedLogin.locale,
    };
    this.securityTokenStorage.saveObject(tokenObj);
    this.setDefaultLanguage();
  }

  public useCommasForDecimals(): Observable<boolean> {
    return this.restClientService.get('commas-period-style');
  }

  public checkIp(): Observable<any> {
    return this.restClientService.get('check-ip');
  }

  public sendTwoFactorCodeByEmail(email = null): Observable<any> {
    return this.restClientService.post(`${this.URL}/send-email`, email);
  }
  public checkPhoneIsCorrect(phone_number: string = ''): Observable<any> {
    return this.restClientService.get(`${this.URL}/check-phone`, {
      phone_number,
    });
  }
  public sendTwoFactorCodeBySms(phone_number: string = ''): Observable<any> {
    const body = {
      phone_number: phone_number,
    };
    return this.restClientService.post(`${this.URL}/send-sms`, body);
  }

  public checkTwoFactorCode(
    mCode: string,
    companyWide: boolean = false
  ): Observable<any> {
    return this.restClientService.put(`${this.URL}/check-code`, {
      code: mCode,
      companyWide,
    });
  }

  public isEmailAvailable(email: string): Observable<any> {
    return this.restClientService.get(
      `${this.URL}/is-email-available/${email}`
    );
  }

  public disableTwoStepVerification(
    disable_for_entire_company: boolean = false
  ) {
    return this.restClientService.put(`${this.URL}/disable`, {
      disable_for_entire_company,
    });
  }

  /**
   * Modifica la contraseña del usuario actual por la especificada.
   *
   * @param password Nueva contraseña.
   * @returns null
   */
  public changePassword(
    old_password: string,
    new_password: string
  ): Observable<null> {
    return this.restClientService.put('change-password', {
      old_password,
      new_password,
    });
  }

  /**
   * Borra la cuenta del usuario actual.
   *
   * @returns Determina si el borrado se ha realizado o no de forma correcta.
   */
  public deleteAccount(): Observable<null> {
    return this.restClientService.delete('delete-account');
  }
}
