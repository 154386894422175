import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CheckContrastService {

  constructor() { }

  public getBestContrast(color: string) {
    const rgbColor = this.hexToRgb(color);
    if (!rgbColor) {
      return null;
    }

    return {
      white: this.contrast(rgbColor, this.hexToRgb('#ffffff')),
      black: this.contrast(rgbColor, this.hexToRgb('#000000')),
      orange: this.contrast(rgbColor, this.hexToRgb('#ffd900')),
    };
  }

  private contrast(
    rgb1: { r: number; g: number; b: number } | null,
    rgb2: { r: number; g: number; b: number } | null
  ) {
    if (!rgb1 || !rgb2) {
      return null;
    }

    const lum1 = this.luminance(rgb1.r, rgb1.g, rgb1.b);
    const lum2 = this.luminance(rgb2.r, rgb2.g, rgb2.b);
    const brightest = Math.max(lum1, lum2);
    const darkest = Math.min(lum1, lum2);
    return (brightest + 0.05) / (darkest + 0.05);
  }

  private luminance(r, g, b) {
    const RED = 0.2126;
    const GREEN = 0.7152;
    const BLUE = 0.0722;
    const GAMMA = 2.4;

    const a = [r, g, b].map((v) => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, GAMMA);
    });
    return a[0] * RED + a[1] * GREEN + a[2] * BLUE;
  }

  private hexToRgb(hex: string) {
    const validHex = /^#([a-fA-F\d]{3}|[a-fA-F\d]{6})$/i.test(hex);
    if (!validHex) {
      return null;
    }

    if (hex.length === 4) {
      hex = `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`;
    }

    const result = /^#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
      : null;
  }
}
