import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/models/storage/storage.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-nc-consent',
  templateUrl: './nc-consent.component.html',
  styleUrls: ['./nc-consent.component.scss'],
})
export class NcConsentComponent implements OnInit {
  cookieUrl: string;
  is1280 = this.responsiveService.isxxll();

  constructor(
    private storageService: StorageService,
    private responsiveService: ResponsiveService,
    public dialog: MatDialogRef<NcConsentComponent>,
  ) { }

  ngOnInit() {
  }

  acceptCookies() {
    this.storageService.set('cookiesRead', '1');
    this.dialog.close();
  }

  onResize() {
    this.is1280 = this.responsiveService.isxxll();
  }
}
