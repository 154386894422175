export const NEW_RATES_I18N_ENTRIES = {
  myRates_data1: {
    es: '<p>Estándar</p>',
    en: '<p>Standard</p>',
    pt: '<p>Padrão</p>',
    fr: '<p>Standard</p>',
    eus: '<p>Estandarrak</p>',
    cat: '<p>Estàndard</p>',
  },

  myRates_data2: {
    es: '<p>Pro</p>',
    en: '<p>Pro</p>',
    pt: '<p>Pro</p>',
    fr: '<p>Pro</p>',
    eus: '<p>Pro</p>',
    cat: '<p>Pro</p>',
  },

  myRates_data3: {
    es: '<p>Business</p>',
    en: '<p>Business</p>',
    pt: '<p>Business</p>',
    fr: '<p>Business</p>',
    eus: '<p>Business</p>',
    cat: '<p>Business</p>',
  },

  myRates_data5: {
    es: '<p><span class="i18n-bold">Usuarios: </span>%X%</p>',
    en: '<p><span class="i18n-bold">Users: </span>%X%</p>',
    pt: '<p><span class="i18n-bold">Utilizadores: </span>%X%</p>',
    fr: '<p><span class="i18n-bold">Utilisateurs : </span>%X%</p>',
    eus: '<p><span class="i18n-bold">Erabiltzaileak: </span>%X%</p>',
    cat: '<p><span class="i18n-bold">Usuaris: </span>%X%</p>',
  },

  myRates_data6: {
    es: '<p><span class="i18n-bold">Límites de subida: </span>Hasta %X% fotos/ %X% min de vídeo</p>',
    en: '<p><span class="i18n-bold">Upload limit: </span>Up to %X% photos/%X% min of video</p>',
    pt: '<p><span class="i18n-bold">Limites de registo: </span>Até %X% fotografias/ %X% min de vídeo</p>',
    fr: '<p><span class="i18n-bold">Limites de téléchargement : </span>Jusqu\'à %X% photos/ vidéo de %X% min</p>',
    eus: '<p><span class="i18n-bold">Karga-muga: </span>Gehienez %X% argazki / %X% minutu bideo</p>',
    cat: '<p><span class="i18n-bold">Límits de càrrega: </span>fins a %X% fotos/ %X% min de vídeo</p>',
  },

  myRates_data7: {
    es: '<p>fotos/%X% min de vídeo</p>',
    en: '<p>photos/%X% min of video</p>',
    pt: '<p>fotografias/%X% min de vídeo</p>',
    fr: '<p>photos/vidéo de %X% min</p>',
    eus: '<p>argazki / %X% minutu bideo</p>',
    cat: '<p>fotos/%X% min de vídeo</p>',
  },

  myRates_data8: {
    es: '<p><span class="i18n-bold">Descargas: </span>%X%</p>',
    en: '<p><span class="i18n-bold">Downloads: </span>%X%</p>',
    pt: '<p><span class="i18n-bold">Transferências: </span>%X%</p>',
    fr: '<p><span class="i18n-bold">Téléchargements : </span>%X%</p>',
    eus: '<p><span class="i18n-bold">Deskargak: </span>%X%</p>',
    cat: '<p><span class="i18n-bold">Baixades: </span>%X%</p>',
  },

  myRates_data9: {
    es: '<p>no incluidas</p>',
    en: '<p>not included</p>',
    pt: '<p>não incluídas</p>',
    fr: '<p>non inclus</p>',
    eus: '<p>ez daude sartuta</p>',
    cat: '<p>no s’inclouen</p>',
  },

  myRates_data10: {
    es: '<p>no incluidas</p>',
    en: '<p>not included</p>',
    pt: '<p>não incluídas</p>',
    fr: '<p>non inclus</p>',
    eus: '<p>ez daude sartuta</p>',
    cat: '<p>no s’inclouen</p>',
  },

  myRates_data11: {
    es: '<p>Tamaño de biblioteca online:</p>',
    en: '<p>Online gallery size:</p>',
    pt: '<p>Tamanho da biblioteca online:</p>',
    fr: '<p>Taille de la bibliothèque en ligne :</p>',
    eus: '<p>Online liburutegiaren tamaina:</p>',
    cat: '<p>Mida de la biblioteca en línia:</p>',
  },

  myRates_data12: {
    es: '<p>Actualmente activa</p>',
    en: '<p>Currently active</p>',
    pt: '<p>Atualmente ativa</p>',
    fr: '<p>Actuellement active</p>',
    eus: '<p>Unean aktibatuta</p>',
    cat: '<p>Actualment activa</p>',
  },

  myRates_data14: {
    es: '<p>Descargas:</p>',
    en: '<p>Downloads:</p>',
    pt: '<p>Transferências:</p>',
    fr: '<p>Téléchargements :</p>',
    eus: '<p>Deskargak:</p>',
    cat: '<p>Baixades:</p>',
  },

  myRates_data15: {
    es: '<p>importaciones que quieras</p>',
    en: '<p>downloads you want</p>',
    pt: '<p>importações que pretenda</p>',
    fr: '<p>importations souhaitées</p>',
    eus: '<p>inportazio kopurua aukeratu</p>',
    cat: '<p>importacions que vulguis</p>',
  },

  myRates_data16: {
    es: '<p>descargas que quieras</p>',
    en: '<p>imports you want</p>',
    pt: '<p>transferências que pretenda</p>',
    fr: '<p>téléchargements souhaités</p>',
    eus: '<p>deskarga kopurua aukeratu</p>',
    cat: '<p>baixades que vulguis</p>',
  },

  myRates_data17: {
    es: '<p>Consulta condiciones</p>',
    en: '<p>Consult conditions</p>',
    pt: '<p>Consulte as condições</p>',
    fr: '<p>Consultez les conditions</p>',
    eus: '<p>Kontsultatu baldintzak</p>',
    cat: '<p>Consulta les condicions</p>',
  },

  myRates_data18: {
    es: '<p>Comprar</p>',
    en: '<p>Purchase</p>',
    pt: '<p>Comprar</p>',
    fr: '<p>Acheter</p>',
    eus: '<p>Erosi</p>',
    cat: '<p>Compra</p>',
  },

  myRates_data19: {
    es: '<p>%X% €</p>',
    en: '<p>€ %X%</p>',
    pt: '<p>%X% €</p>',
    fr: '<p>%X% €</p>',
    eus: '<p>%X% €</p>',
    cat: '<p>%X% €</p>',
  },

  myRates_data21: {
    es: '<p>/modelo</p>',
    en: '<p>/model</p>',
    pt: '<p>/modelo</p>',
    fr: '<p>/modèle</p>',
    eus: '<p>/eredu bakoitzeko</p>',
    cat: '<p>/model</p>',
  },

  myRates_data22: {
    es: '<p><span class="i18n-bold">Importaciones: </span>%X%</p>',
    en: '<p><span class="i18n-bold">Imports: </span>%X%</p>',
    pt: '<p><span class="i18n-bold">Importações: </span>%X%</p>',
    fr: '<p><span class="i18n-bold">Importations : </span>%X%</p>',
    eus: '<p><span class="i18n-bold">Inportazioak: </span>%X%</p>',
    cat: '<p><span class="i18n-bold">importacions: </span>%X%</p>',
  },

  myRates_data23: {
    es: '<p>Mensual</p>',
    en: '<p>Monthly</p>',
    pt: '<p>Mensal</p>',
    fr: '<p>Mensuel</p>',
    eus: '<p>Hileko</p>',
    cat: '<p>Mensual</p>',
  },

  myRates_data24: {
    es: '<p>Anual</p>',
    en: '<p>Annually</p>',
    pt: '<p>Anual</p>',
    fr: '<p>Annuel</p>',
    eus: '<p>Urteko</p>',
    cat: '<p>Anual</p>',
  },

  myRates_data25: {
    es: '<p>+%X%</p>',
    en: '<p>+%X%</p>',
    pt: '<p>+%X%</p>',
    fr: '<p>+%X%</p>',
    eus: '<p>+%X%</p>',
    cat: '<p>+%X%</p>',
  },

  myRates_data26: {
    es: '<p>MESES GRATIS</p>',
    en: '<p>MONTHS FREE</p>',
    pt: '<p>MESES GRÁTIS</p>',
    fr: '<p>MOIS GRATUITS</p>',
    eus: '<p>HILABETE DOHAINIK</p>',
    cat: '<p>MESOS GRATIS</p>',
  },

  myRates_data27: {
    es: '<p>(%X%€ facturado anualmente)</p>',
    en: '<p>(€%X% charged annually)</p>',
    pt: '<p>(%X%€ faturado anualmente)</p>',
    fr: '<p>(%X%€ facturé annuellement)</p>',
    eus: '<p>(%X% € urtean fakturatuta)</p>',
    cat: '<p>(%X% € facturat anualment)</p>',
  },

  myRates_data28: {
    es: '<p>BUSINESS +</p>',
    en: '<p>BUSINESS +</p>',
    pt: '<p>BUSINESS +</p>',
    fr: '<p>BUSINESS +</p>',
    eus: '<p>BUSINESS +</p>',
    cat: '<p>BUSINESS +</p>',
  },

  myRates_data29: {
    es: '<p><span class="i18n-bold">Tamaño de biblioteca online: </span>%X% modelos en línea</p>',
    en: '<p><span class="i18n-bold">Online gallery size: </span>%X% online models</p>',
    pt: '<p><span class="i18n-bold">Tamanho da biblioteca online: </span>%X% modelos online</p>',
    fr: '<p><span class="i18n-bold">Taille de la bibliothèque en ligne : </span>%X% modèles en ligne</p>',
    eus: '<p><span class="i18n-bold">Online liburutegiaren tamaina: </span>%X% eredu linean</p>',
    cat: '<p><span class="i18n-bold">Mida de la biblioteca en línia: </span>%X% models en línia</p>',
  },

  myRates_data30: {
    es: '<p>%X%€/mes</p>',
    en: '<p>€%X% per month</p>',
    pt: '<p>%X%€/mês</p>',
    fr: '<p>%X%€/mois</p>',
    eus: '<p>%X% €/hileko</p>',
    cat: '<p>%X% €/mes</p>',
  },

  myRates_data31: {
    es: '<p>%X% incluidos</p>',
    en: '<p>%X% included</p>',
    pt: '<p>%X% incluídos</p>',
    fr: '<p>%X% inclus</p>',
    eus: '<p>%X% barne</p>',
    cat: '<p>%X% inclosos</p>',
  },

  myRates_data32: {
    es: '<p>Condiciones de la tarifa</p>',
    en: '<p>Conditions of the plan</p>',
    pt: '<p>Condições da tarifa</p>',
    fr: '<p>Conditions du tarif</p>',
    eus: '<p>Tarifaren baldintzak</p>',
    cat: '<p>Condicions de la tarifa</p>',
  },

  myRates_data33: {
    es: '<p>ACEPTAR</p>',
    en: '<p>ACCEPT</p>',
    pt: '<p>ACEITAR</p>',
    fr: '<p>ACCEPTER</p>',
    eus: '<p>ONARTU</p>',
    cat: '<p>ACCEPTA</p>',
  },

  myRates_data34: {
    es: '<p>Nombre de la tarifa</p>',
    en: '<p>Plan name</p>',
    pt: '<p>Nome da tarifa</p>',
    fr: '<p>Nom du tarif</p>',
    eus: '<p>Tarifaren izena</p>',
    cat: '<p>Nom de la tarifa</p>',
  },

  myRates_data35: {
    es: '<p>Texto legal sobre las condiciones de la tarifa</p>',
    en: '<p>Legal text about the conditions of the plan</p>',
    pt: '<p>Texto legal sobre as condições da tarifa</p>',
    fr: '<p>Mentions légales concernant les conditions tarifaires</p>',
    eus: '<p>Tarifaren baldintzei buruzko ohar legala</p>',
    cat: '<p>Text legal sobre les condicions de la tarifa</p>',
  },

  myRates_data36: {
    es: '<p>Estás a un paso de modificar tu tarifa</p><p>actual %X%.</p>',
    en: '<p>You are one step away from changing your</p><p>current plan %X%.</p>',
    pt: '<p>Está a um passo de modificar a sua tarifa</p><p>atual %X%.</p>',
    fr: '<p>Vous allez modifier votre tarif</p><p>actuel %X%.</p>',
    eus: '<p>Orain duzun %X% tarifa</p><p>aldatzear zaude.</p>',
    cat: '<p>Estàs a punt de modificar la tarifa</p><p>actual %X%.</p>',
  },

  myRates_data37: {
    es: '<p>Si haces clic en tarifa adicional, tendrás dos suscripciones activas. Si, por el contrario, seleccionas mejorar tarifa, tu tarifa actual se sustituirá.</p>',
    en: '<p>If you click on “additional plan,” you will have two active plans. If you click on “improve plan,” you will replace your current plan.</p>',
    pt: '<p>Se clicar na tarifa adicional, terá duas subscrições ativas. Se, pelo contrário, selecionar melhorar a tarifa, a sua tarifa atual será substituída.</p>',
    fr: '<p>Si vous cliquez sur tarif supplémentaire, vous disposerez de deux abonnements actifs. Si, par contre, vous souhaitez mettre à niveau votre tarif, votre tarif actuel sera remplacé.</p>',
    eus: '<p>"Gehitu tarifa" botoian klik egiten baduzu, bi harpidetza aktibo izango dituzu. Aitzitik, "Hobetu tarifa" aukeratzen baduzu, zure egungo tarifa ordezkatu egingo da.</p>',
    cat: '<p>Si fas clic en tarifa addicional, tindràs dues subscripcions actives. En canvi, si selecciones millora la tarifa, la tarifa actual se substituirà.</p>',
  },

  myRates_data38: {
    es: '<p>TARIFA ADICIONAL</p>',
    en: '<p>ADDITIONAL PLAN</p>',
    pt: '<p>TARIFA ADICIONAL</p>',
    fr: '<p>TARIF ADDITIONNEL</p>',
    eus: '<p>TARIFA GEHITU</p>',
    cat: '<p>TARIFA ADDICIONAL</p>',
  },

  myRates_data39: {
    es: '<p>MEJORAR TARIFA</p>',
    en: '<p>IMPROVE PLAN</p>',
    pt: '<p>MELHORAR TARIFA</p>',
    fr: '<p>METTRE À NIVEAU LE TARIF</p>',
    eus: '<p>HOBETU TARIFA</p>',
    cat: '<p>MILLORA LA TARIFA</p>',
  },

  myRates_data40: {
    es: '<p>Selecciona tu nueva tarifa</p>',
    en: '<p>Select your new plan</p>',
    pt: '<p>Selecione a sua nova tarifa</p>',
    fr: '<p>Sélectionnez votre nouveau tarif</p>',
    eus: '<p>Aukeratu zure tarifa berria</p>',
    cat: '<p>Selecciona la tarifa nova</p>',
  },

  myRates_data41: {
    es: '<p>Atrás</p>',
    en: '<p>Back</p>',
    pt: '<p>Atrás</p>',
    fr: '<p>Retour</p>',
    eus: '<p>Atzera</p>',
    cat: '<p>Enrere</p>',
  },

  myRates_data42: {
    es: '<p>¿Estás seguro de que quieres añadir una nueva tarifa?</p>',
    en: '<p>Are you sure you want to add a new plan?</p>',
    pt: '<p>Tem a certeza de que pretende adicionar uma nova tarifa?</p>',
    fr: '<p>Êtes-vous sûr(e) de vouloir ajouter un nouveau tarif ?</p>',
    eus: '<p>Ziur zaude tarifa berri bat gehitu nahi duzula?</p>',
    cat: '<p>Segur que vols afegir una tarifa nova?</p>',
  },

  myRates_data43: {
    es: '<p>Te recomendamos:</p>',
    en: '<p>We recommend:</p>',
    pt: '<p>Recomendamos:</p>',
    fr: '<p>Notre recommandation :</p>',
    eus: '<p>Gomendatzen dizugu:</p>',
    cat: '<p>Et recomanem:</p>',
  },

  myRates_data44: {
    es: '<p>TODAS LAS HERRAMIENTAS</p>',
    en: '<p>ALL TOOLS</p>',
    pt: '<p>TODAS AS FERRAMENTAS</p>',
    fr: '<p>TOUS LES OUTILS</p>',
    eus: '<p>TRESNA GUZTIAK</p>',
    cat: '<p>TOTES LES EINES</p>',
  },

  myRates_data45: {
    es: '<p>Descripción</p>',
    en: '<p>Description</p>',
    pt: '<p>Descrição</p>',
    fr: '<p>Description</p>',
    eus: '<p>Deskribapena</p>',
    cat: '<p>Descripció</p>',
  },

  myRates_data46: {
    es: '<p>Añadir código promocional</p>',
    en: '<p>Add promotional code</p>',
    pt: '<p>Adicionar código promocional</p>',
    fr: '<p>Saisir un code promo</p>',
    eus: '<p>Gehitu promozio kodea</p>',
    cat: '<p>Afegeix un codi promocional</p>',
  },

  myRates_data47: {
    es: '<p>*El código introducido no se corresponde con ninguna promoción activa</p>',
    en: '<p>*The code you entered is not valid</p>',
    pt: '<p>*O código introduzido não corresponde a nenhuma promoção ativa</p>',
    fr: '<p>*Le code saisi ne correspond à aucune</p><p>promotion active</p>',
    eus: '<p>*Sartu duzun kodea ez dator bat promozio aktibo batekin</p>',
    cat: '<p>*El codi que s\'ha introduït no es correspon amb cap promoció activa</p>',
  },

  myRates_data48: {
    es: '<p>Fecha de vencimiento*</p>',
    en: '<p>Expiration date*</p>',
    pt: '<p>Data de vencimento*</p>',
    fr: '<p>Date d’expiration*</p>',
    eus: '<p>Epemuga data*</p>',
    cat: '<p>Data de venciment*</p>',
  },

  myRates_data49: {
    es: '<p>CVV*</p>',
    en: '<p>CVV*</p>',
    pt: '<p>CVV*</p>',
    fr: '<p>CVV*</p>',
    eus: '<p>CVC*</p>',
    cat: '<p>CVV*</p>',
  },

  myRates_data50: {
    es: '<p>Nombre del titular de la tarjeta*</p>',
    en: '<p>Cardholder name</p>',
    pt: '<p>Nome do titular do cartão*</p>',
    fr: '<p>Nom du titulaire de la carte*</p>',
    eus: '<p>Txartelaren titularraren izena*</p>',
    cat: '<p>Nom del titular de la targeta*</p>',
  },

  myRates_data51: {
    es: '<p>Dirección de facturación</p>',
    en: '<p>Billing address</p>',
    pt: '<p>Endereço de faturação</p>',
    fr: '<p>Adresse de facturation</p>',
    eus: '<p>Fakturazio helbidea</p>',
    cat: '<p>Adreça de facturació</p>',
  },

  myRates_data52: {
    es: '<p>Nombre de la empresa</p>',
    en: '<p>Company name</p>',
    pt: '<p>Nome da empresa</p>',
    fr: '<p>Nom de l’entreprise</p>',
    eus: '<p>Enpresaren izena</p>',
    cat: '<p>Nom de l’empresa</p>',
  },

  myRates_data53: {
    es: '<p>CIF</p>',
    en: '<p>Tax ID</p>',
    pt: '<p>CIF</p>',
    fr: '<p>CIF</p>',
    eus: '<p>IFK</p>',
    cat: '<p>CIF</p>',
  },

  myRates_data54: {
    es: '<p>Ciudad</p>',
    en: '<p>City</p>',
    pt: '<p>Cidade</p>',
    fr: '<p>Ville</p>',
    eus: '<p>Hiria</p>',
    cat: '<p>Ciutat</p>',
  },

  myRates_data55: {
    es: '<p>Código Postal</p>',
    en: '<p>Postal/ZIP code</p>',
    pt: '<p>Código postal</p>',
    fr: '<p>Code postal</p>',
    eus: '<p>Kode postala</p>',
    cat: '<p>Codi postal</p>',
  },

  myRates_data56: {
    es: '<p>BIC (código Swift)</p>',
    en: '<p>BIC (SWIFT code)</p>',
    pt: '<p>BIC (código Swift)</p>',
    fr: '<p>BIC (code SWIFT)</p>',
    eus: '<p>BIC (Swift kodea)</p>',
    cat: '<p>BIC (codi Swift)</p>',
  },

  myRates_data57: {
    es: '<p>IBAN</p>',
    en: '<p>IBAN</p>',
    pt: '<p>IBAN</p>',
    fr: '<p>IBAN</p>',
    eus: '<p>IBAN</p>',
    cat: '<p>IBAN</p>',
  },

  myRates_data58: {
    es: '<p>Titular de la cuenta</p>',
    en: '<p>Account holder name</p>',
    pt: '<p>Titular da conta</p>',
    fr: '<p>Titulaire du compte</p>',
    eus: '<p>Kontuaren titularra</p>',
    cat: '<p>Titular del compte</p>',
  },

  myRates_data59: {
    es: '<p>Selecciona otro método de pago:</p>',
    en: '<p>Select another payment method:</p>',
    pt: '<p>Selecione outro método de pagamento:</p>',
    fr: '<p>Sélectionnez un autre mode de paiement :</p>',
    eus: '<p>Aukeratu beste ordainketa metodo bat:</p>',
    cat: '<p>Selecciona un altre mètode de pagament:</p>',
  },

  myRates_data60: {
    es: '<p>Si confirmas el pago, le permitirás a  que cargue</p><p>en tu tarjeta este pago y futuros pagos conforme a las condiciones estipuladas.</p>',
    en: '<p>By confirming the payment, you give permission to charge</p><p>your card for this payment and future payments as stipulated in the conditions.</p>',
    pt: '<p>Se confirmar o pagamento, autoriza o débito</p><p>no seu cartão deste pagamento e futuros pagamentos conforme as condições estipuladas.</p>',
    fr: '<p>Si vous confirmez le paiement, vous nous autorisez à débiter votre carte pour ce paiement et les paiements futurs selon les conditions stipulées.</p>',
    eus: '<p>Ordainketa berresten baduzu, baimena ematen duzu</p><p>ordainketa hau eta etorkizuneko ordainketak zure txartelean kargatzeko, ezarritako baldintzen arabera.</p>',
    cat: '<p>Si confirmes el pagament, permetràs que se\'t faci un càrrec</p><p>a la targeta d\'aquest pagament i de pagaments futurs d\'acord amb les condicions estipulades.</p>',
  },

  myRates_data61: {
    es: '<p>Pagar</p>',
    en: '<p>Make payment</p>',
    pt: '<p>Pagar</p>',
    fr: '<p>Payer</p>',
    eus: '<p>Ordaindu</p>',
    cat: '<p>Paga</p>',
  },

  myRates_data62: {
    es: '<p>Ha ocurrido un error en el proceso de pago.</p>',
    en: '<p>An error occurred during the payment process.</p>',
    pt: '<p>Ocorreu um erro no processo de pagamento.</p>',
    fr: '<p>Une erreur est survenue durant le processus de paiement.</p>',
    eus: '<p>Errore bat gertatu da ordainketa prozesuan.</p>',
    cat: '<p>Hi ha hagut un error en el procés de pagament.</p>',
  },

  myRates_data63: {
    es: '<p>Por favor, comprueba tus datos, contacta con tu entidad bancaria</p><p>o inténtalo de nuevo más tarde.</p>',
    en: '<p>Please check your information, contact your bank,</p><p>or try again later.</p>',
    pt: '<p>Verifique os seus dados, contacte a sua entidade bancária</p><p>ou tente novamente mais tarde.</p>',
    fr: '<p>Vérifiez les informations saisies, contactez votre banque</p><p>ou réessayez plus tard.</p>',
    eus: '<p>Mesedez, egiaztatu zure datuak, jarri harremanetan zure bankuarekin</p><p>edo saiatu berriro geroago.</p>',
    cat: '<p>Comprova les dades, posa’t en contacte amb l\'entitat bancària</p><p>o torna a provar-ho més tard.</p>',
  },

  myRates_data64: {
    es: '<p>REINTENTAR</p>',
    en: '<p>TRY AGAIN</p>',
    pt: '<p>TENTAR NOVAMENTE</p>',
    fr: '<p>RÉESSAYER</p>',
    eus: '<p>BERRIRO SAIATU</p>',
    cat: '<p>REINTENTA</p>',
  },

  myRates_data65: {
    es: '<p>CONFIRMAR COMPRA</p>',
    en: '<p>CONFIRM PAYMENT</p>',
    pt: '<p>CONFIRMAR COMPRA</p>',
    fr: '<p>CONFIRMER L\'ACHAT</p>',
    eus: '<p>BERRETSI EROSKETA</p>',
    cat: '<p>CONFIRMA LA COMPRA</p>',
  },

  myRates_data66: {
    es: '<p>(%X% anual)</p>',
    en: '<p>(%X% annually)</p>',
    pt: '<p>(%X% anual)</p>',
    fr: '<p>(%X% par an)</p>',
    eus: '<p>(%X% urteko)</p>',
    cat: '<p>(%X% anual)</p>',
  },

  myRates_data67: {
    es: '<p>Rellena todos los campos correctamente para poder continuar</p>',
    en: '<p>All fields must be filled in correctly before you can continue</p>',
    pt: '<p>Preencha todos os campos corretamente para poder continuar</p>',
    fr: '<p>Veuillez remplir correctement tous les champs pour continuer.</p>',
    eus: '<p>Bete itzazu behar bezala eremu guztiak jarraitu ahal izateko</p>',
    cat: '<p>Emplena tots els camps correctament per poder continuar</p>',
  },

  myRates_data68: {
    es: '<p>Al introducir tu número de cuenta bancaria SEPA (Single Euro Payments Area) en nuestro servicio, estás otorgando tu consentimiento expreso y autorización para que eyesCloud3D realice los pagos necesarios en virtud de los servicios que ofrece. Esta autorización comprende:</p><p>Autorización de Débito Directo: Autorizas a eyesCloud3D a debitar fondos de tu cuenta bancaria de acuerdo con las instrucciones proporcionadas para completar pagos relacionados con nuestros servicios.</p><p>Consentimiento para Transacciones Recurrentes: Reconoces y aceptas que esta autorización se aplicará a transacciones únicas y recurrentes, según sea necesario para el uso continuado de nuestros servicios.</p><p>Cargos y Tarifas: Eres responsable de asegurarte de que haya fondos suficientes en tu cuenta para cubrir los pagos autorizados. Además, está de acuerdo en pagar cualquier tarifa o cargo asociado con los pagos realizados en virtud de esta autorización.</p><p>Modificaciones y Cancelaciones: Tienes el derecho de modificar o cancelar esta autorización en cualquier momento, según lo permitido por las leyes y regulaciones aplicables. Para hacerlo, ponte en contacto con nuestro equipo de soporte.</p><p>Notificación de Cambios: En caso de que se realicen cambios en los términos de esta autorización o en la forma en que se procesan los pagos, se te notificará con antelación de acuerdo con las leyes aplicables.</p>',
    en: '<p>By entering your SEPA (Single Euro Payments Area) bank account number in our service, you give your explicit consent and authorization for eyesCloud3D to collect the payments necessary for the services it offers. This authorization includes:</p><p>Direct Debit Authorization: You authorize eyesCloud3D to debit payments from your bank account in accordance with the instructions provided to collect payments related to our services.</p><p>Consent for Recurring Payments: You understand and accept that this authorization will apply to one- time and recurring payments as necessary for the continued use of our services.</p><p>Charges and Fees: You are responsible for ensuring that there are sufficient funds in your account to cover the authorized payments.Furthermore, you agree to pay any fees and/ or charges associated with the payments made under this authorization.</p><p>Changes and Cancellations: You have the right to modify or revoke this authorization at any moment, in accordance with applicable laws and regulations.To do so, please contact our support team.</p><p>Notification of Changes: In the case that changes are made to the terms of this authorization or to the way in which payments are processed, you will be notified in advance in accordance with applicable laws.</p>',
    pt: '<p>Ao introduzir o seu número de conta bancária SEPA (Single Euro Payments Area) no nosso serviço, está a dar o seu consentimento expresso e autorização para que a eyesCloud3D realize os pagamentos necessários em virtude dos serviços que oferece. Esta autorização compreende:</p><p>Autorização de Débito Direto: Autoriza a eyesCloud3D a debitar fundos da sua conta bancária de acordo com as instruções proporcionadas para completar pagamentos relacionados com os nossos serviços.</p><p>Consentimento para Transações Recorrentes: Reconhece e aceita que esta autorização irá aplicar-se a transações únicas e recorrentes, conforme necessário para a utilização contínua dos nossos serviços.</p><p>Encargos e tarifas: É responsável por certificar-se de que existem fundos suficientes na sua conta para cobrir os pagamentos autorizados. Além disso, concorda pagar qualquer tarifa ou encargo associado aos pagamentos realizados em virtude desta autorização.</p><p>Modificações e Cancelamentos: Tem o direito de modificar ou cancelar esta autorização a qualquer momento, ao abrigo do permitido pelas leis e regulamentos aplicáveis. Para fazê-lo, entre em contacto com a nossa equipa de suporte.</p><p>Notificação de Alterações: Caso sejam realizadas alterações nos termos desta autorização ou na forma como são processados os pagamentos, será notificado com antecedência de acordo com as leis aplicáveis.</p>',
    fr: '<p>En introduisant votre numéro de compte bancaire SEPA (Single Euro Payments Area) dans notre système, vous donnez votre consentement et votre autorisation expresse pour que eyesCloud3D effectue les paiements nécessaires dans le cadre des services offerts. Cette autorisation inclut :</p><p>Autorisation de prélèvement automatique: Vous autorisez eyesCloud3D à prélever des fonds de votre compte bancaire conformément aux instructions fournies pour effectuer les paiements relatifs à nos services.</p><p>Consentement pour les transactions récurrentes: Vous reconnaissez et acceptez que cette autorisation s’appliquera aux transactions uniques et récurrentes nécessaires à l’utilisation continue de nos services.</p><p>Frais et tarifs: Il vous incombe de veiller à ce que votre compte soit suffisamment approvisionné pour couvrir les ordres de paiements autorisés.En outre, vous acceptez de payer tous les frais ou tarifs relatifs aux paiements effectués dans le cadre de cette autorisation.</p><p>Modifications et annulations: Vous avez le droit de modifier ou d’annuler cette autorisation à tout moment, conformément aux lois et réglementations applicables.Pour ce faire, veuillez contacter notre équipe technique.</p><p>Notification des modifications: Si des modifications sont apportées aux conditions de la présente autorisation ou à la manière dont les paiements sont traités, vous en serez informé à l’avance conformément aux lois applicables.</p>',
    eus: '<p>Gure zerbitzuan zure banku-kontuaren SEPA (Single Euro Payments Area) zenbakia sartzean, berariazko baimena eta onespena ematen ari zara eyesCloud3D-k eskaintzen dituen zerbitzuen arabera egin beharreko ordainketak egiteko. Horren barruan sartzen dira:</p><p>Zuzeneko Zorra baimentzea: Baimena ematen diozu eyesCloud3D-ri zure banku-kontuko funtsak zorduntzeko, gure zerbitzuekin lotutako ordainketak burutzeko emandako jarraibideen arabera.</p><p>Transakzio errepikariak egiteko baimena: Aitortzen eta onartzen duzu baimen hau transakzio bakar eta errepikariei aplikatuko zaiela, gure zerbitzuak etengabe erabiltzeko beharrezkoa denaren arabera.</p><p>Karguak eta tarifak: Zure kontuan baimendutako ordainketak estaltzeko behar adina funts daudela ziurtatzeaz arduratzen zara.Gainera, ados zaude baimen honen arabera egindako ordainketekin lotutako edozein tarifa edo kargu ordaintzearekin.</p><p>Aldaketak eta ezeztapenak: Baimen hau edozein unetan aldatzeko edo ezeztatzeko eskubidea duzu, aplikatu beharreko legeek eta araudiek baimentzen dutenaren arabera.Horretarako, jar zaitez harremanetan gure laguntza-taldearekin.</p><p>Aldaketen jakinarazpena: Baimen honen baldintzetan edo ordainketak prozesatzeko moduan aldaketak egiten badira, aldez aurretik jakinaraziko zaizu, aplikatu beharreko legeen arabera.</p>',
    cat: '<p>En introduir el número de compte bancari SEPA (Single Euro Payments Area) en el nostre servei, estàs atorgant el teu consentiment exprés i autoritzant eyesCloud3D perquè faci els pagaments necessaris en virtut dels serveis que ofereix. Aquesta autorització comprèn:</p><p>Autorització de dèbit directe: Autoritzes eyesCloud3D a cobrar fons del teu compte bancari d\'acord amb les instruccions proporcionades per tal de completar pagaments relacionats amb els nostres serveis.</p><p>Consentiment per a transaccions recurrents: Reconeixes i acceptes que aquesta autorització s\'aplicarà a transaccions úniques i recurrents, segons escaigui per a l\'ús continuat dels nostres serveis.</p><p>Càrrecs i tarifes: Ets responsable de garantir que hi hagi fons suficients en el compte per cobrir els pagaments autoritzats. A més, acceptes pagar qualsevol tarifa o càrrec associat amb els pagaments efectuats en virtut d\'aquesta autorització.</p><p>Modificacions i cancel·lacions: Tens el dret de modificar o cancel·lar aquesta autorització en qualsevol moment, segons ho permeten les lleis i regulacions aplicables. Per a fer-ho, posa\'t en contacte amb el nostre equip de suport.</p><p>Notificació de canvis: Si es fan canvis en els termes d\'aquesta autorització o en la forma en què es processen els pagaments, rebràs una notificació amb antelació d\'acord amb les lleis aplicables.</p>',
  },

  myRates_data69: {
    es: '<p>Personaliza según tus necesidades la %X%</p>',
    en: '<p>Customize %X% to fit your needs</p>',
    pt: '<p>Personalize de acordo com as suas necessidades o %X%</p>',
    fr: '<p>Personnalisez selon vos besoins le %X%</p>',
    eus: '<p>Pertsonalizatu %X% tarifa zure beharren arabera</p>',
    cat: '<p>Personalitza la %X% segons les teves necessitats</p>',
  },

  myRates_data70: {
    es: '<p>Añade usuarios a tu tarifa (%X%€/usuario):</p>',
    en: '<p>Add users to your plan (€%X% per user):</p>',
    pt: '<p>Adicione utilizadores à sua tarifa (%X%€/utilizador):</p>',
    fr: '<p>Ajoutez des utilisateurs à votre tarif (%X%€/utilisateur) :</p>',
    eus: '<p>Gehitu erabiltzaileak zure tarifari (%X% €/erabiltzaile bakoitzeko):</p>',
    cat: '<p>Afegeix usuaris a la teva tarifa (%X% €/usuari):</p>',
  },

  myRates_data71: {
    es: '<p>El número de usuarios que selecciones se añadirá a los  incluidos en tu tarifa actual. Este precio afectará al precio  de tu tarifa</p>',
    en: '<p>The number of users you select will be added to those already included in your current plan. This change will affect the price of your plan</p>',
    pt: '<p>O número de utilizadores selecionado será adicionado aos incluídos na sua tarifa atual. Esta alteração afetará o preço da sua tarifa</p>',
    fr: '<p>Le nombre d’utilisateurs sélectionné sera ajouté à ceux qui sont inclus dans votre tarif actuel. Cette modification affectera le prix de votre tarif.</p>',
    eus: '<p>Hautatu dituzun erabiltzaile kopurua egungo tarifan dituzun horiei gehituko zaie. Aldaketa honek zure tarifaren prezioa aldatuko du</p>',
    cat: '<p>El nombre d\'usuaris que seleccions s\'afegirà als que ja estan inclosos en la tarifa actual. Aquest canvi afectarà el preu de la tarifa</p>',
  },

  myRates_data72: {
    es: '<p>Mejorar mi tarifa</p>',
    en: '<p>Improve my plan</p>',
    pt: '<p>Melhorar a minha tarifa</p>',
    fr: '<p>Mettre à niveau mon tarif</p>',
    eus: '<p>Hobetu nire tarifa</p>',
    cat: '<p>Millora la meva tarifa</p>',
  },

  myRates_data73: {
    es: '<p>Acepto los términos y condiciones</p>',
    en: '<p>I accept the terms and conditions</p>',
    pt: '<p>Aceito os termos e condições</p>',
    fr: '<p>J’accepte les conditions d’utilisation</p>',
    eus: '<p>Terminoak eta baldintzak onartzen ditut</p>',
    cat: '<p>Accepto els termes i les condicions</p>',
  },

  myRates_data74: {
    es: '<p>FREE</p>',
    en: '<p>FREE</p>',
    pt: '<p>FREE</p>',
    fr: '<p>FREE</p>',
    eus: '<p>FREE</p>',
    cat: '<p>FREE</p>',
  },

  myRates_data75: {
    es: '<p>DESCARGA</p>',
    en: '<p>DOWNLOAD</p>',
    pt: '<p>TRANSFERÊNCIA</p>',
    fr: '<p>TÉLÉCHARGEMENT</p>',
    eus: '<p>DESKARGATU</p>',
    cat: '<p>BAIXADA</p>',
  },

  myRates_data76: {
    es: '<p>DE MODELOS</p>',
    en: '<p>MODELS</p>',
    pt: '<p>DE MODELOS</p>',
    fr: '<p>DE MODÈLES</p>',
    eus: '<p>EREDUAK</p>',
    cat: '<p>DE MODELS</p>',
  },

  myRates_data77: {
    es: '<p>IMPORTACIÓN</p>',
    en: '<p>IMPORT</p>',
    pt: '<p>IMPORTAÇÃO</p>',
    fr: '<p>IMPORTATION</p>',
    eus: '<p>INPORTATU</p>',
    cat: '<p>IMPORTACIÓ</p>',
  },

  myRates_data78: {
    es: '<p>HERRAMIENTAS ESTÁNDAR +</p>',
    en: '<p>STANDARD +</p>',
    pt: '<p>FERRAMENTAS PADRÃO +</p>',
    fr: '<p>OUTILS STANDARD +</p>',
    eus: '<p>TRESNA ESTANDARRAK +</p>',
    cat: '<p>EINES ESTÀNDARD +</p>',
  },

  myRates_data79: {
    es: '<p>AVANZADAS</p>',
    en: '<p>ADVANCED TOOLS</p>',
    pt: '<p>AVANÇADAS</p>',
    fr: '<p>AVANCÉS</p>',
    eus: '<p>AURRERATUAK</p>',
    cat: '<p>AVANÇADES</p>',
  },

  myRates_data80: {
    es: '<p>ACCESO A TODAS LAS HERRAMIENTAS</p>',
    en: '<p>ACCESS TO ALL TOOLS</p>',
    pt: '<p>ACESSO A TODAS AS FERRAMENTAS</p>',
    fr: '<p>ACCÈS À TOUS LES OUTILS</p>',
    eus: '<p>ERABILI TRESNA GUZTIAK</p>',
    cat: '<p>ACCÉS A TOTES LES EINES</p>',
  },

  myRates_data81: {
    es: '<p>PRO</p>',
    en: '<p>PRO</p>',
    pt: '<p>PRO</p>',
    fr: '<p>PRO</p>',
    eus: '<p>PRO</p>',
    cat: '<p>PRO</p>',
  },

  myRates_data82: {
    es: '<p>PRO +</p>',
    en: '<p>PRO +</p>',
    pt: '<p>PRO +</p>',
    fr: '<p>PRO +</p>',
    eus: '<p>PRO +</p>',
    cat: '<p>PRO +</p>',
  },

  myRates_data83: {
    es: '<p>HERRAMIENTAS AVANZADAS +</p>',
    en: '<p>ADVANCED TOOLS +</p>',
    pt: '<p>FERRAMENTAS AVANÇADAS +</p>',
    fr: '<p>OUTILS AVANCÉS +</p>',
    eus: '<p>TRESNA AURRERATUAK +</p>',
    cat: '<p>EINES AVANÇADES +</p>',
  },

  myRates_data84: {
    es: '<p>SEGURIDAD</p>',
    en: '<p>SAFETY</p>',
    pt: '<p>SEGURANÇA</p>',
    fr: '<p>SÉCURITÉ</p>',
    eus: '<p>SEGURTASUNA</p>',
    cat: '<p>SEGURETAT</p>',
  },

  myRates_data85: {
    es: '<p>INGENIERÍA</p>',
    en: '<p>ENGINEERING</p>',
    pt: '<p>ENGENHARIA</p>',
    fr: '<p>INGÉNIERIE</p>',
    eus: '<p>INGENIARITZA</p>',
    cat: '<p>ENGINYERIA</p>',
  },

  myRates_data86: {
    es: '<p>AGRICULTURA</p>',
    en: '<p>AGRICULTURE</p>',
    pt: '<p>AGRICULTURA</p>',
    fr: '<p>AGRICULTURE</p>',
    eus: '<p>NEKAZARITZA</p>',
    cat: '<p>AGRICULTURA</p>',
  },

  myRates_data87: {
    es: '<p>BUSINESS</p>',
    en: '<p>BUSINESS</p>',
    pt: '<p>BUSINESS</p>',
    fr: '<p>BUSINESS</p>',
    eus: '<p>BUSINESS</p>',
    cat: '<p>BUSINESS</p>',
  },

  myRates_data88: {
    es: '<p>BUSINESS +</p>',
    en: '<p>BUSINESS +</p>',
    pt: '<p>BUSINESS +</p>',
    fr: '<p>BUSINESS +</p>',
    eus: '<p>BUSINESS +</p>',
    cat: '<p>BUSINESS +</p>',
  },

  myRates_data89: {
    es: '<p>elige el nº de descargas que quieras</p>',
    en: '<p>choose the number of downloads you want</p>',
    pt: '<p>escolha o n.º de transferências que pretenda</p>',
    fr: '<p>choisissez le nombre de téléchargements souhaités</p>',
    eus: '<p>nahi duzun deskarga kopurua aukeratu</p>',
    cat: '<p>tria el nre. de baixades que vulguis</p>',
  },

  myRates_data90: {
    es: '<p>elige el nº de</p><p>importaciones que quieras</p>',
    en: '<p>choose the number of</p><p>imports you want</p>',
    pt: '<p>escolha o n.º de</p><p>importações que pretenda</p>',
    fr: '<p>choisissez le nombre</p><p>d’importations souhaitées</p>',
    eus: '<p>nahi duzun</p><p>inportazio kopurua aukeratu</p>',
    cat: '<p>tria el nre.</p><p>d’importacions que vulguis</p>',
  },

  myRates_data91: {
    es: '<p>MEJORAR TARIFA</p>',
    en: '<p>IMPROVE PLAN</p>',
    pt: '<p>MELHORAR TARIFA</p>',
    fr: '<p>METTRE À NIVEAU LE TARIF</p>',
    eus: '<p>HOBETU TARIFA</p>',
    cat: '<p>MILLORA LA TARIFA</p>',
  },

  myRates_data92: {
    es: '<p>Free</p>',
    en: '<p>Free</p>',
    pt: '<p>Free</p>',
    fr: '<p>Free</p>',
    eus: '<p>Free</p>',
    cat: '<p>Free</p>',
  },

  myRates_data93: {
    es: '<p>Pro</p>',
    en: '<p>Pro</p>',
    pt: '<p>Pro</p>',
    fr: '<p>Pro</p>',
    eus: '<p>Pro</p>',
    cat: '<p>Pro</p>',
  },

  myRates_data94: {
    es: '<p>Business</p>',
    en: '<p>Business</p>',
    pt: '<p>Business</p>',
    fr: '<p>Business</p>',
    eus: '<p>Business</p>',
    cat: '<p>Business</p>',
  },

  myRates_data95: {
    es: '<p>Pro Ingeniería</p>',
    en: '<p>Engineering Pro</p>',
    pt: '<p>Pro Engenharia</p>',
    fr: '<p>Pro Ingénierie</p>',
    eus: '<p>Ingeniaritza Pro</p>',
    cat: '<p>Pro Enginyeria</p>',
  },

  myRates_data96: {
    es: '<p>Business Ingeniería</p>',
    en: '<p>Engineering Business</p>',
    pt: '<p>Business Engenharia</p>',
    fr: '<p>Business Ingénierie</p>',
    eus: '<p>Business Ingeniaritza</p>',
    cat: '<p>Business Enginyeria</p>',
  },

  myRates_data97: {
    es: '<p>Pro Seguridad</p>',
    en: '<p>Safety Pro</p>',
    pt: '<p>Pro Segurança</p>',
    fr: '<p>Pro Sécurité</p>',
    eus: '<p>Segurtasuna Pro</p>',
    cat: '<p>Pro Seguretat</p>',
  },

  myRates_data98: {
    es: '<p>Business Seguridad</p>',
    en: '<p>Safety Business</p>',
    pt: '<p>Business Segurança</p>',
    fr: '<p>Business Sécurité</p>',
    eus: '<p>Business Segurtasuna</p>',
    cat: '<p>Business Seguretat</p>',
  },

  myRates_data99: {
    es: '<p>Pro Agricultura</p>',
    en: '<p>Agriculture Pro</p>',
    pt: '<p>Pro Agricultura</p>',
    fr: '<p>Pro Agriculture</p>',
    eus: '<p>Nekazaritza Pro</p>',
    cat: '<p>Pro Agricultura</p>',
  },

  myRates_data100: {
    es: '<p>Business Agricultura</p>',
    en: '<p>Agriculture Business</p>',
    pt: '<p>Business Agricultura</p>',
    fr: '<p>Business Agriculture</p>',
    eus: '<p>Business Nekazaritza</p>',
    cat: '<p>Business Agricultura</p>',
  },

  myRates_data101: {
    es: '<p>Pro +</p>',
    en: '<p>Pro +</p>',
    pt: '<p>Pro +</p>',
    fr: '<p>Pro +</p>',
    eus: '<p>Pro +</p>',
    cat: '<p>Pro +</p>',
  },

  myRates_data102: {
    es: '<p>Business +</p>',
    en: '<p>Business +</p>',
    pt: '<p>Business +</p>',
    fr: '<p>Business +</p>',
    eus: '<p>Business +</p>',
    cat: '<p>Business +</p>',
  },

  myRates_data103: {
    es: '<p>Descarga Extra</p>',
    en: '<p>Extra Download</p>',
    pt: '<p>Transferência Extra</p>',
    fr: '<p>Téléchargement supplémentaire</p>',
    eus: '<p>Deskarga gehigarria</p>',
    cat: '<p>Baixada extra</p>',
  },

  myRates_data104: {
    es: '<p>Importación Extra</p>',
    en: '<p>Extra Import</p>',
    pt: '<p>Importação Extra</p>',
    fr: '<p>Importation supplémentaire</p>',
    eus: '<p>Inportazio gehigarria</p>',
    cat: '<p>Importació extra</p>',
  },

  myRates_data105: {
    es: '<p>Usuario Extra</p>',
    en: '<p>Extra User</p>',
    pt: '<p>Utilizador Extra</p>',
    fr: '<p>Utilisateur supplémentaire</p>',
    eus: '<p>Erabiltzaile gehigarria</p>',
    cat: '<p>Usuari extra</p>',
  },

  myRates_data106: {
    es: '<p>Mejorar</p>',
    en: '<p>Improve</p>',
    pt: '<p>Melhorar</p>',
    fr: '<p>Mettre à niveau</p>',
    eus: '<p>Hobetu</p>',
    cat: '<p>Millora</p>',
  },

  myRates_data107: {
    es: '<p><span class="i18n-bold">Límites de subida: </span>Hasta %X% fotos/ %X% min de vídeo</p>',
    en: '<p><span class="i18n-bold">Upload limit: </span>Up to %X% photos/%X% min of video</p>',
    pt: '<p><span class="i18n-bold">Limites de registo: </span>Até %X% fotografias/ %X% min de vídeo</p>',
    fr: '<p><span class="i18n-bold">Limites de téléchargement : </span>Jusqu\'à %X% photos/ vidéo de %X% min</p>',
    eus: '<p><span class="i18n-bold">Karga-muga: </span>gehienez %X% argazki / %X% minutu bideo</p>',
    cat: '<p><span class="i18n-bold">Límits de càrrega: </span>fins a %X% fotos/%X% min de vídeo</p>',
  },

  myRates_data108: {
    es: '<p>TOTAL A PAGAR:</p>',
    en: '<p>TOTAL AMOUNT TO PAY:</p>',
    pt: '<p>TOTAL A PAGAR:</p>',
    fr: '<p>TOTAL À PAYER :</p>',
    eus: '<p>GUZTIRA ORDAINDU BEHARREKOA:</p>',
    cat: '<p>TOTAL PER PAGAR:</p>',
  },

  myRates_data109: {
    es: '<p>ESTANDAR</p>',
    en: '<p>STANDARD</p>',
    pt: '<p>PADRÃO</p>',
    fr: '<p>STANDARD</p>',
    eus: '<p>ESTANDARRAK</p>',
    cat: '<p>ESTÀNDARD</p>',
  },

  myRates_data110: {
    es: '<p>AVANZADAS</p>',
    en: '<p>ADVANCED</p>',
    pt: '<p>AVANÇADAS</p>',
    fr: '<p>AVANCÉS</p>',
    eus: '<p>AURRERATUAK</p>',
    cat: '<p>AVANÇADES</p>',
  },

  myRates_data111: {
    es: '<p>SEGURIDAD</p>',
    en: '<p>SAFETY</p>',
    pt: '<p>SEGURANÇA</p>',
    fr: '<p>SÉCURITÉ</p>',
    eus: '<p>SEGURTASUNA</p>',
    cat: '<p>SEGURETAT</p>',
  },

  myRates_data112: {
    es: '<p>INGENIERÍA</p>',
    en: '<p>ENGINEERING</p>',
    pt: '<p>ENGENHARIA</p>',
    fr: '<p>INGÉNIERIE</p>',
    eus: '<p>INGENIARITZA</p>',
    cat: '<p>ENGINYERIA</p>',
  },

  myRates_data113: {
    es: '<p>AGRICULTURA</p>',
    en: '<p>AGRICULTURE</p>',
    pt: '<p>AGRICULTURA</p>',
    fr: '<p>AGRICULTURE</p>',
    eus: '<p>NEKAZARITZA</p>',
    cat: '<p>AGRICULTURA</p>',
  },

  myRates_data114: {
    es: '<p><span class="i18n-bold">Herramientas:</span> %X%</p>',
    en: '<p><span class="i18n-bold">Tools:</span> %X%</p>',
    pt: '<p><span class="i18n-bold">Ferramentas:</span> %X%</p>',
    fr: '<p><span class="i18n-bold">Outils :</span> %X%</p>',
    eus: '<p><span class="i18n-bold">Tresnak:</span> %X%</p>',
    cat: '<p><span class="i18n-bold">Eines:</span> %X%</p>',
  },

  myRates_data115: {
    es: '<p>ID DE PAGO</p>',
    en: '<p>PAYMENT ID</p>',
    pt: '<p>ID DE PAGAMENTO</p>',
    fr: '<p>IDENTIFIANT DE PAIEMENT</p>',
    eus: '<p>ORDAINKETA-IDENTIFIKATZAILEA</p>',
    cat: '<p>ID DE PAGAMENT</p>',
  },

  myRates_data116: {
    es: '<p>Tarifa contratada:</p>',
    en: '<p>Current plan:</p>',
    pt: '<p>Tarifa contratada:</p>',
    fr: '<p>Tarif choisi :</p>',
    eus: '<p>Kontratatutako tarifa:</p>',
    cat: '<p>Tarifa contractada:</p>',
  },

  myRates_data117: {
    es: '<p>Fecha de compra:</p>',
    en: '<p>Purchase date:</p>',
    pt: '<p>Data de compra:</p>',
    fr: '<p>Date d’achat :</p>',
    eus: '<p>Erosketa-data:</p>',
    cat: '<p>Data de compra:</p>',
  },

  myRates_data118: {
    es: '<p>Precio de compra:</p>',
    en: '<p>Purchase price:</p>',
    pt: '<p>Preço de compra:</p>',
    fr: '<p>Prix d’achat :</p>',
    eus: '<p>Erosketa-prezioa:</p>',
    cat: '<p>Preu de compra:</p>',
  },

  myRates_data119: {
    es: '<p>Descargar</p>',
    en: '<p>Download</p>',
    pt: '<p>Transferir</p>',
    fr: '<p>Télécharger</p>',
    eus: '<p>Deskargatu</p>',
    cat: '<p>Baixa</p>',
  },

  myRates_data120: {
    es: '<p>Reactivar suscripción</p>',
    en: '<p>Reactivate subscription</p>',
    pt: '<p>Reativar subscrição</p>',
    fr: '<p>Réactiver l’abonnement</p>',
    eus: '<p>Berraktibatu harpidetza</p>',
    cat: '<p>Reactiva la subscripció</p>',
  },

  myRates_data121: {
    es: '<p>Activa hasta el %X%</p>',
    en: '<p>Active until %X%</p>',
    pt: '<p>Ativa até %X%</p>',
    fr: '<p>Actif jusqu’au %X%</p>',
    eus: '<p>%X% arte aktibatuta</p>',
    cat: '<p>Activa fins al dia %X%</p>',
  },

  myRates_data122: {
    es: '<p>€/mes</p>',
    en: '<p>€ per month</p>',
    pt: '<p>€/mês</p>',
    fr: '<p>€/mois</p>',
    eus: '<p>€/hileko</p>',
    cat: '<p>€/mes</p>',
  },

  myRates_data123: {
    es: '<p>Confirmar compra</p>',
    en: '<p>Confirm payment</p>',
    pt: '<p>Confirmar compra</p>',
    fr: '<p>Confirmer l\'achat</p>',
    eus: '<p>Berretsi erosketa</p>',
    cat: '<p>Confirma la compra</p>',
  },

  myRates_data124: {
    es: '<p>Importaciones:</p>',
    en: '<p>Imports:</p>',
    pt: '<p>Importações:</p>',
    fr: '<p>Importations :</p>',
    eus: '<p>Inportazioak:</p>',
    cat: '<p>Importacions:</p>',
  },

  myRates_data125: {
    es: '<p><span class="i18n-bold">Descargas: </span>elige el nº de descargas que quieras</p>',
    en: '<p><span class="i18n-bold">Downloads: </span>choose the number of downloads you want</p>',
    pt: '<p><span class="i18n-bold">Transferências: </span>escolha o n.º de transferências que pretenda</p>',
    fr: '<p><span class="i18n-bold">Téléchargements : </span>choisissez le nombre de téléchargements souhaités</p>',
    eus: '<p><span class="i18n-bold">Deskargak: </span>nahi duzun deskarga kopurua aukeratu</p>',
    cat: '<p><span class="i18n-bold">Baixades: </span>tria el nre. de baixades que vulguis</p>',
  },

  myRates_data126: {
    es: '<p><span class="i18n-bold">Importaciones: </span>elige el nº de importaciones que quieras</p>',
    en: '<p><span class="i18n-bold">Imports: </span>choose the number of imports you want</p>',
    pt: '<p><span class="i18n-bold">Importações: </span>escolha o n.º de importações que pretenda</p>',
    fr: '<p><span class="i18n-bold">Importations : </span>choisissez le nombre</p><p>d’importations souhaitées</p>',
    eus: '<p><span class="i18n-bold">Inportazioak: </span>nahi duzun inportazio kopurua aukeratu</p>',
    cat: '<p><span class="i18n-bold">Importacions: </span>tria el nre. d’importacions que vulguis</p>',
  },

  myRates_data127: {
    es: '<p>(%X%€ anualmente)</p>',
    en: '<p>(€%X% annually)</p>',
    pt: '<p>(%X%€ anualmente)</p>',
    fr: '<p>(%X%€ annuellement)</p>',
    eus: '<p>(%X%€ urteko)</p>',
    cat: '<p>(%X% € anualment)</p>',
  },

  myRates_data128: {
    es: '<p>€/mes</p>',
    en: '<p>€ per month</p>',
    pt: '<p>€/mês</p>',
    fr: '<p>€/mois</p>',
    eus: '<p>€/hilero</p>',
    cat: '<p>€/mes</p>',
  },

  myRates_data129: {
    es: '<p>Añadir otro método de pago</p>',
    en: '<p>Add another payment method</p>',
    pt: '<p>Adicionar outro método de pagamento</p>',
    fr: '<p>Ajouter un autre mode de paiement</p>',
    eus: '<p>Gehitu beste ordainketa metodo bat</p>',
    cat: '<p>Afegeix un altre mètode de pagament</p>',
  },

  myRates_data130: {
    es: '<p>Nombre dirección</p>',
    en: '<p>Address name</p>',
    pt: '<p>Nome endereço</p>',
    fr: '<p>Nom adresse</p>',
    eus: '<p>Helbidearen izena</p>',
    cat: '<p>Nom adreça</p>',
  },

  myRates_data131: {
    es: '<p>GESTIONAR TARIFA</p>',
    en: '<p>MANAGE PLAN</p>',
    pt: '<p>GERIR TARIFA</p>',
    fr: '<p>GÉRER TARIF</p>',
    eus: '<p>KUDEATU TARIFA</p>',
    cat: '<p>GESTIONA LA TARIFA</p>',
  },

  myRates_data132: {
    es: '<p>GENERAR NUEVO MODELO 3D</p>',
    en: '<p>GENERATE NEW 3D MODEL</p>',
    pt: '<p>CRIAR NOVO MODELO 3D</p>',
    fr: '<p>GÉNÉRER UN NOUVEAU MODÈLE 3D</p>',
    eus: '<p>SORTU 3D EREDU BERRIA</p>',
    cat: '<p>GENERA UN NOU MODEL 3D</p>',
  },

  myRates_data133: {
    es: '<p>ADDONS</p>',
    en: '<p>ADDONS</p>',
    pt: '<p>ADDONS</p>',
    fr: '<p>ADDONS</p>',
    eus: '<p>ADDON-AK</p>',
    cat: '<p>ADDONS</p>',
  },

  myRates_data134: {
    es: '<p>Modificar el método de pago</p>',
    en: '<p>Modify payment method</p>',
    pt: '<p>Modificar o método de pagamento</p>',
    fr: '<p>Modifier le mode de paiement</p>',
    eus: '<p>Aldatu ordainketa metodoa</p>',
    cat: '<p>Modifica el mètode de pagament</p>',
  },

  myRates_data135: {
    es: '<p>Añadir un nuevo método de pago</p>',
    en: '<p>Add a new payment method</p>',
    pt: '<p>Acrescentar um novo método de pagamento</p>',
    fr: '<p>Ajouter un nouveau mode de paiement</p>',
    eus: '<p>Gehitu ordainketa metodo berria</p>',
    cat: '<p>Afegeix un nou mètode de pagament</p>',
  },

  myRates_data136: {
    es: '<p>*Rellena todos los campos correctamente para poder continuar</p>',
    en: '<p>*All fields must be filled in correctly before you can continue</p>',
    pt: '<p>*Preencha todos os campos corretamente para poder continuar</p>',
    fr: '<p>*Veuillez remplir correctement tous les champs pour continuer</p>',
    eus: '<p>*Bete itzazu behar bezala eremu guztiak jarraitu ahal izateko</p>',
    cat: '<p>* Emplena tots els camps correctament per poder continuar</p>',
  },

  myRates_data137: {
    es: '<p>GUARDAR</p>',
    en: '<p>SAVE</p>',
    pt: '<p>GUARDAR</p>',
    fr: '<p>SAUVEGARDER</p>',
    eus: '<p>GORDE</p>',
    cat: '<p>DESA</p>',
  },

  myRates_data138: {
    es: '<p>Tu método de pago ha sido añadido correctamente.</p>',
    en: '<p>Your payment method has been added correctly.</p>',
    pt: '<p>O seu método de pagamento foi adicionado corretamente.</p>',
    fr: '<p>Votre mode de paiement a été ajouté correctement.</p>',
    eus: '<p>Zure ordainketa metodoa behar bezala gehitu da.</p>',
    cat: '<p>El mètode de pagament s’ha afegit correctament.</p>',
  },

  myRates_data139: {
    es: '<p>Tu método de pago no se ha podido añadir.</p>',
    en: '<p>Your payment method could not be added.</p>',
    pt: '<p>Não foi possível adicionar o seu método de pagamento.</p>',
    fr: '<p>Votre mode de paiement n’a pas pu être ajouté.</p>',
    eus: '<p>Zure ordainketa metodoa ezin izan da gehitu.</p>',
    cat: '<p>No s’ha pogut afegir el mètode de pagament.</p>',
  },

  myRates_data140: {
    es: '<p>Por favor, comprueba tus datos, contacta con tu entidad bancaria o inténtalo de nuevo más tarde.</p>',
    en: '<p>Please check your information, contact your bank, or try again later.</p>',
    pt: '<p>Verifique os seus dados, contacte a sua entidade bancária ou tente novamente mais tarde.</p>',
    fr: '<p>Vérifiez les informations saisies, contactez votre banque ou réessayez plus tard.</p>',
    eus: '<p>Mesedez, egiaztatu zure datuak, jarri harremanetan zure bankuarekin edo saiatu berriro geroago.</p>',
    cat: '<p>Comprova les dades, posa’t en contacte amb l\'entitat bancària o torna a provar-ho més tard.</p>',
  },

  myRates_data141: {
    es: '<p>€/modelo</p>',
    en: '<p>€ per model</p>',
    pt: '<p>€/modelo</p>',
    fr: '<p>€/modèle</p>',
    eus: '<p>€/eredu bakoitzeko</p>',
    cat: '<p>€/model</p>',
  },

  myRates_data142: {
    es: '<p>MWC24</p>',
    en: '<p>MWC24</p>',
    pt: '<p>MWC24</p>',
    fr: '<p>MWC24</p>',
    eus: '<p>MWC24</p>',
    cat: '<p>MWC24</p>',
  },

  myRates_data143: {
    es: '<p>SICUR 2024</p>',
    en: '<p>SICUR 2024</p>',
    pt: '<p>SICUR 2024</p>',
    fr: '<p>SICUR 2024</p>',
    eus: '<p>SICUR 2024</p>',
    cat: '<p>SICUR 2024</p>',
  },

  myRates_data144: {
    es: '<p>Tarifa Trial</p>',
    en: '<p>Trial Plan</p>',
    pt: '<p>Tarifa Trial</p>',
    fr: '<p>Abonnement d’essai</p>',
    eus: '<p>Trial Tarifa</p>',
    cat: '<p>Tarifa Trial</p>',
  },

  myRates_data145: {
    es: '<p>ALMACENAMIENTO</p>',
    en: '<p>GALLERY</p>',
    pt: '<p>ARMAZENAMENTO</p>',
    fr: '<p>CAPACITÉ DE STOCKAGE</p>',
    eus: '<p>GALERIAKO</p>',
    cat: '<p>EMMAGATZEMATGE</p>',
  },
  myRates_data146: {
    es: '<p>DE LA GALERÍA</p>',
    en: '<p>STORAGE</p>',
    pt: '<p>DA GALERIA</p>',
    fr: '<p>DE LA GALERIE</p>',
    eus: '<p>BILTEGIRATZEA</p>',
    cat: '<p>DE LA GALERIA</p>',
  },
  myRates_data147: {
    es: '<p><span class="i18n-bold">Incluye: (+%X% Modelos) </span>en tu galería al mes</p>',
    en: '<p><span class="i18n-bold">Includes: (+%X% Models) </span>in your gallery per month</p>',
    pt: '<p><span class="i18n-bold">Inclui: (+%X% Modelos) </span>na sua galeria por mês</p>',
    fr: '<p><span class="i18n-bold">Inclus : (+%X% modèles) </span>dans votre galerie par mois</p>',
    eus: '<p><span class="i18n-bold">Harpidetza honekin: (+%X% eredu) </span>zure galerian hilero</p>',
    cat: '<p><span class="i18n-bold">Inclou: (+%X% models) </span>en la galeria al mes</p>',
  },
  myRates_data148: {
    es: '<p>Servicios Adicionales</p>',
    en: '<p>Additional Services</p>',
    pt: '<p>Serviços adicionais</p>',
    fr: '<p>Services supplémentaires</p>',
    eus: '<p>Zerbitzu gehigarriak</p>',
    cat: '<p>Serveis addicionals</p>',
  },
  myRates_data149: {
    es: '<p>Esta, y todas tus próximas facturas estarán</p><p>siempre a tu disposición en tu área personal</p>',
    en: '<p>This and all your future invoices will</p><p>always be available in your personal area</p>',
    pt: '<p>Esta, e todas as suas próximas faturas, estarão</p><p>sempre à sua disposição na sua área pessoal</p>',
    fr: '<p>Cette facture, ainsi que les suivantes, seront </p><p> toujours à votre disposition dans votre espace personnel</p>',
    eus: '<p>Hau, eta hurrengo faktura guztiak zure</p><p>eremu pertsonalean eskura izango dituzu beti</p>',
    cat: '<p>Aquesta factura, i totes les vinents, estaran</p><p>sempre a la teva disposició a l’àrea personal</p>',
  },
  myRates_data151: {
    es: '<p>GENERAR MODELO 3D</p>',
    en: '<p>GENERATE 3D</p>',
    pt: '<p>CRIAR MODELO 3D</p>',
    fr: '<p>GÉNÉRER MODÈLE 3D</p>',
    eus: '<p>SORTU 3D EREDUA</p>',
    cat: '<p>GENERA MODEL 3D</p>',
  },
  myRates_data154: {
    es: '<p>IR A PANEL DE CONTROL</p>',
    en: '<p>GO TO CONTROL PANEL</p>',
    pt: '<p>IR PARA PAINEL DE CONTROLO</p>',
    fr: '<p>ALLER AU TABLEAU DE BORD</p>',
    eus: '<p>JOAN KONTROL PANELERA</p>',
    cat: '<p>VES AL TAULER DE CONTROL</p>',
  },
  myRates_data155: {
    es: '<p>Procediendo con la confirmación del pago, estarás otorgando tu autorización a E-Capture</p><p>Research and Development S.L., la empresa propietaria de eyesCloud3D...</p>',
    en: '<p>By confirming your payment, you authorize E-Capture</p><p>Research and Development S.L., the company that owns eyesCloud3D...</p>',
    pt: '<p>Ao proceder à confirmação do pagamento, estará a dar autorização à E-Capture</p><p>Research and Development S.L., a empresa proprietária da eyesCloud3D...</p>',
    fr: '<p>En validant le paiement, vous autorisez E-Capture </p><p> Research and Development S.L., l\'entreprise propriétaire d\'eyesCloud3D...</p>',
    eus: '<p>Ordainketa berrestean, zure baimena ematen diozu E-Capture Research and</p><p>Development S.L.-ri, eyesCloud3D-ren jabe den enpresari...</p>',
    cat: '<p>Si continues amb la confirmació del pagament, estaràs atorgant la teva autorització a E-Capture</p><p>Research and Development S.L., l\'empresa propietària d’eyesCloud3D…</p>',
  },
  myRates_data156: {
    es: '<p>+ información</p>',
    en: '<p>+ información</p>',
    pt: '<p>+ informação</p>',
    fr: '<p>Plus d’infos</p>',
    eus: '<p>informazio +</p>',
    cat: '<p>+ informació</p>',
  },
  myRates_data157: {
    es: '<p>Procediendo con la confirmación del pago, estarás otorgando tu autorización a E-Capture Research...</p>',
    en: '<p>By confirming your payment, you authorize E-Capture Research...</p>',
    pt: '<p>Ao proceder à confirmação do pagamento, estará a dar autorização à E-Capture Research...</p>',
    fr: '<p>En validant le paiement, vous autorisez E-Capture Research...</p>',
    eus: '<p>Ordainketa berrestean, zure baimena ematen diozu E-Capture Research...</p>',
    cat: '<p>Si continues amb la confirmació del pagament, estaràs atorgant la teva autorització a E-Capture Research…</p>',
  },
  myRates_data158: {
    es: '<p>Procediendo con la confirmación del pago, estarás otorgando tu autorización a E-Capture Research and Development S.L., la empresa propietaria de eyesCloud3D, para llevar a cabo tanto el presente cobro como los que puedan surgir en el futuro utilizando el método de pago que hayas seleccionado e ingresado previamente. Al brindar esta autorización, permites que la entidad mencionada efectúe transacciones financieras en tu nombre y en relación con los servicios proporcionados por eyesCloud3D. Es importante tener en cuenta que estás aceptando explícitamente la continuidad de este acuerdo de autorización para facilitar transacciones futuras. Si deseas más información puedes obtenerla en nuestros Términos de Uso o Condiciones Generales de Contratación. También puedes ponerte en contacto con nosotros en customercare@eyescloud3d.com</p>',
    en: '<p>By confirming your payment, you authorize E-Capture Research and Development S.L., the company that owns eyesCloud3D, to carry out both the current charge and any future charges using the payment method you have selected and provided. By granting this authorization, you allow the aforementioned company to carry out financial transactions on your behalf in connection with the services provided by eyesCloud3D. It’s important to note that you are explicitly accepting the continuation of this authorization agreement to facilitate future transactions. For more information, please see our Terms of Use and General Terms and Conditions. You can also contact us at customercare@eyescloud3d.com</p>',
    pt: '<p>Ao proceder à confirmação do pagamento, estará a dar autorização à E-Capture Research and Development S.L., a empresa proprietária da eyesCloud3D, para efetuar a presente cobrança e as que possam surgir no futuro, utilizando o método de pagamento previamente selecionado e introduzido por si. Ao dar esta autorização, permite que a entidade acima mencionada efetue transações financeiras em seu nome e relacionadas com os serviços prestados pela eyesCloud3D. É importante ter em conta que aceita explicitamente a continuação deste acordo de autorização para facilitar futuras transações. Para mais informações, consulte os nossos Termos de Utilização ou Condições Gerais de Contratação. Pode também contactar-nos através do e-mail customercare@eyescloud3d.com</p>',
    fr: '<p>En validant le paiement, vous autorisez E-Capture Research and Development S.L., l\'entreprise propriétaire d\'eyesCloud3D, à encaisser le paiement actuel et les éventuels paiements futurs en utilisant le mode de paiement que vous avez préalablement sélectionné et saisi. En donnant votre autorisation, vous permettez à l\'entité susmentionnée d\'effectuer des transactions financières en votre nom et en lien avec les services fournis par eyesCloud3D. Il est important de noter que vous acceptez explicitement la continuité de cet accord afin de faciliter les transactions futures. Pour plus d’informations, veuillez consulter nos conditions générales d\'utilisation ou nos conditions générales de vente.Vous pouvez également nous contacter à l’adresse customercare@eyescloud3d.com</p>',
    eus: '<p>Ordainketa berrestean, zure baimena ematen diozu E-Capture Research and Development S.L.-ri, eyesCloud3D-ren jabe den enpresari, kobrantza hau zein etorkizunean sor daitezkeen kobrantzak egiteko, aurretik aukeratu eta sartu duzun ordainketa-metodoa erabiliz. Baimen hori ematean, aipatutako erakundeari eyesCloud3D-k emandako zerbitzuei dagokienez zure izenean finantza-transakzioak egiteko baimena ematen duzu. Garrantzitsua da kontuan izatea berariaz onartzen ari zarela baimen-akordio horren jarraitutasuna, etorkizuneko transakzioak erraztea baimentzen duena. Informazio gehiago nahi baduzu, gure Erabiltzeko baldintzak edo Kontratazioko baldintza orokorretan lor dezakezu. Nahi baduzu, gurekin harremanetan jar zaitezke customercare@eyescloud3d.com helbidean</p>',
    cat: '<p>Si continues amb la confirmació del pagament, estaràs atorgant la teva autorització a E-Capture Research and Development S.L., l\'empresa propietària d\'eyesCloud3D, per dur a terme el cobrament en curs i d’altres que puguin sorgir en el futur mitjançant el mètode de pagament que hagis triat i introduït prèviament.En proporcionar aquesta autorització, permets que l’esmentada entitat efectuï transaccions financeres en el teu nom i en relació amb els serveis que proporciona eyesCloud3D.Cal tenir en compte que estàs acceptant explícitament la continuïtat d’aquest acord d’autorització per facilitar futures transaccions.Si vols més informació, pots obtenir-la als Termes d\'ús o a les Condicions generals de contractació.També pots posar- te en contacte amb nosaltres a customercare@eyescloud3d.com</p>',
  },
  myRates_data159: {
    es: '<p>Cerrar</p>',
    en: '<p>Close</p>',
    pt: '<p>Fechar</p>',
    fr: '<p>Fermer</p>',
    eus: '<p>Itxi</p>',
    cat: '<p>Tanca</p>',
  },
  myRates_data160: {
    es: '<p>¿Qué es esto?</p>',
    en: '<p>What is this?</p>',
    pt: '<p>O que é isto?</p>',
    fr: '<p>Qu\'est-ce que le code CVV ou CVC?</p>',
    eus: '<p>Zer da hau?</p>',
    cat: '<p>Què és això?</p>',
  },
  myRates_data161: {
    es: '<p>El código CVV o CVC es un grupo de 3 o 4 números situado en el reverso de la tarjeta de crédito o débito.</p>',
    en: '<p>The CVV or CVC code is a group of 3 or 4 numbers located on the back of your credit or debit card.</p>',
    pt: '<p>O código CVV ou CVC é um grupo de 3 ou 4 dígitos situado no verso do cartão de crédito ou de débito.</p>',
    fr: '<p>Le code CVV ou CVC est un code à 3 ou 4 chiffres situé au dos de la carte de débit ou de crédit.</p>',
    eus: '<p>CVV edo CVC kodeak 3 edo 4 zenbaki ditu eta kreditu edo zordunketa txartelaren atzealdean dago.</p>',
    cat: '<p>El codi CVV o CVC és un grup de 3 o 4 xifres situat en la part posterior de la targeta de crèdit o dèbit.</p>',
  },
  myRates_data162: {
    es: '<p>Adicionales</p>',
    en: '<p>Additional</p>',
    pt: '<p>Adicionais</p>',
    fr: '<p>Supplémentaires</p>',
    eus: '<p>Gehigarriak</p>',
    cat: '<p>Addicionals</p>',
  },
  myRates_data163: {
    es: '<p>Almacenamiento de la galería</p>',
    en: '<p>Gallery storage</p>',
    pt: '<p>Armazenamento da galeria</p>',
    fr: '<p>Capacité de stockage de la galerie</p>',
    eus: '<p>Galeriako biltegiratzea</p>',
    cat: '<p>Emmagatzematge de la galeria</p>',
  },
  myRates_data164: {
    es: '<p>USUARIOS</p>',
    en: '<p>ADDITIONAL</p>',
    pt: '<p>UTILIZADORES</p>',
    fr: '<p>UTILISATEURS</p>',
    eus: '<p>ERABILTZAILE</p>',
    cat: '<p>USUARIS</p>',
  },
  myRates_data165: {
    es: '<p>ADICIONALES</p>',
    en: '<p>USERS</p>',
    pt: '<p>ADICIONAIS</p>',
    fr: '<p>SUPPLÉMENTAIRES</p>',
    eus: '<p>GEHIGARRIAK</p>',
    cat: '<p>ADDICIONALS</p>',
  },
  myRates_data166: {
    es: '<p><span class="i18n-bold">Incluye: (nº de usuarios)</span> tendrán acceso a tu cuenta</p>',
    en: '<p><span class="i18n-bold">Includes: (no. of users)</span>  with access to your account</p>',
    pt: '<p><span class="i18n-bold">Inclui: (nº de utilizadores)</span> terão acesso à sua conta</p>',
    fr: '<p><span class="i18n-bold">Inclus : (nb. d’utilisateurs)</span> pourront accéder à votre compte</p>',
    eus: '<p><span class="i18n-bold">Harpidetza honekin: (erabiltzaile kop.)</span> sar daitezke zure kontuan</p>',
    cat: '<p><span class="i18n-bold">Inclou:(quant. d’usuaris)</span> tindran accés al teu compte</p>',
  },
  myRates_data167: {
    es: '<p>Establecer como método de pago <span class="i18n-underline">predeterminado.</span></p>',
    en: '<p>Set as <span class="i18n-underline">default.</span> payment method.</p>',
    pt: '<p>Estabelecer como método de pagamento <span class="i18n-underline">predeterminado.</span></p>',
    fr: '<p>Définir comme méthode de paiement <span class="i18n-underline">par défaut.</span></p>',
    eus: '<p>Ezarri ordainketa-metodo <span class="i18n-underline">lehenetsi</span> gisa.</p>',
    cat: '<p>Estableix com a mètode de pagament <span class="i18n-underline">per defecte.</span></p>',
  },
  myRates_data168: {
    es: '<p>Añadir tarjeta</p>',
    en: '<p>Add card</p>',
    pt: '<p>Adicionar cartão</p>',
    fr: '<p>Ajouter une carte bancaire</p>',
    eus: '<p>Gehitu txartela</p>',
    cat: '<p>Afegeix una targeta</p>',
  },
  myRates_data169: {
    es: '<p>Modificar tarjeta</p>',
    en: '<p>Modify card</p>',
    pt: '<p>Alterar cartão</p>',
    fr: '<p>Modifier une carte bancaire</p>',
    eus: '<p>Aldatu txartela</p>',
    cat: '<p>Modifica la targeta</p>',
  },
  myRates_data170: {
    es: '<p>Nombre de la empresa/empresario</p>',
    en: '<p>Company/Business owner name</p>',
    pt: '<p>Nome da empresa/empresário</p>',
    fr: '<p>Nom de l’entreprise/responsable</p>',
    eus: '<p>Enpresaren/enpresaburuaren izena</p>',
    cat: '<p>Nom de l’empresa/empresari</p>',
  },
  myRates_data171: {
    es: '<p>Eliminar método de pago</p>',
    en: '<p>Delete payment method</p>',
    pt: '<p>Eliminar método de pagamento</p>',
    fr: '<p>Supprimer le mode de paiement</p>',
    eus: '<p>Ezabatu ordainketa metodoa</p>',
    cat: '<p>Elimina el mètode de pagament</p>',
  },
  myRates_data172: {
    es: '<p>Tu tarjeta se ha modificado correctamente</p>',
    en: '<p>Your card has been successfully modified</p>',
    pt: '<p>O seu cartão foi corretamente alterado</p>',
    fr: '<p>Votre carte a été modifiée avec succès</p>',
    eus: '<p>Zure txartela behar bezala aldatu da</p>',
    cat: '<p>La targeta s\'ha modificat correctament</p>',
  },
  myRates_data173: {
    es: '<p>Tu tarjeta no se ha modificado correctamente</p>',
    en: '<p>Your card has not been successfully modified</p>',
    pt: '<p>O seu cartão não foi corretamente alterado</p>',
    fr: '<p>Votre carte n\a pas pu être modifiée</p>',
    eus: '<p>Zure txartela ezin izan da aldatu</p>',
    cat: '<p>La targeta no s\'ha modificat correctament</p>',
  },
  myRates_data174: {
    es: '<p>Por favor, inténtalo de nuevo más tarde</p>',
    en: '<p>Please try again later</p>',
    pt: '<p>Tente novamente mais tarde</p>',
    fr: '<p>Veuillez réessayer plus tard</p>',
    eus: '<p>Mesedez, saiatu berriro geroago</p>',
    cat: '<p>Torna a provar-ho més tard.</p>',
  },
  myRates_data175: {
    es: '<p>Cancelar suscripción</p>',
    en: '<p>Cancel subscription</p>',
    pt: '<p>Cancelar subscrição</p>',
    fr: '<p>Annuler abonnement</p>',
    eus: '<p>Utzi harpidetza</p>',
    cat: '<p>Cancel·la la subscripció</p>',
  },

  myRates_data176: {
    es: '<p>/modelo</p>',
    en: '<p>per model</p>',
    pt: '<p>/modelo</p>',
    fr: '<p>/modèle</p>',
    eus: '<p>/eredu bakoitzeko</p>',
    cat: '<p>/model</p>',
  },

  myRates_data177: {
    es: '<p>/mes</p>',
    en: '<p>per month</p>',
    pt: '<p>/mês</p>',
    fr: '<p>/mois</p>',
    eus: '<p>/hileko</p>',
    cat: '<p>/mes</p>',
  },

  myRates_data178: {
    es: '<p>Tu mejora de tarifa se ha realizado con éxito.</p>',
    en: '<p>Your plan has been improved correctly.</p>',
    pt: '<p>A sua melhoria de tarifa foi efetuada com sucesso.</p>',
    fr: '<p>La mise à niveau de votre tarif a été effectuée avec succès.</p>',
    eus: '<p>Zure tarifa behar bezala hobetu da.</p>',
    cat: '<p>La millora de tarifa s’ha completat correctament.</p>',
  },

  myRates_data179: {
    es: '<p>Tu suscripción a la Tarifa %X% ha sido cancelada.</p>',
    en: '<p>Your %X% subscription has been canceled.</p>',
    pt: '<p>A sua subscrição da tarifa %X% foi cancelada.</p>',
    fr: '<p>Votre abonnement au tarif %X% a été résilié.</p>',
    eus: '<p>%X% tarifako harpidetza bertan behera utzi duzu.</p>',
    cat: '<p>La subscripció a la tarifa %X% s’ha cancel·lat.</p>',
  },

  myRates_data180: {
    es: '<p>A partir del próximo %X%, dejarás de tener acceso a tus funcionalidades actuales.</p>',
    en: '<p>Starting on %X%, you will no longer have access to your current features.</p>',
    pt: '<p>A partir do próximo %X%, deixará de ter acesso às suas funcionalidades atuais.</p>',
    fr: '<p>À partir du %X%, vous n’aurez plus accès à vos fonctionnalités actuelles.</p>',
    eus: '<p>Datorren %X%(e)tik aurrera, orain dauzkazun funtzionalitate guztiak galduko dituzu.</p>',
    cat: '<p>A partir del pròxim %X%, deixaràs de tenir accés a les funcionalitats actuals.</p>',
  },

  myRates_data181: {
    es: '<p>Ha ocurrido un error en la cancelación de tu tarifa</p>',
    en: '<p>An error has occurred while canceling your plan</p>',
    pt: '<p>Ocorreu um erro ao cancelar a sua tarifa</p>',
    fr: '<p>Une erreur est survenue durant annulation de votre tarif</p>',
    eus: '<p>Errore bat gertatu da zure tarifa bertan behera uzteko prozesuan.</p>',
    cat: '<p>Hi ha hagut un error en la cancel·lació de la tarifa</p>',
  },

  myRates_data182: {
    es: '<p>Por favor, inténtalo de nuevo más tarde.</p>',
    en: '<p>Please try again later.</p>',
    pt: '<p>Tente novamente mais tarde.</p>',
    fr: '<p>Veuillez réessayer plus tard.</p>',
    eus: '<p>Mesedez, saiatu berriro geroago.</p>',
    cat: '<p>Torna a provar-ho més tard.</p>',
  },

  myRates_data183: {
    es: '<p>Añadir método de pago</p>',
    en: '<p>Add a payment method</p>',
    pt: '<p>Adicionar método de pagamento</p>',
    fr: '<p>Ajouter un mode de paiement</p>',
    eus: '<p>Gehitu ordainketa metodoa</p>',
    cat: '<p>Afegeix un mètode de pagament</p>',
  },

  myRates_data184: {
    es: '<p>Siempre que necesites modificar o eliminar tu método de pago, estarán a tu disposición en tu área personal.</p>',
    en: '<p>You can modify or delete your payment method in your personal area whenever you need.</p>',
    pt: '<p>Sempre que precisar de alterar ou eliminar o seu método de pagamento, este estará disponível na sua área pessoal.</p>',
    fr: '<p>Accédez à votre espace personnel si vous souhaitez modifier ou supprimer votre mode de paiement.</p>',
    eus: '<p>Zure ordainketa metodoa aldatu edo ezabatu ahal izateko, sartu zure eremu pertsonalean eta bertan izango dituzu eskura.</p>',
    cat: '<p>Sempre que hagis de modificar o eliminar el mètode de pagament, el trobaràs a la teva disposició a l’àrea personal.</p>',
  },

  myRates_data185: {
    es: '<p>¿Estás seguro que quieres eliminar tu método de pago?</p>',
    en: '<p>Are you sure that you want to delete this payment method?</p>',
    pt: '<p>Tem a certeza de que pretende eliminar o seu método de pagamento?</p>',
    fr: '<p>Êtes-vous sûr(e) de vouloir supprimer votre mode de paiement ?</p>',
    eus: '<p>Ziur zaude zure ordainketa metodoa ezabatu nahi duzula?</p>',
    cat: '<p>Segur que vols eliminar el mètode de pagament?</p>',
  },

  myRates_data186: {
    es: '<p>Esta acción no se puede deshacer.</p>',
    en: '<p>This action cannot be undone.</p>',
    pt: '<p>Esta ação não é reversível.</p>',
    fr: '<p>Cette action ne peut pas être annulée.</p>',
    eus: '<p>Ekintza hau ezin da desegin.</p>',
    cat: '<p>Aquesta acció no es pot desfer.</p>',
  },

  myRates_data187: {
    es: '<p>Tu método de pago se ha eliminado correctamente.</p>',
    en: '<p>Your payment method has been deleted correctly.</p>',
    pt: '<p>O seu método de pagamento foi eliminado com sucesso.</p>',
    fr: '<p>Votre mode de paiement a été correctement supprimé.</p>',
    eus: '<p>Zure ordainketa metodoa behar bezala ezabatu da.</p>',
    cat: '<p>El mètode de pagament s’ha eliminat correctament.</p>',
  },

  myRates_data188: {
    es: '<p>Tu método de pago no se ha podido eliminar.</p>',
    en: '<p>Your payment method could not be deleted.</p>',
    pt: '<p>Não foi possível eliminar o seu método de pagamento.</p>',
    fr: '<p>Votre mode de paiement n’a pas pu être supprimé.</p>',
    eus: '<p>Zure ordainketa metodoa ezin izan da ezabatu.</p>',
    cat: '<p>No s’ha pogut eliminar el mètode de pagament.</p>',
  },
};
