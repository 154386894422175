import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ModelRemoteService } from 'src/app/core/services/remote/model/model-remote.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ModelAccessibilityGuard implements CanActivate {
  constructor(
    private router: Router,
    private modelRemoteService: ModelRemoteService
  ) { }

  public canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot
  ): Observable<boolean> {
    const code = activatedRouteSnapshot.params['code'];

    return this.modelRemoteService.getAccesibility(code).pipe(
      map((response) => {
        return true;
      }),
      catchError((error) => {
        this.router.navigate(['/model-not-found']);
        return of(false);
      })
    );
  }
}
