import { Injectable } from '@angular/core';
import { RestClientService } from '../api-access/rest-client.service';
import { Observable } from 'rxjs';
import { NewRate } from '../../models/new-rates/new-rate';
import { CanDownloadCodes } from '../../models/new-rates/new-rate-enums';

export type DownloadsInfo = {
  total_downloads?: number;
  used_downloads: number;
  remaining_downloads: number;
};

export type UsersInfo = {
  total_users?: number;
  used_users: number;
  remaining_users: number;
};

export type StorageInfo = {
  total_storage?: number;
  used_storage: number;
  remaining_storage: number;
};

export type ImportsInfo = {
  total_imports?: number;
  used_imports: number;
  remaining_imports: number;
};

export type ImageVideosConfig = {
  min: number;
  max: number;
  max_details: number;
  valid_formats: string;
};

export type RateAssignedByUser = {
  price: { monthly: number; yearly: number };
  users: number;
  groups: string[];
  upload_limits: { images: number; seconds: number };
  downloads: number;
  imports: number;
  storage: number;
  active_until?: string;
  rate_main?: { id: number; owner_id: number; rate_id: number };
  groupsI18n?: string[];
  beforeNameI18n?: string;
  nameI18n?: string;
  deleted_at?: string;
  is_extra?: boolean;
  canceled_at: string | null;
};

export type RatesAssignedByUser = {
  rates: RateAssignedByUser[];
  total: RateAssignedByUser;
};

export type ToolInfo = {
  pwa_name: string;
  is_enabled: boolean;
};

export type GroupInfo = {
  pwa_name: string;
  is_enabled: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class NrInfoRemoteService {
  private readonly URL = 'nr-info';

  constructor(private restClientService: RestClientService) {}

  public summary(): Observable<RatesAssignedByUser> {
    return new Observable((subscriber) => {
      this.restClientService
        .get(`${this.URL}/summary`)
        .subscribe((summary: RatesAssignedByUser) => {
          summary.total.groups = summary.total.groups.filter(
            (group) => group !== 'ADDONS'
          );
          summary.rates.forEach((rate) => {
            rate.groups = rate.groups.filter((group) => group !== 'ADDONS');
          });
          subscriber.next(summary);
          subscriber.complete();
        });
    });
    // return this.restClientService.get(`${this.URL}/summary`);
  }

  public getDownloadsInfo(): Observable<DownloadsInfo> {
    return this.restClientService.get(`${this.URL}/downloads`);
  }

  public getImportsInfo(): Observable<ImportsInfo> {
    return this.restClientService.get(`${this.URL}/imports`);
  }

  public getStorageInfo(): Observable<StorageInfo> {
    return this.restClientService.get(`${this.URL}/storage`);
  }

  public getUsersInfo(): Observable<UsersInfo> {
    return this.restClientService.get(`${this.URL}/users`);
  }

  public getImagesInfo(): Observable<ImageVideosConfig> {
    return this.restClientService.get(`${this.URL}/images`);
  }

  public getVideosInfo(): Observable<ImageVideosConfig> {
    return this.restClientService.get(`${this.URL}/videos`);
  }

  public getGroupsInfo(): Observable<GroupInfo[]> {
    return this.restClientService.get(`${this.URL}/groups`);
  }

  public getToolsInfo(modelId): Observable<ToolInfo[]> {
    return this.restClientService.get(`${this.URL}/tools/${modelId}`);
  }

  public canDownloadModel(modelId: number): Observable<CanDownloadCodes> {
    return this.restClientService.get(
      `${this.URL}/can-download-model/${modelId}`
    );
  }
}
